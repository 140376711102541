<template>
  <loading-view loading-text="processing, one moment..." />
</template>

<script>
import store from '@/store'
import LoadingView from '@/components/FullScreenLoader'
import { ERROR_CODES } from '@/constants'
import jwtDecode from 'jwt-decode'

export default {
  name: 'EHubResume',
  components: { LoadingView },
  mounted() {
    this.resumeApplication()
  },
  methods: {
    async resumeApplication() {
      const { idToken, refreshToken, schoolData } =
        this.getItemsFromSessionStorage([
          'idToken',
          'refreshToken',
          'schoolData',
          'ernEnrolmentOwnerName'
        ])
      if (idToken && refreshToken && schoolData) {
        const { email } = jwtDecode(idToken)
        const parentId = jwtDecode(idToken)['custom:pid']
        this.setStore(idToken, refreshToken, email, schoolData, parentId)
        this.fetchApplication()
      } else {
        await this.$store.dispatch('setErrorType', {
          errorType: ERROR_CODES.NO_SCHOOL_CODE
        })
        await this.$router.push({ name: 'error' })
      }
    },
    fetchApplication() {
      this.$store
        .dispatch('fetchApplication')
        .then(() => {
          if (this.$store.getters.isPendingApplication) {
            this.$router.push({ name: 'form', params: { viaEhub: true } })
          } else {
            this.$store.dispatch('setErrorType', {
              errorType: ERROR_CODES.NO_PENDING
            })
            this.$router.push({ name: 'error' })
          }
        })
        .catch(() => {
          this.$store.dispatch('setErrorType', { errorType: ERROR_CODES.API })
          this.$router.push({ name: 'error' })
        })
    },
    setStore(idToken, refreshToken, email, schoolData, parentId) {
      store.commit('setIdToken', idToken)
      store.commit('setRefreshToken', refreshToken)
      store.commit('setSchool', schoolData)
      store.commit('setViaEhub', true)
      store.commit('setEmail', email)
      store.commit('setParentId', parentId)
    },
    getItemFromSessionStorage(key) {
      const value = window.sessionStorage.getItem(key)
      if (value) {
        return JSON.parse(value)
      }
      return ''
    },
    getItemsFromSessionStorage(keys) {
      return keys.reduce(
        (acc, key) => ({ ...acc, [key]: this.getItemFromSessionStorage(key) }),
        {}
      )
    }
  }
}
</script>
