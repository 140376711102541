<template>
  <!-- eslint-disable max-len -->
  <div>
    <Banner v-if="school" class="ooaBanner" data-testid="school-name-banner">
      <v-card
        v-if="school.logo"
        :class="$vuetify.breakpoint.xsOnly ? 'mr-4' : 'mr-10'"
        :max-height="$vuetify.breakpoint.xsOnly ? '50' : '118'"
        :max-width="$vuetify.breakpoint.xsOnly ? '50' : '118'"
      >
        <v-img
          :class="$vuetify.breakpoint.xsOnly ? 'imgMoble' : 'imgDesktop'"
          :height="$vuetify.breakpoint.xsOnly ? '38' : '89'"
          :width="$vuetify.breakpoint.xsOnly ? '38' : '89'"
          contain
          :src="school.logo"
          alt="school logo"
          data-testid="school-logo"
        />
      </v-card>
      <div
        :class="{
          mobleTitle: $vuetify.breakpoint.xsOnly,
          tabletTitle: $vuetify.breakpoint.smOnly,
          desktopTtile: $vuetify.breakpoint.mdAndUp
        }"
      >
        <h2>{{ school.schoolName }}</h2>
        <p class="mt-2 mb-0">Out-of-area enrolment application</p>
      </div>
    </Banner>

    <v-container v-if="address" class="ooaContent">
      <div class="addressHeader mt-9" data-testid="student-address">
        <v-icon
          class="material-icons-outlined"
          data-testid="place-icon"
          :class="{ 'ml-6 pl-9': $vuetify.breakpoint.mdAndUp }"
        >
          place
        </v-icon>
        <span
          class="ml-4 address"
          :class="{ 'pl-12': $vuetify.breakpoint.mdAndUp }"
          >{{ address }}</span
        >
      </div>
      <v-card class="mb-6">
        <ContentBox
          v-if="noSchoolFound"
          class="pt-6"
          img-file-name="icon-no-local-school.svg"
          title="This address is not in a catchment area for a NSW public school"
          data-testid="no-school-found"
        >
          <div slot="copyContent">
            Your child needs to reside in NSW to apply for enrolment. If you did
            enter a NSW address, or are moving to NSW, please contact us to
            discuss.
            <SchoolExpanderPanel
              :name="school.schoolName"
              :address="school.address"
              :lat="school.lat"
              :long="school.long"
              :phone="school.phone"
              :website="school.website"
              class="my-9"
            />
          </div>
        </ContentBox>
        <ContentBox
          v-if="withinArea && hasLocalCatchmentSchools"
          class="pt-6"
          img-file-name="icon-local-school.svg"
          title="Eligible for local enrolment"
          data-testid="within-catchment-area-section"
        >
          <div slot="copyContent">
            <p>
              Your address is in area for
              <strong>{{ displayLocalCatchmentSchoolName }}</strong
              >. Your child is entitled to a place at our school.
            </p>
            <v-btn
              x-large
              color="primary"
              class="mt-6 mb-6"
              @click="proceedLocalEnrl(displayLocalCatchmentSchoolCode)"
            >
              Apply for local enrolment
            </v-btn>
          </div>
        </ContentBox>

        <template v-if="isOutOfAreaForYear7NextYear">
          <ContentBox
            class="pt-6"
            :icon="{
              name: 'mdi-alert-circle-outline',
              color: 'primary',
              size: '55px'
            }"
            :title="`Out-of-area year ${scholasticYear} enrolments for ${nextYear}`"
            data-testid="out-of-area-section"
          >
            <div slot="copyContent" class="mb-4 text-left">
              <p>
                The process of moving from year 6 to year 7 at a NSW government
                school is managed using a separate expression of interest
                process.
              </p>
              <p>
                If your child is currently enrolled in a NSW public primary
                school please contact the primary school for further information
                about moving into year 7 in a NSW government school.
              </p>
              <p class="mb-9">
                Otherwise, please contact
                <strong>{{ school.schoolName }}</strong> directly to express
                your interest in enrolling your child next year.
              </p>
              <SchoolExpanderPanel
                :name="school.schoolName"
                :address="school.address"
                :lat="school.lat"
                :long="school.long"
                :phone="school.phone"
                :website="school.website"
                class="my-9"
              />
              <p class="mt-9">
                To read more about Year 7 enrolments please refer to the
                <a
                  href="https://education.nsw.gov.au/public-schools/going-to-a-public-school/enrolment/high-school-enrolment"
                  target="_blank"
                >
                  high school enrolment information page </a
                >.
              </p>
            </div>
          </ContentBox>
        </template>
        <template v-else-if="outOfArea && hasLocalCatchmentSchools">
          <ContentBox
            class="pt-6"
            img-file-name="icon-local-school.svg"
            title="Have you considered your local school?"
            data-testid="out-of-area-section"
          >
            <div slot="copyContent" class="mb-4 text-left">
              <p>
                Placement at our school is not guaranteed for out-of-area
                students. We recommend speaking with your local school about how
                to meet your child’s education needs.
              </p>
              <div v-for="loc in localCatchmentSchools" :key="loc.schoolCode">
                <SchoolExpanderPanel
                  :name="loc.schoolName"
                  :address="loc.schoolAddress"
                  :lat="loc.lat"
                  :long="loc.long"
                  :phone="loc.schoolPhone"
                  :website="loc.schoolWebsite"
                  :show-apply="loc.isOesSchool"
                  @click="proceedLocalEnrl(loc.schoolCode)"
                />
              </div>
            </div>
          </ContentBox>

          <v-divider />

          <ContentBox
            class="pt-6"
            img-file-name="icon-next-steps.svg"
            data-testid="localschool-info-section"
          >
            <div slot="copyContent" class="mb-4 text-left">
              <p>
                <strong
                  >To understand how your local school can support your child's
                  needs, you can:</strong
                >
              </p>

              <div class="steps pa-4">
                <div class="d-flex pb-3 pt-2">
                  <v-icon class="pr-5">schedule</v-icon>
                  <div>Ask to meet with the principal.</div>
                </div>
                <div class="d-flex pb-3 pt-2">
                  <v-icon class="pr-5">mdi-web</v-icon>
                  <div>Visit the school website.</div>
                </div>

                <div class="d-flex pb-3 pt-2">
                  <v-icon class="pr-5">mdi-train</v-icon>
                  <div>
                    Look at transport and commutes on
                    <a href="https://www.google.com/maps" target="_blank"
                      >Google Maps</a
                    >.
                  </div>
                </div>

                <div
                  v-if="!isSecondary"
                  class="d-flex pb-3 pt-2"
                  data-testid="localschool-info-oshc-section"
                >
                  <v-icon class="pr-5">schedule</v-icon>
                  <div>
                    Find out about
                    <a
                      href="https://www.service.nsw.gov.au/find-a-basc-provider"
                      target="_blank"
                      >out of school hours care (OSHC) services</a
                    >.
                  </div>
                </div>
              </div>
            </div>
          </ContentBox>

          <v-divider />

          <ContentBox
            class="pt-6"
            img-file-name="icon-child-attending.svg"
            data-testid="atteded-localschool-section"
          >
            <div slot="copyContent" class="text-left">
              <p>
                <strong>Does your child already attend school?</strong> If
                applying out-of-area due to concerns at your current school,
                please talk to the principal at your local school to try to
                resolve them first.
              </p>
            </div>
          </ContentBox>
        </template>
      </v-card>

      <v-card
        v-if="
          !isOutOfAreaForYear7NextYear && outOfArea && hasLocalCatchmentSchools
        "
        class="ooa pa-2 mb-12"
        data-testid="proceed-out-of-area-application"
      >
        <ContentBox img-file-name="icon-out-of-area.svg">
          <div slot="title">
            <h2 style="font-weight: normal">Proceed with applying to</h2>
            <h2>{{ school.schoolName }}</h2>
          </div>
          <div slot="copyContent" class="mt-6">
            <p class="text-left">
              It takes about <strong>10-20 minutes.</strong> Once you start an
              application, any saved progress will be kept for 30 days.
            </p>
            <v-btn
              class="mt-4 mb-4 proceedOoa"
              outlined
              color="primary"
              to="/privacy"
              data-testid="Proceed-application-btn"
            >
              <span style="font-weight: bold"
                >Proceed with
                <span class="hidden-sm-and-up"><br /></span> out-of-area
                application</span
              >
            </v-btn>
          </div>
        </ContentBox>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import ContentBox from '@/components/ContentBox'
import SchoolExpanderPanel from '@/components/SchoolExpanderPanel'
import { mapGetters } from 'vuex'
import { CATCHMENT_STATUSES } from '@/constants'
import { isSecondary } from '@/helpers'

export default {
  name: 'SchoolReminder',
  components: {
    Banner,
    ContentBox,
    SchoolExpanderPanel
  },
  created() {
    // For users trying to navigate back and forth using the browser buttons - redirect user back to home/landing page
    if (this.school && !this.address) {
      this.$router.push({
        name: 'home',
        query: {
          schoolCode: this.school.schoolCode,
          catchmentLevel: this.school.catchmentLevel
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      inCatchment: 'inCatchment',
      address: 'residentialAddressFormatted',
      pilotSchools: 'pilotSchools',
      localCatchmentSchools: 'localCatchmentSchools',
      school: 'school',
      selectedCatchment: 'selectedCatchment',
      calendarYear: 'calendarYear',
      selectedResidentialAddress: 'selectedResidentialAddress',
      scholasticYear: 'scholasticYear'
    }),
    withinArea() {
      return this.inCatchment === CATCHMENT_STATUSES.WITHIN_AREA
    },
    outOfArea() {
      return this.inCatchment === CATCHMENT_STATUSES.OUT_OF_AREA
    },
    noSchoolFound() {
      return this.inCatchment === CATCHMENT_STATUSES.NO_SCHOOL_FOUND
    },
    isSecondary() {
      return isSecondary(this.school.catchmentLevel, this.selectedCatchment)
    },
    hasLocalCatchmentSchools() {
      return this.localCatchmentSchools && this.localCatchmentSchools.length > 0
    },
    displayLocalCatchmentSchool() {
      if (
        !this.localCatchmentSchools ||
        this.localCatchmentSchools.length === 0
      ) {
        return undefined
      }

      // If there are two local schools catchment, we display the same school catchment as we navigated
      if (this.localCatchmentSchools.length > 1) {
        const sameLocalCatchmentSchool = this.localCatchmentSchools.filter(
          (school) => this.school.schoolCode === school?.schoolCode?.toString()
        )
        if (sameLocalCatchmentSchool.length > 0) {
          return sameLocalCatchmentSchool[0]
        }
      }
      // If there is only one local school catchment or we can't filter the same local school catchment, fallback to the first local school
      return this.localCatchmentSchools[0]
    },
    displayLocalCatchmentSchoolName() {
      if (!this.displayLocalCatchmentSchool) {
        return undefined
      }
      return this.displayLocalCatchmentSchool.schoolName
    },
    displayLocalCatchmentSchoolCode() {
      if (!this.displayLocalCatchmentSchool) {
        return undefined
      }
      return this.displayLocalCatchmentSchool.schoolCode
    },
    nextYear() {
      return new Date().getFullYear() + 1
    },
    isOutOfAreaForYear7NextYear() {
      return (
        this.outOfArea &&
        this.nextYear === parseInt(this.calendarYear, 10) &&
        parseInt(this.scholasticYear, 10) === 7
      )
    }
  },
  methods: {
    proceedLocalEnrl(schoolCode) {
      let catchmentPrefill
      if (this.selectedResidentialAddress) {
        const data = {
          address: this.selectedResidentialAddress,
          catchmentLevel: this.selectedCatchment,
          year: this.calendarYear,
          scholasticYear: this.scholasticYear
        }
        catchmentPrefill = encodeURIComponent(btoa(JSON.stringify(data)))
      }

      let url = `${process.env.VUE_APP_LOCAL_ENROLMENT_LINK}schoolCode=${schoolCode}&catchmentLevel=${this.school.catchmentLevel}`
      if (catchmentPrefill) {
        url += `&catchmentPrefill=${catchmentPrefill}`
      }
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.addressHeader {
  min-height: 72px;
  height: auto;
  background: $ads-light-blue;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  color: $color-text-body;
  font-size: 18px;
  font-weight: 500;
}
.mobile .addressHeader {
  padding: 8px;
}
.steps {
  background: $ads-light-10;
  border-radius: 4px;
}
.ooa {
  background: $color-bg-section;
}
.v-btn.proceedOoa {
  height: 52px;
}
.mobile .v-btn {
  width: 100%;
}
.mobile .v-btn.proceedOoa {
  width: 95%;
}
.address:first-letter {
  text-transform: capitalize;
}
.proceedOoa {
  font-size: 16px;
}
::v-deep .ooaBanner .container--fluid {
  display: flex;
}
::v-deep .v-sheet.v-card {
  border-radius: 0;
}
.imgMoble {
  margin: 0.3rem;
}
.imgDesktop {
  margin: 1rem;
}
.mobleTitle {
  margin-top: 4px;
}
.tabletTitle {
  margin-top: 26px;
}
.tabletTitle h2 {
  font-size: 30px !important;
}
.tabletTitle p {
  font-size: 18px !important;
}
.desktopTtile {
  margin-top: 12px;
}
</style>
