<template>
  <div class="APIError">
    <ErrorContent
      :include-stack-trace="includeStackTrace"
      :stack-trace="stackTrace"
    >
      <template #content>
        <ErrorCard
          :error-code="errorCode"
          :heading-text="errorText"
          :error-id="errorId"
          :actions="actions"
        />
      </template>
    </ErrorContent>
  </div>
</template>

<script>
import { API_ERROR_SUBTYPES } from '@/constants'
import ErrorContent from '@/components/error/ErrorContent'
import ErrorCard from '@/components/error/ErrorCard'

export default {
  name: 'APIError',
  components: {
    ErrorContent,
    ErrorCard
  },
  props: {
    error: {
      type: Object,
      default: () => ({})
    },
    includeStackTrace: {
      type: Boolean,
      default: false
    },
    previousRoute: {
      type: Object,
      default: () => ({})
    },
    defaultAction: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      API_ERROR_SUBTYPES,
      actions: [
        { text: 'Try again', on: { click: this.handleTryAgain } },
        this.defaultAction
      ]
    }
  },
  computed: {
    errorObject() {
      return this.error.errorObject || {}
    },
    stackTrace() {
      return this.errorObject.stack || null
    },
    response() {
      return this.error.response || {}
    },
    errorList() {
      return (this.response.data && this.response.data.errors) || []
    },
    codeText() {
      return (
        (this.errorList[0] && this.errorList[0].error_code) ||
        this.error.fallbackCode
      )
    },
    codeStatus() {
      return (
        (this.errorList[0] && this.errorList[0].status) ||
        (this.isNetworkError(this.errorObject) && 'NET') ||
        this.response.status
      )
    },
    errorCode() {
      return (
        (this.codeText &&
          this.codeStatus &&
          `${this.codeText}-${this.codeStatus}`) ||
        null
      )
    },
    errorId() {
      return (this.errorList[0] && this.errorList[0].id) || null
    },
    errorText() {
      switch (this.codeText) {
        case this.API_ERROR_SUBTYPES.initiateAuth:
          return 'Sorry, we are unable to authenticate you right now.'
        case this.API_ERROR_SUBTYPES.retrieveApplications:
          return 'Sorry, we are unable to retrieve your application at this time. Please try again later or contact our support team for further assistance.'
        case this.API_ERROR_SUBTYPES.submitApplication:
          return 'Sorry, we are unable to submit your application right now. Please try again later or contact our support team for further assistance.'
        case this.API_ERROR_SUBTYPES.oesPilotSchoolJson:
        case this.API_ERROR_SUBTYPES.oesPropertiesJson:
          return 'Sorry, this application is currently unavailable.'
        default:
          return 'An error has occurred and we’re trying to fix the problem.'
      }
    }
  },
  methods: {
    handleTryAgain() {
      switch (this.codeText) {
        case this.API_ERROR_SUBTYPES.retrieveApplications:
          // handle oauth login case, where the previousRoute is not /login
          this.$router.push('/login')
          return
        case this.API_ERROR_SUBTYPES.oesPropertiesJson:
          this.$store.dispatch('getOESProperties').catch(() => {})
          this.navigateToPrevPage()
          return
        case this.API_ERROR_SUBTYPES.initiateAuth:
        case this.API_ERROR_SUBTYPES.submitApplication:
        default:
          // eslint-disable-next-line no-unused-expressions
          this.navigateToPrevPage()
      }
    },
    navigateToPrevPage() {
      return this.previousRoute.name
        ? this.$router.push(this.previousRoute.fullPath)
        : this.$router.go(-1)
    },
    isNetworkError(e) {
      return e.message === 'Network Error'
    }
  }
}
</script>

<style lang="scss" scoped>
.typed-email-headline {
  color: pink;
  line-height: normal;
  font-weight: bold;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
</style>
