<template>
  <v-text-field
    :ref="inputRef"
    :value="value"
    :label="readonly || (disabled && !showLabelOnDisabled) ? '' : label"
    :placeholder="placeholder"
    :hint="hint"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    :class="{ 'readonly-text': readonly }"
    v-bind="$attrs"
    persistent-hint
    persistent-placeholder
    outlined
    :aria-describedby="messageBoxId"
    :aria-invalid="!isValid"
    :autofocus="autofocus"
    @input="$emit('input', $event)"
    @blur="$emit('handleOnBlur')"
  >
    <template #message="data">
      <div
        :id="messageBoxId"
        class="v-messages__message"
        v-html="data.message"
      />
    </template>
  </v-text-field>
</template>

<script>
import FormMixin from '@/mixins/form'
import accessibilityMixin from '@/mixins/fieldAccessibility'

export default {
  name: 'TextField',
  mixins: [FormMixin, accessibilityMixin('textField')],
  props: {
    value: {
      type: String
    },
    showLabelOnDisabled: {
      type: Boolean
    },
    autofocus: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped></style>
