<template>
  <!-- eslint-disable max-len -->

  <div>
    <Banner>
      <h2 data-testid="start-application-banner">
        <span v-if="isResuming">Resume your</span
        ><span v-else> Start a new</span><br />
        Out-of-area application
      </h2>
    </Banner>

    <v-container fluid class="ooaContent">
      <v-container class="form-container">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="elevation-2 pa-4 pr-6 white"
          data-testid="login-email-form"
          @submit.prevent="emailAuth"
        >
          <v-row>
            <v-col cols="12" md="3" class="text-center">
              <img
                alt="Email"
                class="mt-4"
                data-testid="email-img"
                :src="require('@/assets/icon-email.svg')"
              />
            </v-col>
            <v-col cols="12" md="9">
              <v-row>
                <v-col>
                  <span v-if="isResuming" class="headline-1">
                    <h3 class="mb-2">Resume using your email address</h3>
                    Enter the <strong>email address</strong> you used to begin
                    your out-of-area application to resume your progress.
                  </span>
                  <span v-else class="headline-1">
                    <h3 class="mb-2">Enter your email address</h3>
                    Entering your email address will allow you to start the
                    out-of-area application, save it and come back later
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    ref="email"
                    v-model="email"
                    label="Email address"
                    placeholder="Enter your email address"
                    :class="{ 'readonly-text': isResume }"
                    outlined
                    required
                    autofocus
                    :readonly="isResume"
                    :error-messages="errorMessages"
                    data-testid="login-email-text-input"
                    persistent-placeholder
                  />
                </v-col>
              </v-row>
              <v-row v-if="!byPassRecaptcha">
                <v-col>
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="recaptchaSiteKey"
                    data-testid="recaptcha"
                    @verify="onRecaptchaVerify"
                    @expired="onRecaptchaExpried"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <div class="d-flex justify-end">
                    <v-btn
                      class="primary"
                      x-large
                      :disabled="
                        isBlank(email) ||
                        !valid ||
                        (recaptchaVerifyToken === null && !byPassRecaptcha)
                      "
                      data-testid="continue-button"
                      @click="
                        enableLoader()
                        emailAuth()
                      "
                    >
                      <ButtonSpinner :loading="loading" class="mr-2" />
                      Continue
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-container>
        <div class="text-center mt-2 mb-2 headline-1"><strong>OR</strong></div>
      </v-container>
      <ServiceNSWLogin class="mb-8" data-testid="serviceNSW-login-section" />
    </v-container>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'

import doeColors from '@/settings/doe-colors'
import Banner from '@/components/Banner'
import ButtonSpinner from '@/components/ButtonSpinner'
import ServiceNSWLogin from '@/views/ServiceNSWLogin'
import { NODE_ENVIRONMENTS } from '@/constants'
import { getGtmApplication } from '@/helpers'

export default {
  name: 'LoginView',
  components: {
    Banner,
    ButtonSpinner,
    VueRecaptcha,
    ServiceNSWLogin
  },
  props: {
    resumeViaEmail: {
      type: Boolean,
      default: false
    },
    resumeSessionExpired: {
      type: Boolean,
      default: false
    },
    userEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valid: false,
      loading: false,
      email: null,
      notificationColors: doeColors.notification,
      recaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaVerifyToken: null,
      isResume: false,
      errors: {},
      ERROR_MESSAGES: {
        EMAIL: 'Please enter a valid email address.',
        RECAPTCHA: 'Recaptcha verification failed. Please try again.'
      }
    }
  },
  computed: {
    oesProperties() {
      return this.$store.getters.oesProperties
    },
    errorMessages() {
      return Object.values(this.errors).filter((message) => message)
    },
    byPassRecaptcha() {
      const bypassrecaptcha = this.oesProperties
        ? this.oesProperties.bypassRecaptcha
        : null
      if (
        bypassrecaptcha &&
        process.env.VUE_APP_ENV_NAME &&
        process.env.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PROD
      ) {
        return true
      }
      return false
    },
    isResuming() {
      return this.$store.getters.isResuming
    }
  },
  watch: {
    email(val) {
      this.errors = { ...this.errors, email: null } // clear API error on change
      if (this.isBlank(val)) {
        this.reset()
      }
    },
    errors: {
      deep: true,
      handler(newVal) {
        if (newVal.recaptcha) {
          // If recaptcha has an error then reset the recaptcha
          this.recaptchaVerifyToken = null
          this.$refs.recaptcha.reset()
        } else if (newVal.email) {
          this.$refs.email.focus()
        }
      }
    }
  },
  created() {
    if (this.resumeViaEmail || this.resumeSessionExpired) {
      this.email = this.userEmail
      this.isResume = true
    }
  },
  methods: {
    enableLoader() {
      this.loading = true
    },
    disableLoader() {
      this.loading = false
    },
    isBlank(val) {
      return !val || val.trim().length === 0
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    emailAuth() {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.email
        )
      ) {
        this.valid = false
        this.errors = { email: this.ERROR_MESSAGES.EMAIL }
        this.disableLoader()
        return
      }

      if (this.byPassRecaptcha) {
        // Hardcoded number for non-prod env's.
        this.recaptchaVerifyToken = '111111'
      }

      if (this.valid) {
        // Google Analytics
        const gtmApplication = getGtmApplication(false)
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'Authentication',
          action: 'Attempt',
          label: 'Email',
          application: gtmApplication
        })

        this.$store
          .dispatch('initiateAuth', {
            email: this.email,
            recaptchaToken: this.recaptchaVerifyToken
          })
          .then(() => {
            if (this.resumeViaEmail) {
              this.$router.push({
                name: 'otp',
                params: { resumeViaEmail: true, userEmail: this.userEmail }
              })
            } else {
              this.$router.push({ name: 'otp' })
            }
          })
          .catch((e) => {
            this.handleAPIError(e)
          })
          .finally(() => {
            this.disableLoader()
          })
      }
    },
    handleAPIError(error) {
      const { response } = error
      // Specifically handle 400s with proper error info inline, else go to error view
      if (
        response &&
        response.status === 400 &&
        response.data &&
        response.data.errors
      ) {
        this.handleInlineErrors(response.data.errors)
      } else {
        this.$router.push({ name: 'error' })
      }
    },
    handleInlineErrors(errors) {
      this.errors = errors.reduce(
        (errors, error) => ({ ...errors, ...this.getErrorMessage(error) }),
        {}
      )
    },
    getErrorMessage(error) {
      const genericError = { email: this.ERROR_MESSAGES.EMAIL }
      if (error && error.source) {
        switch (error.source.pointer) {
          case '/recaptchaToken':
            return { recaptcha: this.ERROR_MESSAGES.RECAPTCHA }
          case '/userId':
          default:
            return genericError
        }
      } else {
        return genericError
      }
    },
    onRecaptchaVerify(response) {
      this.errors = { ...this.errors, recaptcha: null } // remove recaptcha error on verify
      this.recaptchaVerifyToken = response
    },
    onRecaptchaExpried() {
      this.recaptchaVerifyToken = null
    }
  }
}
</script>

<style lang="scss" scoped>
.headline-1 {
  font-size: 1rem; //equals 16px when root font-size is 16px.
  font-weight: 500;
  color: $color-text-body;
}
.form-container {
  padding-top: 40px;
}
</style>
