import axios from 'axios'

const ERN_API_BASE = process.env.VUE_APP_EHUB_API_BASE

const ERN_RECORD_URL = `${ERN_API_BASE}/v1/ehub/lookupStudent/`

const PREFILL_PARENT_STUDENT_URL = `${ERN_API_BASE}/v1/ehub/prefillParentStudent`

function getErnRecordBySRN(srn) {
  return axios
    .get(ERN_RECORD_URL + srn)
    .then((response) => response.data)
    .catch(() => null)
}

function getPrefillParentStudentBySrn(srn) {
  return axios
    .get(`${PREFILL_PARENT_STUDENT_URL}/${srn}`)
    .then((response) => {
      const updatedSrnData = { ...response.data.body }
      if (!response.data.body?.student?.srn) {
        updatedSrnData.student.srn = srn
      }
      return updatedSrnData
    })
    .catch(() => null)
}

function getPrefillParentStudentByName(firstName, lastName) {
  return axios
    .get(`${PREFILL_PARENT_STUDENT_URL}?studentName=${firstName}_${lastName}`)
    .then((response) => response.data.body)
    .catch(() => null)
}

function getPrefillParentStudentForNewChild() {
  return axios
    .get(PREFILL_PARENT_STUDENT_URL)
    .then((response) => response.data.body)
    .catch(() => null)
}

export default {
  getErnRecordBySRN,
  getPrefillParentStudentBySrn,
  getPrefillParentStudentByName,
  getPrefillParentStudentForNewChild
}
