<template>
  <!-- eslint-disable max-len -->

  <div>
    <Banner compact data-testid="form-banner">
      <h2>{{ title }}</h2>
    </Banner>
    <v-container
      fluid
      class="contentPadding form-container py-12"
      data-testid="form-container"
    >
      <v-row v-if="showNotification">
        <v-col md="10" class="pr-6 pl-6">
          <v-alert
            border="left"
            colored-border
            color="orange darken-1 mr-1"
            icon="mdi-alert"
            class="mb-0"
          >
            <h3
              class="banner__title grey--text text--darken-1 font-weight-bold"
            >
              {{ notification.heading }}
            </h3>
            <p>{{ notification.body }}</p>
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12 hidden-sm-and-down" md="3" class="sideContent">
          <div class="stepper">
            <Stepper
              :steps="getSteps"
              :current-step="getCurrentStep"
              data-testid="stepper"
              aria-label="application steps"
              @select="selectStep"
            />
          </div>
        </v-col>
        <v-col cols="12 hidden-sm-and-down" md="1" />
        <v-col cols="12" md="6" data-testid="form-content">
          <StudentView v-if="getStepKey === 'student'" />
          <ParentCarerView v-if="getStepKey === 'parentcarer'" />
          <ConsiderationsView
            v-if="getStepKey === 'considerations'"
            @notification="pushNotification"
          />
          <ReviewView v-if="getStepKey === 'review'" />
        </v-col>
        <v-col cols="12 hidden-sm-and-down" md="2" />
      </v-row>

      <div
        class="alertsWrapper"
        :class="{ mobile: $vuetify.breakpoint.smAndDown }"
        role="alert"
        aria-live="assertive"
      >
        <Alert
          v-for="(alert, i) in alerts"
          :key="i"
          in-page
          :show-alert="true"
          type="error"
          :icon="alert.icon"
          :text="alert.title"
          absolute
        >
          <template slot="optional">
            <!-- eslint-disable-next-line -->
            <div v-html="alert.html" />
            <v-btn
              ref="alertButton"
              icon
              class="snackbarCloseButton"
              aria-label="close"
              @click="closeError(i)"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
        </Alert>
      </div>

      <ooa-dialog
        :visible="attemptToLeave"
        title="Sign out of online enrolment?"
        max-width="520px"
        :open-dialog="attemptToLeave"
        :display-close-btn="true"
        :actions="dialogActions"
        @proceed="proceedToNavigation"
        @cancel="cancelLeaveForm"
        @close="attemptToLeave = false"
      >
        <div slot="message">
          If you proceed to a different website, you will be signed out of
          online enrolment, and will need to sign in again to resume.
        </div>
      </ooa-dialog>

      <ooa-dialog
        data-testid="name-change-notice"
        title="A parent/carer name has been updated"
        max-width="700px"
        :open-dialog="showNameChangeNotice"
        :display-close-btn="true"
        icon="mdi-swap-vertical-variant"
        :actions="nameChangeDialogActions"
        @close="showNameChangeNotice = false"
      >
        <div slot="message">
          <p>
            <strong>Please note:</strong> The name of the parent/carer
            completing this application has been updated on the form to match
            your verified details used with online enrolment.
          </p>
          <p>
            Please review all parent carer details before submitting the
            application.
          </p>
        </div>
      </ooa-dialog>
    </v-container>
  </div>
</template>

<script>
import ParentCarerView from '@/views/form/ParentCarer'
import ConsiderationsView from '@/views/form/Considerations'
import StudentView from '@/views/form/Student'
import ReviewView from '@/views/form/Review'
import OoaDialog from '@/components/OoaDialog'
import Banner from '@/components/Banner'
import { Alert, Stepper } from '@nswdoe/doe-ui-core'
import {
  steps,
  currentStep,
  currentStepName,
  currentStepKey,
  selectStep
} from '@/helpers/form/stepper'
import { FILE_UPLOAD_ERROR, FILE_REMOVE_ERROR } from '@/constants'
import { getGtmApplication, isFromEhub } from '@/helpers'

import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'ApplicationForm',
  components: {
    Banner,
    Stepper,
    ParentCarerView,
    ConsiderationsView,
    StudentView,
    ReviewView,
    OoaDialog,
    Alert
  },
  data() {
    return {
      attemptToLeave: null,
      confirmToLeave: null,
      navigateTo: null,
      alerts: [],
      dialogActions: [
        {
          name: 'Cancel',
          color: '#041E42',
          size: 'large',
          outlined: true,
          btnText: false,
          action: 'cancel'
        },
        {
          name: 'Proceed',
          color: 'primary',
          size: 'large',
          outlined: false,
          btnText: false,
          action: 'proceed'
        }
      ],
      nameChangeDialogActions: [
        {
          name: 'Ok',
          color: 'primary',
          size: 'small',
          outlined: false,
          btnText: false,
          btnIcon: 'mdi-check-circle-outline',
          action: 'close'
        }
      ],
      showNameChangeNotice: this.$store.getters.ernEnrolmentOwnerNameNotMatched
    }
  },
  computed: {
    // TODO: See if there is a way to bind the imported steps(), currentSteps() & selectStep(step)
    // functions the Stepper component directly. Possibly using mixin?
    ...mapGetters(['ehubPrefill', 'viaEhub']),
    getSteps() {
      return steps()
    },
    getCurrentStep() {
      return currentStep()
    },
    getStepKey() {
      return currentStepKey()
    },
    title() {
      return currentStepName()
    },
    oesProperties() {
      return this.$store.getters.oesProperties
    },
    notification() {
      return this.oesProperties && this.oesProperties.banner
        ? this.oesProperties.banner.ooa
        : null
    },
    showNotification() {
      if (
        !this.notification ||
        !this.notification.displayStartTime ||
        !this.notification.displayEndTime
      ) {
        return false
      }

      const now = moment().valueOf()
      const startTime = moment(
        this.notification.displayStartTime,
        'DD/MM/YYYY HH:mm:ss'
      ).valueOf()
      const endTime = moment(
        this.notification.displayEndTime,
        'DD/MM/YYYY HH:mm:ss'
      ).valueOf()
      return now >= startTime && now <= endTime
    }
  },
  watch: {
    alerts() {
      this.$nextTick(() => {
        if (this.$refs.alertButton) {
          this.$refs.alertButton.forEach((btn) => {
            if (btn) {
              btn.$el.focus()
            }
          })
        }
      })
    }
  },
  mounted() {
    this.$emit('trackInactivity')
    // set the event listener to pop up a leave page warning if users refresh or manually type in an URL in the address bar.
    if (!isFromEhub()) {
      window.onbeforeunload = this.leaveWarning
    } else {
      // An ehub user refreshing a form step, before EHUB-880, will be redirect to login page and save the current step number in the payload.
      // when users log back in, the page will show the step based on the current step number saved in the BE.
      // Also if users finished step 1, OOA will show step 2 automatically at the FE, but not saving current step as 2 in BE until users clicked save button.
      // Therefore, if a user is on step 2 without saving and refresh
      // the step, the user will see step 1, with step 2 url in the address bar
      // the code below is to set the current step based on the current url parameter as part of EHUB-880
      const refreshedStepNum = this.getSteps.filter(
        (s) => s.key === this.$route.params.step
      )[0].step
      selectStep(refreshedStepNum)
    }
    // Google Analytics
    this.$gtm.trackEvent({
      event: 'interaction',
      category: 'Prefill Details',
      action: 'form start',
      label: this.ehubPrefill,
      application: getGtmApplication(this.viaEhub)
    })
  },

  // handle the in-app navigation away from the form pages
  beforeRouteLeave(to, from, next) {
    this.attemptToLeave = true
    this.navigateTo = to
    const routesToIgnore = [
      'timeout',
      'confirmation',
      'error',
      'submitted',
      'logout'
    ]
    if (this.confirmToLeave || routesToIgnore.includes(to.name)) {
      next()
    }
  },
  destroyed() {
    // remove the event listener to pop up a leave page warning if users refresh or manually type in an URL in the address bar.
    window.onbeforeunload = null
  },
  methods: {
    selectStep(step) {
      if (this.$store.getters.isFileUploadInProgress) {
        this.pushNotification(FILE_UPLOAD_ERROR, 500)
      } else if (this.$store.getters.isFileDeleteInProgress) {
        this.pushNotification(FILE_REMOVE_ERROR, 500)
      } else {
        selectStep(step)
      }
    },
    proceedToNavigation() {
      this.attemptToLeave = false
      this.confirmToLeave = true
      if (this.navigateTo.name === 'otp') {
        this.$router.push('/login')
      } else {
        this.$router.push(this.navigateTo.path)
      }
    },
    cancelLeaveForm() {
      this.attemptToLeave = false
    },
    leaveWarning(e) {
      // Cancel the event
      e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = ''
    },
    pushNotification(notification, delay) {
      if (delay) {
        setTimeout(() => {
          this.alerts.push(notification)
        }, delay)
      } else {
        this.alerts.push(notification)
      }
    },
    closeError(i) {
      this.alerts.splice(i, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.sideContent {
  position: relative;
  min-height: 100%;
  top: 0;
}
.stepper {
  position: sticky;
  height: auto;
  top: 95px;
}
.alertsWrapper {
  position: fixed;
  left: 20px;
  bottom: 10px;
  max-width: 50vw;
  z-index: 10;
  &.mobile {
    max-width: 100vw;
    margin-right: 20px;
  }
}

::v-deep .v-alert .v-alert__wrapper {
  .snackbar--text {
    margin-bottom: 8px;
    ~ span {
      line-height: 1.3rem;
    }
  }
  .v-alert__content {
    margin-right: 35px;
  }
  .snackbarCloseButton {
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
button.snackbarCloseButton.v-btn.v-btn--flat.v-btn--icon.v-btn--round.theme--light.v-size--default:focus {
  border: 2px solid $ads-navy;
}
</style>
