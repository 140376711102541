import Vue from 'vue'
import store from '@/store'
import jwtDecode from 'jwt-decode'

export default async function ehubGuard(to, from, next) {
  // get data set by eHub app from the session storage - idToken, refreshToken, formData, schoolData
  const idToken = JSON.parse(window.sessionStorage.getItem('idToken'))
  const refreshToken = JSON.parse(window.sessionStorage.getItem('refreshToken'))
  const schoolData = JSON.parse(window.sessionStorage.getItem('schoolData'))
  const formData = JSON.parse(window.sessionStorage.getItem('formData'))
  const swsEnrolmentData = JSON.parse(
    window.sessionStorage.getItem('swsEnrolmentData')
  )

  if (!idToken || !refreshToken || !schoolData || !formData) {
    next('/error')
  }

  const schoolCode = schoolData ? schoolData.schoolCode : ''
  // school code query string must be present to begin an application
  if (!schoolCode) {
    Vue.$log.error('Not set: schoolCode')
    store.commit('setError', new Error('schoolCode must be set'))
    next('/error')
    return
  }
  // `schoolCode` must be comprised of digits with a length between one and four
  if (!/^\d{1,4}$/.test(schoolCode)) {
    Vue.$log.error(`Incorrect schoolCode: ${schoolCode}`)
    store.commit('setError', new Error(`Incorrect schoolCode (${schoolCode})`))
    next('/error')
    return
  }
  window.sessionStorage.setItem('schoolCode', schoolCode)

  const parentId = jwtDecode(idToken)['custom:pid']
  const childSrn = formData.child ? formData.child.srn : ''
  const isLinkedChild = !!(childSrn && childSrn !== 'new')
  const isChildFromApplication = !!(
    !isLinkedChild &&
    formData.child?.firstName &&
    formData.child?.lastName
  )

  store.commit('setIdToken', idToken)
  store.commit('setRefreshToken', refreshToken)
  store.commit('setSchool', schoolData)
  store.commit('setIsUserActive', true)
  store.commit('setLocalResidentialAddress', formData.address)
  store.commit('setCalendarYear', parseInt(formData.calendarYear, 10))
  store.commit('setViaEhub', true)
  store.commit('setIsEhubLinkedStudent', isLinkedChild)
  store.commit('setParentId', parentId)
  store.commit('setEmail', jwtDecode(idToken).email)
  store.commit('setEhubFormData', formData)
  store.commit('setChildSrn', childSrn)
  store.commit('setIsResuming', false)
  if (swsEnrolmentData) {
    store.commit('setSwsEnrolmentData', swsEnrolmentData)
  }

  if (isLinkedChild) {
    // get prefill data by srn
    await store.dispatch('getPrefillParentStudentBySrn', childSrn)
  } else if (isChildFromApplication) {
    // get prefill data by previously submitted application
    await store.dispatch('getPrefillParentStudentByName', {
      firstName: formData.child.firstName,
      lastName: formData.child.lastName
    })
  } else {
    await store.dispatch('getPrefillParentStudentForNewChild')
  }

  // window.sessionStorage.removeItem('idToken')
  // window.sessionStorage.removeItem('refreshToken')
  // window.sessionStorage.removeItem('formData')
  // window.sessionStorage.removeItem('schoolData')

  next()
}
