import axios from 'axios'
import { CATCHMENTS } from '../../constants'

// Environment variables
const API_BASE = process.env.VUE_APP_API_BASE

const getPredictiveAddresses = (addressVal) => {
  const url = `${API_BASE}/nswpoint/v2/api/predictive1`

  return axios.get(url, {
    params: {
      address: addressVal,
      addressType: 'physical', // 'physical' prevents looking up PO boxes
      maxNumberOfResults: 10
    },
    headers: {
      'x-preflight': 'force'
    },
    disableDefaultErrorHandling: true
  })
}

const getFullAddressInfo = (oItem) => {
  const url = `${API_BASE}/nswpoint/v2/api/predictive2`

  return axios.post(
    url,
    { id: oItem.id },
    {
      headers: { 'x-preflight': 'force' }
    }
  )
}

const getAddressCatchment = async (
  baseUrls,
  coordinateData,
  catchmentLevel,
  selectedYear,
  scholasticYear
) => {
  const long = coordinateData[0]
  const lat = coordinateData[1]
  const catchmentLevels = [CATCHMENTS.INFANTS, CATCHMENTS.PRIMARY].includes(
    catchmentLevel.toLowerCase()
  )
    ? `('${CATCHMENTS.INFANTS}', '${CATCHMENTS.PRIMARY}')`
    : `('${catchmentLevel}')`
  const convertScholasticYear =
    scholasticYear === 'K' ? 'kindergarten' : `year${scholasticYear}`
  const queryString = `?q=
        + SELECT s.school_code, s.school_name, s.school_email, s.street, s.town_suburb, s.postcode, s.longitude, s.latitude, phone, website
        FROM   dec_schools_2020 s
        JOIN catchments_2020 c ON s.school_code = c.school_code
        WHERE  c.catchment_level IN ${catchmentLevels}
        AND    c.calendar_year = ${selectedYear}
        AND    c.${convertScholasticYear} = 'Y'
        AND    ST_CONTAINS(c.the_geom, ST_SetSRID(ST_Point(${long},${lat}), 4326));`

  const urls = baseUrls.map((url) => url + queryString)

  return axios
    .get(urls[0])
    .then((response) => response)
    .catch((error) => {
      if (urls.length > 1) {
        return axios
          .get(urls[1])
          .then((response) => response)
          .catch((error) => {
            throw error
          })
      }
      throw error // if there is no fallback url
    })
}

const getCatchmentAndYearData = (baseUrls, schoolCode) => {
  const queryString =
    '?q=' +
    `SELECT calendar_year, catchment_level from catchments_2020 where school_code = ${schoolCode};`

  // 'catchments_2020' is not actually 2020 the year, it's version number

  const urls = baseUrls.map((url) => url + queryString)
  return axios
    .get(urls[0])
    .then((response) => response)
    .catch((error) => {
      if (urls.length > 1) {
        return axios
          .get(urls[1])
          .then((response) => response)
          .catch((error) => {
            throw error
          })
      }
      throw error // if there is no fallback url
    })
}

export default {
  getPredictiveAddresses,
  getFullAddressInfo,
  getAddressCatchment,
  getCatchmentAndYearData
}
