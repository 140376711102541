import axios from 'axios'
import store from '@/store'

const API_BASE = process.env.VUE_APP_API_BASE

const APPLICATION_URL = `${API_BASE}/v1/ooa/applications/saved/self`

function fetchApplication() {
  return axios
    .get(APPLICATION_URL)
    .then((response) => response.data)
    .catch((error) => {
      store.dispatch('setAPIError', { error, fallbackCode: 'OB' })
      throw error
    })
}

function saveApplication(application) {
  axios
    .put(APPLICATION_URL, application)
    .then((response) => response.data.body)
    .catch((error) => {
      store.commit('setError', error)
      throw error
    })
}
// this action is required by the Backend to send application expiry email
function createNewApplication(application) {
  return axios
    .put(APPLICATION_URL, { ...application, isNew: true })
    .then((response) => response.data)
    .catch((error) => {
      store.commit('setError', error)
      throw error
    })
}

// this action is required by ehub to delete an exsiting application before creating a new one
function deleteApplication() {
  return axios
    .delete(APPLICATION_URL)
    .then((response) => response.data.body)
    .catch((error) => {
      store.commit('setError', error)
      throw error
    })
}

function submitApplication(applicationData) {
  const APPLICATION_URL = `${API_BASE}/v1/ooa/applications/submitted/self`
  const data = {
    application: applicationData,
    lastUpdatedTime: new Date().toISOString()
  }
  return axios
    .post(APPLICATION_URL, data)
    .then((response) => response.data)
    .catch((error) => {
      store.dispatch('setAPIError', { error, fallbackCode: 'OD' })
      throw error
    })
}

export default {
  saveApplication,
  createNewApplication,
  fetchApplication,
  submitApplication,
  deleteApplication
}
