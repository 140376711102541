function focusOnFirstError(formInputs) {
  if (!formInputs) {
    return false
  }

  const invalidInput = formInputs.find((input) => !input.valid)
  if (invalidInput) {
    if (invalidInput.focus) {
      invalidInput.focus()
      return true
    }
    // try and find the focusable input within a radio group
    const focusableInput = invalidInput.$el.querySelector('input')
    if (focusableInput && focusableInput.focus) {
      focusableInput.focus()
      return true
    }
  }
  return false
}

export default function handleFirstFocus(formInputs) {
  if (!focusOnFirstError(formInputs)) {
    const focusSelectors = [
      'input[data-initial-focus]',
      'textarea[data-initial-focus]',
      '[data-initial-focus] input', // radio button
      '.v-expansion-panels[data-initial-focus] button' // review section
    ]
    const focusElement = document.querySelector(focusSelectors.join(', '))
    if (focusElement) {
      focusElement.focus()
    }
  }
}
