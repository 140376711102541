import store from '@/store'
import router from '@/router'

const isNullOrUndefined = (val) => val === null || val === undefined

function studentStatus() {
  if (!store.getters.validStudent) {
    return store.getters.validStudent === false ? 'invalid' : 'initial'
  }
  return 'complete'
}
function parentCarerStatus() {
  if (!store.getters.validParentCarers) {
    return store.getters.validParentCarers === false ? 'invalid' : 'initial'
  }
  return 'complete'
}

function considerationsStatus() {
  if (store.getters.validConsiderations === false) {
    return 'invalid'
  }
  if (isNullOrUndefined(store.getters.validConsiderations)) {
    return 'initial'
  }
  return 'complete'
}

// Exported functions required to for Stepper to function.
export function currentStep() {
  return store.getters.currentStep
}

export function steps() {
  return [
    {
      key: 'student',
      name: 'Student details',
      step: 1,
      status: studentStatus()
    },
    {
      key: 'parentcarer',
      name: 'Parent/carer details',
      step: 2,
      status: parentCarerStatus()
    },
    {
      key: 'considerations',
      name: 'Considerations',
      step: 3,
      status: considerationsStatus()
    },
    {
      key: 'review',
      name: 'Review & submit',
      step: 4
    }
  ]
}

export function currentStepName() {
  return steps().find((item) => item.step === currentStep()).name
}

export function currentStepKey() {
  return steps().find((item) => item.step === currentStep()).key
}

export function selectStep(currentStep) {
  store.dispatch('setCurrentStep', { currentStep })
  const path = `/form/${currentStepKey()}`
  router.push(path)
}
