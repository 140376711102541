<!--
  Allow users to confirm the NSW public school catchment based on
  the auto complete address input by users
-->
<!-- eslint-disable max-len -->
<template>
  <v-form>
    <div class="accessibility-results-text d-sr-only" aria-live="polite">
      {{ resultsFoundAccessibilityText }}
    </div>
    <v-row>
      <v-col cols="12" lg="6" class="mt-2 mb-2">
        <div>
          <SelectMenu
            v-model="selectedYear"
            :items="enrolmentYears"
            label="Enrolling for"
            placeholder="Please select"
            data-testid="enrolment-years"
            @input="onEnrolmentYearChange"
          />
        </div>
      </v-col>
      <v-col
        v-if="schoolDataHasValidCatchmentLevel"
        cols="12"
        lg="6"
        class="mt-2 mb-2"
      >
        <div>
          <SelectMenu
            v-model="selectedScholasticYear"
            :items="scholasticYears"
            label="Scholastic year"
            placeholder="Please select"
            data-testid="scholastic-years"
            no-data-text="Select a calendar year first"
            @input="onScholasticYearChange"
          />
        </div>
      </v-col>
      <v-col
        v-if="!schoolDataHasValidCatchmentLevel"
        cols="12"
        lg="6"
        class="mt-2 mb-2"
      >
        <SelectMenu
          v-model="selectedCatchment"
          :items="catchmentLevels"
          label="School level"
          :placeholder="selectedCatchment ? null : 'Please Select'"
          data-testid="catchment-levels"
          @input="selectedCatchmentChanged"
        />
      </v-col>
    </v-row>
    <v-row v-if="showScholasticYearForCentral">
      <v-col
        v-if="!schoolDataHasValidCatchmentLevel"
        cols="12"
        lg="12"
        class="mt-2 mb-2"
      >
        <div>
          <SelectMenu
            v-model="selectedScholasticYear"
            :items="scholasticYears"
            label="Scholastic year"
            placeholder="Please select"
            data-testid="scholastic-years"
            @input="onScholasticYearChange"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="9">
        <div>
          <div id="addressMenuAnchor" style="position: relative" />
          <v-combobox
            ref="autocomplete"
            label="Student’s home address"
            placeholder="Enter your child's home address"
            :items="optionsList"
            item-text="address"
            :search-input.sync="searchInput"
            return-object
            hide-no-data
            prepend-inner-icon="search"
            clearable
            outlined
            hide-details
            no-filter
            attach="#addressMenuAnchor"
            :menu-props="{ maxHeight: 250 }"
            :error="!!errorMessage"
            data-testid="address-typeahead-select"
            @input="onTypeAheadSelect"
            persistent-placeholder
          />
        </div>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn
          :disabled="isButtonDisabled"
          class="btn-search"
          type="button"
          color="primary"
          data-testid="search-proceed-btn"
          @click="onBtnClick"
        >
          <v-progress-circular
            v-if="isConfirmingCatchment"
            :size="32"
            :width="4"
            color="white"
            indeterminate
            aria-label="loading"
            class="icon spin"
          />
          {{ buttonText }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="errorMessage">
      <v-col>
        <div aria-role="alert" aria-live="assertive" data-testid="errorMessage">
          <v-icon size="38px" color="failure">mdi-alert-circle</v-icon>
          <span class="failure--text ml-1"
            ><strong>{{ errorMessage }}</strong></span
          >
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import _uniqBy from 'lodash/uniqBy'
import {
  FIXED_CATCHMENT_LEVELS,
  CENTRAL_SCHOOL_CATCHMENT_OPTIONS,
  CATCHMENT_STATUSES,
  SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL,
  CATCHMENTS
} from '@/constants'
import catchmentFinder from '@/api/server/CatchmentFinder'
import SelectMenu from '@/components/form/SelectMenu'
import {
  capitalize,
  getQueryYear,
  getFallBackQueryYear,
  getQueryYearLookupArray
} from '@/helpers'
import { mapGetters } from 'vuex'

// Moved to component because it's not used anywhere else
const ADDRESS_STATE = {
  INITIAL: 'INITIAL',
  WITHIN_AREA: 'WITHIN_AREA',
  OUT_OF_AREA: 'OUT_OF_AREA',
  NONE_FOUND: 'NONE_FOUND',
  CONFIRMING_CATCHMENT: 'CONFIRMING_CATCHMENT',
  INPUTTING_ADDRESS: 'INPUTTING_ADDRESS'
}

const genericErrorMessage =
  'Sorry, we can’t retrieve your address at the moment. Please try again later.'

export default {
  name: 'CatchmentFinder',
  components: {
    SelectMenu
  },
  props: {
    school: Object,
    address: String,
    enrolmentYear: String,
    scholasticYear: String,
    catchmentLevel: String
  },
  data() {
    const schoolCatchment = this?.school.catchmentLevel.toLowerCase()
    const schoolDataHasValidCatchmentLevel = FIXED_CATCHMENT_LEVELS.some(
      (c) => c === schoolCatchment
    )

    let selectedCatchment = null
    if (this.catchmentLevel) {
      selectedCatchment = this.catchmentLevel // From query parameter
    } else if (schoolDataHasValidCatchmentLevel) {
      selectedCatchment = schoolCatchment // From oes-pilot-school.json
    }

    if (schoolCatchment === 'central' && this.scholasticYear != null) {
      this.$emit('update:school', {
        ...this.school,
        initialCatchmentLevel: schoolCatchment
      })
      if (
        SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL.PRIMARY.contains(
          this.scholasticYear
        )
      ) {
        this.$emit('update:school', {
          ...this.school,
          catchmentLevel: CATCHMENTS.PRIMARY
        })
        this.selectedCatchment = CATCHMENTS.PRIMARY
      } else if (
        SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL.SECONDARY.contains(
          this.scholasticYear
        )
      ) {
        this.$emit('update:school', {
          ...this.school,
          catchmentLevel: CATCHMENTS.SECONDARY
        })
        this.selectedCatchment = CATCHMENTS.SECONDARY
      }
    }
    return {
      originalAddress: null,
      selectedAddress: null,
      optionsList: [],
      addressState: ADDRESS_STATE.INITIAL,
      searchInput: '',
      selectedYear: this.enrolmentYear || null,
      enrolmentYears: [],
      selectedScholasticYear: this.scholasticYear || null,
      scholasticYears: [],
      queryYearLookupArray: [],
      selectedCatchment,
      catchmentLevels: CENTRAL_SCHOOL_CATCHMENT_OPTIONS,
      schoolDataHasValidCatchmentLevel,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters(['states', 'oesProperties']),
    // todo: refactor away these component states and determine
    // what to display based on the data in the component.
    initial() {
      return this.addressState === ADDRESS_STATE.INITIAL
    },
    isWithInArea() {
      return this.addressState === ADDRESS_STATE.WITHIN_AREA
    },
    isOutOfArea() {
      return this.addressState === ADDRESS_STATE.OUT_OF_AREA
    },
    isNoneFound() {
      return this.addressState === ADDRESS_STATE.NONE_FOUND
    },
    isInputtingAddress() {
      return this.addressState === ADDRESS_STATE.INPUTTING_ADDRESS
    },
    isConfirmingCatchment() {
      return this.addressState === ADDRESS_STATE.CONFIRMING_CATCHMENT
    },
    isOriginalAddress() {
      return (
        this.originalAddress &&
        this.selectedAddress &&
        this.originalAddress.id === this.selectedAddress.id
      )
    },
    schoolFinderURLs() {
      const endPoints = this.oesProperties ? this.oesProperties.endpoints : null
      return endPoints && endPoints.schoolFinder && endPoints.schoolFinder.urls
    },
    resultsFoundAccessibilityText() {
      return this.optionsList.length
        ? `${this.optionsList.length} results have been found`
        : null
    },
    canVerifyAddress() {
      return this.selectedCatchment && this.selectedYear && this.selectedAddress
    },
    buttonText() {
      if (this.isConfirmingCatchment) {
        return 'Searching...'
      }
      if (this.isWithInArea || this.isOutOfArea) {
        return 'Proceed'
      }
      return 'Search'
    },
    isButtonDisabled() {
      return (
        this.initial ||
        this.isNoneFound ||
        this.isConfirmingCatchment ||
        !this.selectedYear ||
        !this.selectedScholasticYear ||
        !this.selectedCatchment
      )
    },
    showScholasticYearForCentral() {
      return this.selectedYear != null && this.selectedCatchment != null
    }
  },
  watch: {
    searchInput(val) {
      this.errorMessage = null
      if (!val) {
        this.addressState = ADDRESS_STATE.INITIAL
        this.optionsList = []
        return
      }

      // Remove multiple instances of whitespace, but preserve spaces in the address sent to the API
      const sanitisedVal = this.sanitiseValue(val)

      if (sanitisedVal.trim().length <= 4) {
        this.addressState = ADDRESS_STATE.INITIAL
      } else {
        this.searchAddress(sanitisedVal)
      }
    },
    schoolFinderURLs: {
      handler() {
        // If enrolmentYears aren't yet set and schoolfinderURLs are now set
        if (
          this.enrolmentYears.length === 0 &&
          this.schoolFinderURLs &&
          this.schoolFinderURLs.length > 0
        ) {
          this.getCatchmentAndYearData()
        }
      },
      immediate: true
    }
  },
  created() {
    this.getReferenceData()
      .then(() => {
        this.getScholasticYears().then(() => {
          this.scholasticYears = this.getScholasticYearsDropdown()
        })
      })
      .catch((err) => {
        this.$store.commit('setError', err)
      })
  },
  mounted() {
    if (this.address) {
      const sanitisedAddress = this.sanitiseValue(this.address)
      if (sanitisedAddress.length >= 4) {
        catchmentFinder
          .getPredictiveAddresses(sanitisedAddress)
          .then((resp) => {
            this.resolvePredictiveAddresses(resp)
            this.originalAddress = this.optionsList[0] || sanitisedAddress
            this.$refs.autocomplete.setValue(this.originalAddress)
          })
          .catch(() => {
            this.errorMessage = genericErrorMessage
          })
      } else {
        this.$refs.autocomplete.setValue(sanitisedAddress)
      }
    }
  },
  methods: {
    sanitiseValue(val) {
      return val.replace(/^\s+/, '').replace(/\s+/g, ' ')
    },
    onTypeAheadSearch(val) {
      this.addressState = ADDRESS_STATE.INPUTTING_ADDRESS

      // Send typed address to predictive API
      catchmentFinder
        .getPredictiveAddresses(val)
        .then(this.resolvePredictiveAddresses)
        .catch(() => {
          this.errorMessage = genericErrorMessage
        })
    },
    onTypeAheadSelect(val) {
      if (val && typeof val === 'object') {
        // combo-box emits input event on blur with a string argument, which we do not want.
        this.selectedAddress = val

        if (this.canVerifyAddress) {
          this.addressState = ADDRESS_STATE.CONFIRMING_CATCHMENT
          catchmentFinder
            .getFullAddressInfo(val)
            .then(this.resolveFullAddressInfo)
            .then((resp) => {
              const [long, lat] = resp.data.data.geo.geometry.coordinates
              const queryYear = getQueryYear(
                this.queryYearLookupArray,
                this.selectedCatchment,
                this.selectedYear
              )
              // if the normal query year returns no data, then use the fallBackQueryYear
              const fallbackQueryYear = getFallBackQueryYear(
                this.queryYearLookupArray,
                this.selectedCatchment,
                this.selectedYear
              )
              // Send coordinates to catchment API, which returns list of schools within catchment.
              return this.$store
                .dispatch('fetchLocalCatchmentSchool', {
                  long,
                  lat,
                  catchmentLevel: this.selectedCatchment,
                  selectedYear: queryYear,
                  scholasticYear: this.selectedScholasticYear
                })
                .then((response) => {
                  if (response.data.rows.length > 0 || !fallbackQueryYear) {
                    return response
                  }
                  // Due to the bad design of third party API, if the normal queryYear doesn't return any school
                  // use the fallbackQueryYear to call the API again.
                  return this.$store.dispatch('fetchLocalCatchmentSchool', {
                    long,
                    lat,
                    catchmentLevel: this.selectedCatchment,
                    selectedYear: fallbackQueryYear,
                    scholasticYear: this.selectedScholasticYear
                  })
                })
            })
            .then(this.resolveAddressCatchment)
            .catch(() => {
              this.errorMessage = genericErrorMessage
            })
        }
      }
    },
    resolvePredictiveAddresses(resp) {
      const addresses = _uniqBy(resp.data, (i) => i.address)

      // Sometimes the API returns something other than an array. For some
      // reason this has been historically ignored. Bail early if we do have an
      // array, but it's empty.
      if (!Array.isArray(addresses) || addresses.length === 0) {
        return
      }
      this.optionsList = addresses
    },
    resolveFullAddressInfo(resp) {
      const addressData = resp.data.data.addressDetails

      let stateData = null
      if (this.states) {
        stateData = this.states.find(
          (item) => item.value === addressData.stateTerritory
        )
      }

      // Default country and state codes to `XXX` (unknown)
      let countryCode = 'XXX'
      let stateCode = 'XXX'

      if (stateData) {
        countryCode = 'AUS'
        stateCode = stateData.value
      }

      // Set the primitive address object
      const address = {
        addressLine1: capitalize(addressData.formattedAddress.split(',')[0]),
        suburbName: capitalize(addressData.localityName),
        // #DSE-685: postcode deliberately lower-case due to APIs
        postCode: addressData.postcode,
        stateCode,
        countryCode
      }

      this.$store.commit('setLocalResidentialAddress', address)

      // Make response available to any following Promises
      return resp
    },
    resolveAddressCatchment() {
      const { localCatchmentSchools } = this.$store.getters
      const residentialAddress = this.$store.getters.localResidentialAddress

      let localCatchmentSchool
      if (localCatchmentSchools && localCatchmentSchools.length !== 0) {
        localCatchmentSchool = localCatchmentSchools[0]
        if (localCatchmentSchools.length > 1) {
          const sameLocalCatchmentSchool = localCatchmentSchools.filter(
            (school) =>
              this.school.schoolCode === school?.schoolCode?.toString()
          )
          if (sameLocalCatchmentSchool.length > 0) {
            localCatchmentSchool = sameLocalCatchmentSchool[0]
          }
        }
      }

      if (localCatchmentSchool) {
        residentialAddress.long = localCatchmentSchool.long
        residentialAddress.lat = localCatchmentSchool.lat
      }

      this.$store.commit('setSelectedResidentialAddress', this.selectedAddress)
      this.$store.commit('setLocalResidentialAddress', residentialAddress)
      this.$store.commit('setCalendarYear', parseInt(this.selectedYear, 10))
      this.$store.commit('setScholasticYear', this.selectedScholasticYear)
      this.$store.commit('setSelectedCatchment', this.selectedCatchment)

      // `withinCatchment` is determined by the API schoolCode verses url param schoolCode
      const withinCatchment =
        this.school.schoolCode === localCatchmentSchool?.schoolCode?.toString()
      if (withinCatchment) {
        this.addressState = ADDRESS_STATE.WITHIN_AREA
        this.$store.commit('setInCatchment', CATCHMENT_STATUSES.WITHIN_AREA)
      } else if (localCatchmentSchool) {
        this.addressState = ADDRESS_STATE.OUT_OF_AREA
        this.$store.commit('setInCatchment', CATCHMENT_STATUSES.OUT_OF_AREA)
      } else {
        this.addressState = ADDRESS_STATE.OUT_OF_AREA
        this.$store.commit('setInCatchment', CATCHMENT_STATUSES.NO_SCHOOL_FOUND)
      }
    },
    onManualSearch() {
      window.setTimeout(() => {
        this.addressState = ADDRESS_STATE.NONE_FOUND
      }, 500)
    },
    nextStep() {
      // eslint-disable-next-line no-unused-expressions
      this.isOriginalAddress && this.isOutOfArea
        ? this.$router.push({ name: 'privacy' })
        : this.$router.push({ name: 'schoolreminder' })
    },
    async getReferenceData() {
      await this.$store.dispatch('getReferenceData').catch(() => {
        this.$router.push({ name: 'error' })
      })
    },
    async getScholasticYears() {
      await this.$store
        .dispatch('getScholasticYears', { schoolCode: this.school.schoolCode })
        .catch(() => {
          this.$router.push({ name: 'error' })
        })
    },
    getScholasticYearsDropdown() {
      if (
        this.selectedYear == null ||
        !this.$store.state.refData?.scholasticYears
      ) {
        return []
      }

      const scholasticYears =
        this.$store.state.refData?.scholasticYears[this.selectedYear]
      const sYearsForCatchment =
        SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL[
          this.selectedCatchment?.toUpperCase()
        ] || []
      return scholasticYears
        ? scholasticYears.filter((year) =>
            sYearsForCatchment.includes(year.value)
          )
        : []
    },
    searchAddress(address) {
      if (this.addressState !== ADDRESS_STATE.CONFIRMING_CATCHMENT) {
        clearTimeout(this.searchTimerId)
        this.searchTimerId = setTimeout(() => {
          this.onTypeAheadSearch(address)
        }, 500)
      }
    },
    getCatchmentAndYearData() {
      catchmentFinder
        .getCatchmentAndYearData(this.schoolFinderURLs, this.school.schoolCode)
        .then(({ data: { rows } }) => {
          if (rows.length === 1 && rows[0].calendar_year === 0) {
            // handle 0 calendar year case
            const currentYear = new Date().getFullYear()
            this.enrolmentYears = [
              {
                value: `${currentYear + 1}`,
                text: `${currentYear + 1} calendar year`
              }
            ]

            this.queryYearLookupArray = [
              {
                catchmentLevel: rows[0].catchment_level,
                calendarYear: currentYear + 1,
                queryYear: 0,
                fallbackQueryYear: currentYear
              }
            ]
          } else {
            // Get all calendar years and catchment levels
            const calendarYears = rows.map((row) => row.calendar_year)
            const catchmentLevels = rows.map((row) => row.catchment_level)

            // Remove the duplicates and sort years
            const uniqueCalendarYears = Array.from(
              new Set(calendarYears)
            ).sort()
            const uniqueCatchmentLevels = Array.from(new Set(catchmentLevels))

            // Take the base year as the lowest non-zero year
            const baseYear =
              uniqueCalendarYears[0] === 0
                ? uniqueCalendarYears[1]
                : uniqueCalendarYears[0]
            const nextYear = baseYear + 1

            // Set the 2 years to appear in the dropdown
            this.setEnrolmentYears(baseYear, nextYear)

            this.generateQueryYearLookupArray(
              rows,
              uniqueCatchmentLevels,
              baseYear,
              nextYear
            )
          }
        })
    },
    setEnrolmentYears(year1, year2) {
      this.enrolmentYears = [
        {
          value: `${year1}`,
          text: `${year1} calendar year`
        },
        {
          value: `${year2}`,
          text: `${year2} calendar year`
        }
      ]
    },
    generateQueryYearLookupArray(
      rows,
      uniqueCatchmentLevels,
      baseYear,
      nextYear
    ) {
      this.queryYearLookupArray = getQueryYearLookupArray(
        rows,
        uniqueCatchmentLevels,
        baseYear,
        nextYear
      )
    },
    onEnrolmentYearChange() {
      this.scholasticYears = this.getScholasticYearsDropdown()

      if (this.canVerifyAddress) {
        this.onTypeAheadSelect(this.selectedAddress)
      }
    },
    selectedCatchmentChanged() {
      this.selectedScholasticYear = null
      this.$store.commit('setSelectedCatchment', this.selectedCatchment)
      this.onEnrolmentYearChange()
    },
    onScholasticYearChange() {
      this.$store.commit('setScholasticYear', this.selectedScholasticYear)
    },
    onBtnClick() {
      if (
        this.isInputtingAddress &&
        this.selectedYear &&
        this.selectedCatchment
      ) {
        this.onManualSearch()
      } else if (this.isOutOfArea || this.isWithInArea) {
        this.nextStep()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btn-search,
.btn-apply {
  width: 100%;
}
.icon {
  &.spin {
    margin-right: 1rem;
  }
}
::v-deep .v-autocomplete input {
  text-transform: uppercase;
}
::v-deep .v-autocomplete input::placeholder {
  text-transform: none;
}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details,
::v-deep .v-autocomplete .v-input__icon.v-input__icon--append {
  display: none;
}
::v-deep .v-list-item.v-list-item--link.theme--light:focus {
  background-color: $ads-light-10;
}
</style>
