<template>
  <v-dialog v-model="show" :max-width="maxWidth">
    <v-card>
      <v-card-title class="pl-10">
        <v-row>
          <v-col md="11" cols="11">
            <div class="mt-4 title">
              <v-row>
                <v-col v-if="icon" cols="2" md="1">
                  <v-avatar color="rgb(199, 220, 240)" size="40" class="mr-2">
                    <v-icon>{{ icon }}</v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="10" md="11">
                  {{ title }}
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col md="1" cols="1">
            <div class="d-flex justify-end">
              <v-btn
                v-if="displayCloseBtn"
                icon
                aria-label="close"
                @click="show = false"
              >
                <v-icon class="dialog--close-icon"> close </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="content pl-10">
        <slot name="message">
          {{ message }}
        </slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <div v-for="action in actionsList" :key="action.name" class="pr-4 pl-4">
          <v-btn
            class="ma-2 textWhite btnPadding"
            :aria-label="action.name"
            :color="action.color"
            action.size
            :outlined="action.outlined"
            :height="btnHeight"
            @click="$emit(action.action)"
          >
            <v-icon v-if="action.btnIcon" class="mr-1">
              {{ action.btnIcon }}
            </v-icon>
            {{ action.name }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'OoaDialog',
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '675px'
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default() {
        return []
      }
    },
    displayCloseBtn: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    btnClass: {
      type: String,
      default: ''
    },
    btnHeight: {
      type: String,
      default: '50'
    },
    returnHandler: {
      type: Function,
      default(name) {
        return name
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.openDialog
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    actionsList() {
      return this.actions
    }
  }
}
</script>
<style lang="scss" scoped>
.rightAlign {
  text-align: right;
}
.title {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold !important;
  color: $ads-navy !important;
  word-break: break-word;
}
.content {
  font-size: 16px;
  line-height: 24px;
  color: #041e42 !important;
}
.textWhite {
  color: $ads-white;
}
.v-card__actions .v-btn.btnPadding {
  padding: 0 16px;
}
::v-deep .v-btn__content {
  font-size: 16px;
  font-weight: bold;
}
::v-deep .dialog--close-icon {
  color: $ads-dark-60 !important;
}
</style>
