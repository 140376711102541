export function setSessionItem(key, value) {
  window.sessionStorage.setItem(key, btoa(JSON.stringify(value)))
}

export function getSessionItem(key) {
  const sessionData = window.sessionStorage.getItem(key)
  if (sessionData) {
    return JSON.parse(atob(sessionData))
  }
  return null
}

export function removeSessionItem(key) {
  window.sessionStorage.removeItem(key)
}

export function removeLocalStorageItem(key) {
  window.localStorage.removeItem(key)
}
