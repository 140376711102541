import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import axios from 'axios'
import axiosRetry, { isRetryableError, isNetworkError } from 'axios-retry'
import GTM from '@gtm-support/vue2-gtm'
import 'url-search-params-polyfill'
import { OAuth } from '@nswdoe/doe-ui-core'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import App from './App.vue'

const isProduction = process.env.NODE_ENV === 'production'

Vue.config.productionTip = false

// Global event bus
Vue.prototype.$eventHub = new Vue()

// Axios Request / Response Interceptor for handling Refresh & Auth tokens.
// Axios helpers
let isRefreshing = false
let requestsAwaitingRefresh = []

function addToRefreshWaitQueue(callback) {
  requestsAwaitingRefresh.push(callback)
}
function onRefreshed() {
  requestsAwaitingRefresh.map((callback) => callback())
}

// Retries if network error or 5xx status
function exponentialDelay(baseDelay = 100) {
  return (retryNumber = 0) => {
    const delay = 2 ** retryNumber * baseDelay
    const randomSum = delay * 0.2 * Math.random() // 0-20% of the delay
    return delay + randomSum
  }
}

function oesRetryableErrors(err) {
  const retryableErrors = [400, 403, 404, 500, 502, 504]
  return retryableErrors.includes(err.response.status)
}

axiosRetry(axios, {
  retries: 2,
  retryDelay: exponentialDelay(300),
  retryCondition: (e) =>
    isRetryableError(e) || isNetworkError(e) || oesRetryableErrors(e)
})

// Authorisation request interceptor.
// Adds the Authorization header to application api (saved/submitted) requests.
axios.interceptors.request.use(
  (request) => {
    const token = store.getters.idToken
    if (
      (request.url.includes('/applications/') ||
        request.url.includes('/application/') ||
        request.url.includes('pid.api.enrol')) &&
      token
    ) {
      request.headers.Authorization = `Bearer ${token}`
    }
    return request
  },
  (error) => Promise.reject(error)
)

// Refresh token response interceptor
axios.interceptors.response.use(
  // return a successful response with no processing
  (response) => response,
  (error) => {
    const originalRequest = error.config
    if (originalRequest.disableDefaultErrorHandling) {
      // pass on the error to be handled externally
      return Promise.reject(error)
    }
    const { status } = { ...error.response } // avoiding destructuring undefined

    // Token refresh or auth related API didn't work,
    // so Logout user and clear the auth details from store.
    if (originalRequest.url.includes('passwordless')) {
      if (originalRequest.url.includes('initiateAuth')) {
        // Specific error handling implemented for email API errors.
        return Promise.reject(error)
      }
      if (
        error.response.status === 400 &&
        store.getters.otpRemainingAttempts === 1
      ) {
        // Enters here if the otpCode attempt has been failed 3 times.
        return Promise.reject(error)
      }
      if (
        error.response.status === 400 &&
        error.response.data.message ===
          'NotAuthorizedException - Invalid session for the user.'
      ) {
        // Enters here if the otpCode is older than 3 minutes.
        return Promise.reject(error)
      }
      store.dispatch('logout').then(router.push({ name: 'home' }))
      return Promise.reject(error)
    }

    // can use to skip lookup student api error
    // if (originalRequest.url.includes('lookupStudent')) {
    //   return Promise.resolve()
    // }

    // No auth header or invalid auth header, AWS API Gateway may not respond with a CORS Header
    // https://forums.aws.amazon.com/message.jspa?messageID=763752
    // https://github.com/axios/axios/issues/883
    if (!error.response || error.response.status !== 401) {
      if (error.response) {
        router.push({ name: 'error' })
      }
      return Promise.reject(error)
    }

    // Check if its a 401 & invoke refreshToken action, before retrying the request
    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true
        store
          .dispatch('refreshToken')
          .then(({ status }) => {
            if (status === 200 || status === 204) {
              isRefreshing = false
              // Refreshing complete and new token set. Re-run failed requests and empty the queue.
              onRefreshed()
              requestsAwaitingRefresh = []
            }
          })
          .catch((error) => {
            // Token refresh didn't work, so logout user and clear the auth details from the store.
            store.dispatch('logout').then(router.push({ name: 'home' }))
            throw error
          })
      }

      return new Promise((resolve) => {
        addToRefreshWaitQueue(() => {
          resolve(axios(originalRequest))
        })
      })
    }

    // Should really get here... But ESLint wants this.
    return Promise.reject(error)
  }
)

const gtmQueryParams =
  process.env.VUE_APP_ENV_NAME === 'PROD'
    ? {}
    : {
        gtm_auth: 'VvFRnunnDibaeEsFyLpetw',
        gtm_preview: 'env-5',
        gtm_cookies_win: 'x'
      }

// Adds Google Tag Manager tracking
Vue.use(GTM, {
  id: process.env.VUE_APP_GTM_ID,
  debug:
    process.env.VUE_APP_ENV_NAME === 'TEST' ||
    process.env.VUE_APP_ENV_NAME === 'PREPROD',
  vueRouter: router,
  queryParams: gtmQueryParams
})

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  showLogLevel: true,
  showMethodName: true,
  showConsoleColors: true
})

Vue.use(OAuth, {
  router,
  pkce: true, // set to false for implicit auth
  authoriseUri: process.env.VUE_APP_SNSW_AUTH_URL,
  tokenUri: process.env.VUE_APP_SNSW_TOKEN_URL,
  forceProdMode: true, // redirect to SNSW login in local and dev env

  // authorise url params
  params: {
    client_id: process.env.VUE_APP_SNSW_AUTH_CLIENT_ID,
    redirect_uri: `${window.location.origin}/login/callback/`,
    scope: 'openid profile email'
  }
})

new Vue({
  router,
  store,
  vuetify,
  created() {
    this.$store.dispatch('getOESProperties').catch(() => {})
  },
  render: (h) => h(App)
}).$mount('#app')
