import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import doeColors from '@/settings/doe-colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: doeColors.primary.navy,
        secondary: doeColors.primary.lightblue,
        accent: doeColors.secondary.blue1,
        success: doeColors.secondary.blue2,
        failure: doeColors.primary.red,
        orange: {
          base: '#FF7F2F',
          darken1: '#DC5800'
        },
        grey: {
          darken1: '#121212'
        },
        blue: {
          base: '#2e5299',
          lighten1: '#c8dcf0'
        }
      }
    }
  }
})
