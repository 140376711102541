<template>
  <!-- eslint-disable max-len -->

  <div>
    <Banner>
      <h2 data-testid="signed-out-banner">You are now signed out</h2>
    </Banner>
    <v-container fluid class="ooaContent" data-testid="signed-out-content">
      <h3 class="pt-6 pb-6">
        Your online application has been saved, and you can resume your
        application at any time.
      </h3>
      <div>
        <v-row>
          <v-col
            cols="12"
            md="2"
            :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
          >
            <v-avatar size="88" color="secondary">
              <v-icon
                color="primary"
                size="60"
                alt="In progress applications"
                aria-label="In progress applications"
              >
                restore
              </v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="12" md="10">
            To resume your application later, just go to the school website you
            used to start this application, choose
            <strong>Apply for Enrolment Online</strong> then select the
            <strong>Resume application</strong> button. You can also follow the
            <strong>resume link</strong> sent to your email address.
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'

export default {
  name: 'LogoutView',
  components: {
    Banner
  }
}
</script>
