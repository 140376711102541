<template>
  <!-- eslint-disable max-len -->

  <div>
    <Banner>
      <h2>
        Please read and agree<br />
        to our privacy agreement
      </h2>
    </Banner>

    <v-container class="ooaContent py-12">
      <strong
        >As part of the NSW Department of Education's online enrolment process
        we need your consent to collect and store your personal information.
        Before consenting, please ensure you read the following:</strong
      >

      <h2 class="my-6">
        Why do we collect information about your child for enrolment and how do
        we store it?
      </h2>

      <p>
        The personal information collected on this application is for purposes
        directly related to your child's education including processing this
        application.
      </p>
      <p>
        Any information provided to the NSW Department of Education (the
        Department) will be used, disclosed and stored consistent with the NSW
        privacy laws.
      </p>
      <p>
        Certain information is required by the Department to meet its duty of
        care and other legal obligations under public health, education and
        child protection legislation and for meeting data collection and
        reporting requirements under Commonwealth – State funding agreements
        which may involve evaluation and assessment of student outcomes.
      </p>
      <p>
        Information may be disclosed to NSW State and Commonwealth government
        agencies and other organisations for the above purposes and as
        authorised or required by law.
      </p>
      <p>
        Information will be stored on a secure electronic database. You may
        access or correct the information by contacting your child's school. If
        you have a concern or complaint about the information collected or how
        it has been used or disclosed you should contact the school.
      </p>
      <p>
        If you choose not to provide some requested information it may have a
        detrimental impact on your child's enrolment, resourcing of the school
        or meeting your child's educational needs.
      </p>
      <p>
        Further information about the collection of information while your child
        is enrolled at a NSW Government school, and how we protect your privacy
        is available on the Department's website or from your school.
      </p>
      <p>
        After submitting your application, if you need to access or correct any
        personal or health information submitted you should contact the relevant
        school that you have applied for enrolment at.
      </p>
      <p>
        Further details setting out how the Department will treat the personal
        information and health information submitted as part of school enrolment
        processes (including permitted use and disclosure) is contained in:
      </p>

      <ul>
        <li>
          <a :href="`${privacyBaseUrl}/collection-notice.pdf`" target="_blank"
            >Data Collection Notice</a
          >
        </li>
        <li>
          <a :href="`${privacyBaseUrl}/privacy_code.pdf`" target="_blank"
            >Privacy Code of Practice</a
          >
        </li>
        <li>
          <a
            :href="`${privacyBaseUrl}/privacy-management-plan-march-2014.pdf`"
            target="_blank"
            >Privacy Management Plan</a
          >
        </li>
      </ul>

      <h2 class="my-6">
        What are we doing with information you provide to us online?
      </h2>

      <p>
        We will be collecting and analysing online enrolment usage data to help
        make this a better product into the future, making it easier for parents
        to enrol their children in our schools.
      </p>
      <p>
        At the end of the application process you will be asked to declare the
        accuracy of the information and submit. On submitting your application
        you will receive an email containing a password protected copy of your
        application.
      </p>
      <p>
        {{ schoolName }} will also receive a copy of this email. When you
        contact the school, they will advise you about which documents you will
        need to bring with you to the school.
      </p>

      <v-card outline flat class="my-6 pa-6">
        <h2 class="mb-4">Please confirm you accept this privacy agreement</h2>

        <p>
          If you do not agree, you will not be able to proceed with the online
          enrolment process. Don’t worry, you can still use existing enrolment
          processes to apply. You can find out more on our
          <a
            href="https://education.nsw.gov.au/public-schools/going-to-a-public-school"
            target="_blank"
            >going to a public school website</a
          >.
        </p>

        <v-form>
          <v-checkbox
            v-model="agree"
            hide-details
            label="I agree to the privacy agreement"
            required
          />
        </v-form>
      </v-card>

      <v-btn
        class="mt-4"
        large
        color="primary"
        :disabled="!agree"
        @click="proceedToLogin"
      >
        Continue
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import { mapGetters } from 'vuex'

export default {
  name: 'PrivacyView',
  components: {
    Banner
  },
  data() {
    return {
      agree: false,
      privacyBaseUrl:
        'https://education.nsw.gov.au/content/dam/main-education/about-us/rights-and-accountability/media/documents/privacy'
    }
  },
  computed: {
    ...mapGetters(['school']),
    schoolName() {
      return (this.school && this.school.schoolName) || 'The school'
    }
  },
  methods: {
    proceedToLogin() {
      // set the resuming flag to false to ensure correct logic flow during OTP
      this.$store.commit('setIsResuming', false)
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  border: 3px solid #041e42;
  background-color: transparent;
}

.v-btn {
  display: flex;
  margin-left: auto;
  width: min-content;
}
</style>
