// TODO: Remove gender codes and scholastic years and replace with appropriate ref data /
// scholasticYear API once we start building out the forms!

export const GENDER_CODES = [
  { text: 'Male', value: 'M' },
  { text: 'Female', value: 'F' }
]

export const FIELD_TYPE = {
  ADDRESS: 'ADDRESS',
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_GROUP: 'CHECKBOX_GROUP',
  COLLECTION: 'COLLECTION',
  DATE: 'DATE',
  DROPDOWN: 'DROPDOWN',
  EMAIL: 'EMAIL',
  GROUP: 'GROUP',
  HEADING: 'HEADING',
  LINKTO: 'LINKTO',
  LOOKUP: 'LOOKUP',
  MONTH_YEAR: 'MONTH_YEAR',
  NAME: 'NAME',
  PHONE: 'PHONE',
  POSTCODE: 'POSTCODE',
  RADIO: 'RADIO',
  TEXTAREA: 'TEXTAREA'
}

export const HEARTBEAT_EXEMPTIONS = [
  'ConfirmationView',
  'ErrorView',
  'LoginView',
  'PilotView',
  'WelcomeView'
]

export const ALERT_TYPE = {
  MISSING: 'MISSING',
  INVALID: 'INVALID',
  CONFLICT: 'CONFLICT'
}

export const ADDRESS_VALIDATION = {
  UNKNOWN: 'N',
  VALID: 'V'
}
export const ERROR_CODES = {
  NO_PENDING: 'NO_PENDING',
  NO_SCHOOL_CODE: 'NO_SCHOOL_CODE',
  NETWORK_ERROR: 'NETWORK_ERROR',
  API: 'API'
}

export const API_ERROR_SUBTYPES = {
  initiateAuth: 'PILIA',
  retrieveApplications: 'OB',
  submitApplication: 'OD',
  oesPilotSchoolJson: 'CPS',
  oesPropertiesJson: 'COP'
}

export const REFDATA_MAP = {
  ABORIGINALITY: 'Aboriginality',
  CONTACT_TYPE: 'ContactType',
  COUNTRIES: 'Countries',
  COUNTRIES_POPULAR: 'CountriesPopular',
  DISABILITY: 'Disability',
  LANGUAGES: 'Languages',
  LANGUAGES_POPULAR: 'LanguagesPopular',
  LEARNING_ADJUSTMENTS: 'LearningAdjustments',
  MEDICAL_CONDITION_ALLERGIES: 'MedicalConditionAllergies',
  MEDICAL_CONDITION_NAME: 'MedicationConditionName',
  OCCUPATION_GROUP: 'OccupationGroup',
  PARENT_SCHOOL_EDUCATION: 'ParentSchoolEducation',
  PARENT_TERTIARY_EDUCATION: 'ParentTertiaryEducation',
  PRESCHOOL_HOURS_TYPE: 'PreschoolHoursType',
  PRESCHOOL_TYPE: 'PreschoolType',
  RELATIONSHIP: 'Relationship',
  RESIDENCY_STATUS: 'ResidencyStatus',
  TITLE: 'Title',
  STATES: 'States',
  VISA_SUB_CLASS: 'VisaSubClass',
  TEMP_VISA_SUBCLASS: 'TempVisaSubClass'
}

// Default api date format
export const DATE_FORMAT = 'YYYY-MM-DD'

// Used as the value for intendedStartDate when enterStartDate is specified
export const CHOOSE_DATE_VALUE = 'Choose a specific start date'

// scholastic year primary/secondary values
export const SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL = {
  INFANTS: ['K', '1', '2'],
  PRIMARY: ['P', 'K', '1', '2', '3', '4', '5', '6'],
  SECONDARY: ['7', '8', '9', '10', '11', '12']
}

export const NODE_ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  TEST: 'TEST',
  PREPROD: 'PRE',
  PROD: 'PROD',
  TRAIN: 'TRAIN'
}

export const CATCHMENTS = {
  INFANTS: 'infants',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  CENTRAL: 'central'
}

// everything but central
export const FIXED_CATCHMENT_LEVELS = [
  CATCHMENTS.INFANTS,
  CATCHMENTS.PRIMARY,
  CATCHMENTS.SECONDARY
]

export const CENTRAL_SCHOOL_CATCHMENT_OPTIONS = [
  {
    text: 'Primary school',
    value: CATCHMENTS.PRIMARY
  },
  {
    text: 'Secondary school',
    value: CATCHMENTS.SECONDARY
  }
]

export const CATCHMENT_STATUSES = {
  WITHIN_AREA: 'withinArea',
  OUT_OF_AREA: 'outOfArea',
  NO_SCHOOL_FOUND: 'noSchoolFound'
}

export const NSW_EDUCATION_URL = 'https://education.nsw.gov.au/'

export const ERROR_MESSAGES = {
  NAME: "Can only contain letters, spaces, hyphens, left parenthesis, right parenthesis and apostrophes - ( ) '",
  PHONE_NUM: 'Can only contain 0-9, spaces and - ( ) +'
}

export const FILE_UPLOAD_ERROR_TYPES = {
  FILE_SIZE: 'EXCEED_MAX_FILE_SIZE',
  FILE_TYPE: 'INVALID_FILE_TYPE',
  FILE_NAME: 'INVALID_FILE_NAME',
  EXCEED_MAX_FILES: 'EXCEED_MAX_FILES'
}

export const FILE_UPLOAD_ERROR = {
  display: true,
  title: 'Files are currently being uploaded',
  type: 'error',
  html: '<p class="mb-2">Please wait until files have been uploaded before navigating away from this page.</p>',
  icon: 'mdi-alert-circle'
}

export const FILE_REMOVE_ERROR = {
  display: true,
  title: 'Files are currently being removed',
  type: 'error',
  html: '<p class="mb-2">Please wait until files have been removed before navigating away from this page.</p>',
  icon: 'mdi-alert-circle'
}

export function getFileUploadError(file, reason, action) {
  return {
    display: true,
    title: 'File did not upload',
    type: 'error',
    html: `<p class="mb-2"><strong>${file}</strong> did not upload because ${reason}<p>${
      action ? `<p>${action}</p>` : ''
    }`,
    icon: 'mdi-alert-circle'
  }
}

export function getFileRemoveError(file) {
  return {
    display: true,
    title: 'File could not be removed',
    type: 'error',
    html: `<p class="mb-2"><strong>${file}</strong> could not be removed because of a server error.</p><p>Please try removing the file again.</p>`,
    icon: 'mdi-alert-circle'
  }
}

export const DATE_OF_BIRTH_MINIMUM_YEAR = 20
export const DATE_OF_BIRTH_MAX_YEAR = 18
export const ATTENDED_FROM_UNTIL_MINIMUM_YEAR = 16

export const EHUB = 'ehub'

export const OOA = 'ooa'

export const PREFILL_FLAG = {
  STUDENT: 'student',
  PARENT: 'parent',
  STUDENT_AND_PARENT: 'student and parent',
  NO_DATA: 'no data'
}
