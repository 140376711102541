/* eslint-disable no-param-reassign */

import authApi from 'api-client/auth'

const authModule = {
  state: {
    email: null,
    cognitoSession: null,
    idToken: null,
    refreshToken: null,
    otpRemainingAttempts: 3,
    isUserActive: false,
    isLoggedOut: false
  },
  getters: {
    isUserActive: (state) => state.isUserActive,
    email: (state) => state.email,
    cognitoSession: (state) => state.cognitoSession,
    idToken: (state) => state.idToken,
    refreshToken: (state) => state.refreshToken,
    otpRemainingAttempts: (state) => state.otpRemainingAttempts,
    isLoggedOut: (state) => state.isLoggedOut
  },
  mutations: {
    setIsUserActive(state, isActive) {
      state.isUserActive = isActive
    },
    setEmail(state, email) {
      state.email = email
    },
    setCognitoSession(state, cognitoSession) {
      state.cognitoSession = cognitoSession
    },
    setIdToken(state, idToken) {
      state.idToken = idToken
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken
    },
    setOtpRemainingAttempts(state, attempts) {
      state.otpRemainingAttempts = attempts
    },
    setIsLoggedOut(state, isLoggedOut) {
      state.isLoggedOut = isLoggedOut
    }
  },
  actions: {
    initiateAuth({ commit }, { email: userId, recaptchaToken }) {
      return authApi.initiateAuth(userId, recaptchaToken).then((response) => {
        commit('setCognitoSession', response.cognitoSession)
        commit('setEmail', userId)
        // reset the otpRemainingAttempts when re-authenticating / re-sending code
        commit('setOtpRemainingAttempts', 3)
      })
    },
    authenticate({ commit, state }, otpCode) {
      return authApi
        .authenticate(otpCode, state.cognitoSession, state.email)
        .then((response) => {
          if (response.attemptsLeft) {
            commit(
              'setOtpRemainingAttempts',
              parseInt(response.attemptsLeft, 10)
            )
            commit('setCognitoSession', response.cognitoSession)
          } else {
            commit('setIdToken', response.idToken)
            commit('setRefreshToken', response.refreshToken)
            commit('setIsUserActive', true)
            commit('setOtpRemainingAttempts', 3)
          }
        })
        .catch((error) => {
          this._vm.$log.error(error) // eslint-disable-line no-underscore-dangle
          if (error.response.status === 400) {
            commit('setOtpRemainingAttempts', 0)
          }
        })
    },
    refreshToken({ commit, state }) {
      return authApi
        .refreshToken(state.refreshToken, state.email)
        .then((response) => {
          commit('setIdToken', response.body.idToken)
          // User must have submitted something to warrant a user token refresh, so we should set
          // the isUserActive flag to true.
          commit('setIsUserActive', true)
          return response
        })
    },
    inactivateSession({ commit }) {
      commit('setCognitoSession', null)
      commit('setIdToken', null)
      commit('setRefreshToken', null)
      commit('setIsUserActive', false)
      commit('setIsLoggedOut', false)
    },
    logout({ commit }) {
      commit('setCognitoSession', null)
      commit('setIdToken', null)
      commit('setRefreshToken', null)
      commit('setEmail', null)
      commit('setIsUserActive', false)
    }
  }
}

export default authModule
