<template>
  <!-- eslint-disable max-len -->
  <ErrorContent>
    <template #body="">
      <p>
        There are currently no active applications for
        <span class="redText"
          ><strong>{{ userID }}</strong></span
        >
        at {{ school.schoolName }}
      </p>
      <p>
        If you have entered your email address incorrectly, you can
        <strong>re-enter your email</strong> to try again.
      </p>
      <p>
        Alternatively, you can <strong>start a new application</strong> using
        this email address.
      </p>
    </template>
    <template #footer>
      <div class="btnSection">
        <span class="mr-5">
          <v-btn class="primary" x-large color="primary" @click="reEnterEmail">
            <v-icon class="pr-2">refresh</v-icon>Re-enter your email
          </v-btn>
        </span>
        <span class="mr-5"><strong>OR</strong></span>
        <span>
          <v-btn x-large outlined color="primary" @click="startNewApplication">
            <v-icon class="pr-2">add_circle_outline</v-icon>Start a new
            application
          </v-btn>
        </span>
      </div>
    </template>
  </ErrorContent>
</template>

<script>
import ErrorContent from '@/components/error/ErrorContent'

export default {
  name: 'NoPendingError',
  components: {
    ErrorContent
  },
  computed: {
    userID() {
      return this.$store.getters.email
    },
    school() {
      return this.$store.getters.school
    }
  },
  methods: {
    reEnterEmail() {
      this.$store.commit('setIsResuming', true)
      this.$router.push({ name: 'login' })
    },
    startNewApplication() {
      this.$router.push({
        name: 'home',
        query: { schoolCode: this.school.schoolCode }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.redText {
  color: $ads-error-red;
}
.mobile .btnSection span {
  display: block;
  margin-top: 10px;
}
</style>
