<template>
  <div class="APIError">
    <ErrorContent
      :include-stack-trace="includeStackTrace"
      :stack-trace="stackTrace"
    >
      <template #content>
        <ErrorCard :actions="actions" />
      </template>
    </ErrorContent>
  </div>
</template>

<script>
import { API_ERROR_SUBTYPES } from '@/constants'
import ErrorContent from '@/components/error/ErrorContent'
import ErrorCard from '@/components/error/ErrorCard'

export default {
  name: 'DefaultError',
  components: {
    ErrorContent,
    ErrorCard
  },
  props: {
    error: {
      type: Object,
      default: () => ({})
    },
    includeStackTrace: {
      type: Boolean,
      default: false
    },
    previousRoute: {
      type: Object,
      default: () => ({})
    },
    defaultAction: {
      type: Object
    }
  },
  data() {
    const actions = this.defaultAction ? [this.defaultAction] : []
    return {
      API_ERROR_SUBTYPES,
      actions
    }
  },
  computed: {
    errorObject() {
      return this.error.errorObject || {}
    },
    stackTrace() {
      return this.errorObject.stack || null
    }
  }
}
</script>

<style lang="scss" scoped>
.typed-email-headline {
  color: pink;
  line-height: normal;
  font-weight: bold;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
</style>
