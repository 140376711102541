import axios from 'axios'
import store from '@/store'
import { API_ERROR_SUBTYPES } from '@/constants'

const API_REFDATA = process.env.VUE_APP_API_REFDATA

const getPilotSchools = () => {
  const url = `${API_REFDATA}/oes-pilot-schools.json`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      const error = new Error('Network Error')
      store.dispatch('setAPIError', {
        error,
        fallbackCode: API_ERROR_SUBTYPES.oesPilotSchoolJson
      })
      throw error
    })
}

const getGlobalWebContentRef = () => {
  const url = `${API_REFDATA}/global-web-content-ref.json`

  return axios
    .get(url)
    .then((res) => res.data.ooa)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error(
        'Failed to load global web content data (global-web-content-ref.json)'
      )
      store.commit('setError', error)
      throw error
    })
}

const getWebCustomisation = (schoolCode) => {
  const url = `${API_REFDATA}/webCustomisation/${schoolCode}-web-customisation.json`

  /*
  The axios interceptor configured in main.js is redirecting to the error page on 404.
  This api could legitimately fail, so we have to use a different axios instance
  to avoid that interceptor
  */
  const axiosInstance = axios.create()
  return (
    axiosInstance
      .get(url)
      .then((res) => res.data)
      // the school might not be on-boarded correctly.
      .catch(() => null)
  )
}

export default {
  getPilotSchools,
  getGlobalWebContentRef,
  getWebCustomisation
}
