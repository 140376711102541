<template>
  <div class="ChipDropdown">
    <v-menu v-model="showMenu" offset-y :disabled="disabled">
      <template #activator="{ on }">
        <v-btn
          class="white red--text text--darken-1 text-uppercase text--bold font-weight-bold dropdown__button"
          :class="[{ 'dropdown__button--disabled': disabled }, chipClass]"
          tabindex="0"
          rounded
          v-on="on"
        >
          <slot name="title">Dropdown Menu Title</slot>
          <v-icon
            v-if="!disabled"
            class="red--text text--darken-1 ml-1 mr-n2"
            :class="iconClass"
          >
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <slot name="dropdown-content">
        <v-list dense>
          <v-subheader
            v-if="subHeader"
            class="dropdown__subheader font-weight-bold ml-2 black--text"
          >
            {{ subHeader }}
          </v-subheader>
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            class="dropdown__list-item"
            :href="item.externalLink"
            :to="item.internalLink"
            :disabled="item.disabled"
            :class="{ selected: item.selected, disabled: item.disabled }"
            v-on="{ click: itemClick(item) }"
          >
            {{ item.text }}
          </v-list-item>
        </v-list>
      </slot>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'ChipDropdown',
  props: {
    subHeader: {
      type: String
    },
    menuItems: {
      type: Array
    },
    chipClass: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    itemClick(item) {
      return (event) => {
        if (typeof item.onClick === 'function') {
          item.onClick(event)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content {
  margin-top: 8px; //Vuetify mt-2
}

.dropdown {
  &__subheader.v-subheader {
    cursor: default;
    font-size: 15px !important;
  }

  &__list-item {
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;
    font-weight: inherit;

    &.selected {
      cursor: default;
    }

    &.selected,
    &:hover {
      background-color: #f5f5f5;
    }
  }

  &__button {
    padding: 0 10px !important;
    min-height: auto !important;
    height: 32px !important;
    box-shadow: none;
    font-size: 14px !important;

    &--disabled {
      pointer-events: none;
    }
  }
}

.mdi-menu-down {
  width: 24px;
}
</style>
