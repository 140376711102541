import moment from 'moment'
import { CHOOSE_DATE_VALUE } from '@/constants'

export default function getTerms(terms, calendarYear, lateStart) {
  const isoToday = moment().format('YYYY-MM-DD')
  const validTerms = terms
    .filter((term) =>
      calendarYear
        ? parseInt(term.calendarYear, 10) === parseInt(calendarYear, 10) &&
          term.calendarLateInd === lateStart &&
          term.value >= isoToday
        : true
    )
    .map((term) => ({
      text: term.code,
      value: term.value
    }))
    .slice(0, 4) // slice in case no calendar year present

  return [
    ...validTerms,
    {
      text: 'Choose a specific start date',
      value: CHOOSE_DATE_VALUE
    }
  ]
}
