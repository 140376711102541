<template>
  <div data-testid="login-otp">
    <Banner>
      <h2 data-testid="verification-code">
        Please enter your<br />
        6-digit verification code
      </h2>
    </Banner>
    <v-container fluid class="ooaContent">
      <v-container class="form-container">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="elevation-2 pa-5 white"
          data-testid="verification-form"
          @submit.prevent="verifyOTP"
        >
          <v-row>
            <v-col>
              <span v-if="isResuming && !resumeViaEmail" class="headline-1">
                If an out-of-area enrolment application exists under
                <strong>{{ authEmail }}</strong
                >, we have sent a 6-digit verification code to this address.
                Check your email, then enter the code below
              </span>
              <span v-if="!isResuming" class="headline-1">
                We have sent an email to
                <strong>{{ authEmail }}</strong> containing a 6-digit
                verification code. <br />Check your email, then enter the code
                below.
              </span>
              <span v-if="resumeViaEmail" class="headline-1">
                We have sent an email to
                <strong>{{ authEmail }}</strong> containing a 6-digit
                verification code. <br />To resume your application, enter the
                code below.
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                ref="otpCode"
                v-model="otpCode"
                label="Verification code"
                placeholder="Enter your 6 digit code"
                data-testid="verification-code-input"
                outlined
                maxlength="6"
                required
                autofocus
                :error-messages="invalidOTPCode"
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                class="primary"
                x-large
                color="primary"
                :disabled="
                  otpCode == null ||
                  otpCode.length < 6 ||
                  $store.getters.otpRemainingAttempts === 0
                "
                data-testid="verify-code"
                @click="verifyOTP"
              >
                <ButtonSpinner :loading="loading" class="mr-2" />
                Verify
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-container data-testid="reenter-email">
        <v-row class="pl-5">
          <v-col>
            <span class="headline-1"
              >Didn't receive a code in your email? You can
              <router-link class="underline" :to="reEnterEmailRoute"
                >re-enter your email address</router-link
              >.
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import ButtonSpinner from '@/components/ButtonSpinner'
import doeColors from '@/settings/doe-colors'
import { ERROR_CODES } from '@/constants'
import { getGtmApplication } from '@/helpers'

export default {
  name: 'LoginOTP',
  components: {
    Banner,
    ButtonSpinner
  },
  props: {
    resumeViaEmail: {
      type: Boolean,
      default: false
    },
    userEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valid: false,
      loading: false,
      otpCode: null,
      notificationColors: doeColors.notification,
      invalidOTPCode: ''
    }
  },
  computed: {
    authEmail() {
      return this.$store.getters.email
    },
    isResuming() {
      return this.$store.getters.isResuming
    },
    reEnterEmailRoute() {
      return this.resumeViaEmail
        ? {
            name: 'login',
            params: { resumeViaEmail: true, userEmail: this.userEmail }
          }
        : { name: 'login' }
    }
  },
  methods: {
    enableLoader() {
      this.loading = true
    },
    disableLoader() {
      this.loading = false
    },
    validate() {
      this.valid = this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    async getSchoolDetails(schoolCode) {
      await this.$store.dispatch('isValidSchool', schoolCode).catch(() => {
        this.$router.push({ name: 'error' })
      })
    },
    verifyOTP() {
      this.invalidOTPCode = ''
      // extra check to ensure user hitting enter on form cannot submit an empty value.
      if (
        this.otpCode != null &&
        this.otpCode.length === 6 &&
        this.$store.getters.otpRemainingAttempts !== 0
      ) {
        // set loading state
        this.enableLoader()
        // only execute the authentication step if user has provided an OTP Code.
        this.$store
          .dispatch('authenticate', this.otpCode)
          .then(() => {
            if (this.$store.getters.otpRemainingAttempts === 3) {
              this.$store
                .dispatch('fetchApplication')
                .then(() => {
                  if (this.$store.getters.isPendingApplication) {
                    // There is a pending application for the authenticated user.
                    // Set the appropriate school if there's a mismatch
                    // between URL schoolCode & form schoolCode
                    this.$log.debug('Found pending application.')

                    // Google Analytics
                    const gtmApplication = getGtmApplication(false)
                    this.$gtm.trackEvent({
                      event: 'interaction',
                      category: 'Authentication',
                      action: 'Success',
                      label: 'Email',
                      application: gtmApplication
                    })

                    if (
                      this.$store.getters.school === null ||
                      this.$store.getters.school.schoolCode !==
                        this.$store.getters.form.schoolCode
                    ) {
                      this.getSchoolDetails(
                        this.$store.getters.form.schoolCode
                      ).then(() => {
                        window.sessionStorage.setItem(
                          'schoolCode',
                          this.$store.getters.school.schoolCode
                        )
                        this.$router.push({ name: 'form' })
                      })
                    } else {
                      this.$router.push({ name: 'form' })
                    }
                  } else if (this.$store.getters.isResuming) {
                    // No Pending Application, yet user is resuming. Raise appropriate error
                    this.$log.debug(
                      'Resuming application, but no application found.'
                    )
                    const errorType =
                      this.$store.getters.school &&
                      this.$store.getters.school.schoolCode !== null
                        ? ERROR_CODES.NO_PENDING
                        : ERROR_CODES.NO_SCHOOL_CODE
                    this.$store.dispatch('setErrorType', { errorType })
                    this.$router.push({ name: 'error' })
                  } else {
                    // No Pending Application & User is creating a new application
                    this.$log.debug('Creating a new application.')

                    // Google Analytics
                    const gtmApplication = getGtmApplication(false)
                    this.$gtm.trackEvent({
                      event: 'interaction',
                      category: 'Authentication',
                      action: 'Success',
                      label: 'Email',
                      application: gtmApplication
                    })

                    this.$store
                      .dispatch('createNewApplication')
                      .then(() => {
                        // Google Analytics
                        this.$gtm.trackEvent({
                          event: 'interaction',
                          category: 'Form Attempt',
                          action: 'start',
                          label: 'ooa',
                          application: getGtmApplication(false)
                        })

                        this.$router.push({
                          name: 'form',
                          params: { step: 'student' }
                        })
                      })
                      .catch(() => {
                        this.$router.push({ name: 'error' })
                      })
                      .finally(() => {
                        this.disableLoader()
                      })
                  }
                })
                .catch(() => {
                  this.$router.push({ name: 'error' })
                })
                .finally(() => {
                  this.disableLoader()
                })
              this.invalidOTPCode = ''
            } else {
              this.disableLoader()
              this.invalidOTPCode =
                'The entered code is incorrect. Please check the code and try again.'
              if (this.$refs.otpCode) {
                this.$refs.otpCode.focus()
              }
            }
          })
          .catch((error) => {
            this.disableLoader()
            this.$log.error(error)
            this.$router.push({ name: 'error' })
          })
      } else {
        this.invalidOTPCode = 'Please provide a 6 digit verification code.'
        if (this.$refs.otpCode) {
          this.$refs.otpCode.focus()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.headline-1 {
  font-size: 1rem; //16px if root font-size is 16px which it appears to be.
  font-weight: 500;
  color: $ads-navy;
  word-break: break-word;
}
.form-container {
  padding-top: 40px;
  border-radius: 4px;
}
</style>
