<template>
  <!-- eslint-disable max-len -->
  <div>
    <Banner compact>
      <h2>Application submitted</h2>
    </Banner>
    <v-container fluid class="ooaContent py-12">
      <ContentBox
        class="pt-6"
        img-file-name="green_tick.svg"
        data-testid="application-submitted"
      >
        <div slot="title">
          <h2 class="pb-4">
            Your application has now been submitted and has been sent to the
            school for review
          </h2>
        </div>
        <div slot="copyContent">
          For assistance please
          <a
            href="https://enrolmentassist.freshdesk.com/support/tickets/new"
            target="_blank"
            >Contact our Support Team</a
          >
        </div>
      </ContentBox>
      <v-divider />
      <ContentBox
        class="pt-9"
        img-file-name="whatsnext.svg"
        title="What’s next?"
        image-class="largeImage"
        data-testid="whats-next"
      >
        <div slot="copyContent">
          <p>
            We'll review your application and contact you soon. It might take
            longer during school holidays. If we need additional information or
            to arrange an interview before making a decision, we’ll let you
            know.
          </p>
        </div>
      </ContentBox>
      <ContentBox
        class="pt-9"
        img-file-name="survey-icon.svg"
        title="Complete our 1 minute survey"
        image-class="largeImage"
        data-testid="survey"
      >
        <div slot="copyContent">
          <p>
            Our goal is to create the best possible product, and your thoughts,
            ideas, and suggestions play a major role in helping us identify
            opportunities to improve.<br />
            <strong
              ><a
                href="https://digitalnsw.typeform.com/to/vDRv88"
                target="_blank"
                >Click here to start.</a
              ></strong
            >
          </p>
        </div>
      </ContentBox>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import ContentBox from '@/components/ContentBox'

export default {
  name: 'SubmittedApplication',
  components: {
    Banner,
    ContentBox
  }
}
</script>

<style lang="scss" scoped></style>
