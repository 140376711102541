<template>
  <v-progress-circular v-bind="attrs" />
</template>

<script>
export default {
  name: 'SpinnerComponent',
  computed: {
    attrs() {
      const defaultAttrs = {
        indeterminate: true,
        color: '#1D428A',
        size: 80,
        width: 7
      }
      return { ...defaultAttrs, ...this.$attrs }
    }
  }
}
</script>
