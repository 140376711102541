<template>
  <div class="ErrorContent container">
    <slot name="content">
      <div class="error__heading">
        <slot name="heading">
          <p class="heading__error-code">
            <slot name="error-code">
              <template v-if="errorCode">
                Error Code: {{ errorCode }}
              </template>
            </slot>
          </p>
          <p class="heading__error-id">
            <slot name="error-id">
              <template v-if="errorId"> Error ID: {{ errorId }} </template>
            </slot>
          </p>
        </slot>
      </div>
      <div class="error__body">
        <slot name="body">
          <p>
            {{
              errorText ||
              'An error has occurred and we’re trying to fix the problem.'
            }}
          </p>
        </slot>
      </div>
      <div class="error__footer">
        <slot name="footer">
          <div v-if="!fromEhub">
            <p>
              Please
              <a
                href="https://enrolmentassist.freshdesk.com/support/tickets/new"
                rel="external noopener"
                target="_blank"
                >contact our support team</a
              >
              for further assistance.
            </p>
          </div>
          <div v-else>
            <p>
              Please
              <a href="/dashboard">return to the dashboard</a>
              to continue.
            </p>
          </div>
        </slot>
      </div>
    </slot>
    <pre v-if="includeStackTrace && stackTrace" class="error__stack-trace">{{
      stackTrace
    }}</pre>
  </div>
</template>

<script>
import { isFromEhub } from '@/helpers'

export default {
  name: 'ErrorContent',
  props: {
    errorCode: {
      type: String
    },
    errorId: {
      type: String
    },
    errorText: {
      type: String
    },
    includeStackTrace: {
      type: Boolean,
      default: false
    },
    stackTrace: {
      type: String
    }
  },
  computed: {
    fromEhub() {
      return isFromEhub()
    }
  }
}
</script>

<style lang="scss" scoped>
.ErrorContent {
  .error {
    &__heading {
      margin-bottom: 1.25rem;
    }

    &__heading,
    &__heading * {
      font-weight: bold;
      color: $ads-navy;
    }

    &__body {
      margin-bottom: 1.25rem;
    }

    &__heading,
    &__heading *,
    &__body,
    &__body *,
    &__footer,
    &__footer * {
      font-size: 1.25rem;
    }

    &__stack-trace {
      border: 1px solid $ads-light-20;
      border-radius: 0.25rem;
      color: $ads-navy;
      background-color: white;
      padding: 1rem 1rem 0.375rem;
      margin-top: 1.875rem;
      font-size: 1rem;
      overflow: auto;
    }
  }
}
</style>
