export default {
  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    hint: {
      type: String
    },
    rules: {
      type: Array,
      default() {
        return []
      }
    },
    required: {
      type: Boolean
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    }
  },
  // computed: {
  //   // TODO(POST-MVP): Revise this, might be possible to use a
  //   closure somewhere to achieve the fix
  //   clonedRules() {
  //     const orig = this.rules
  //     const clone = [...orig]

  //     if (this.required) {
  //       clone.push(v => !!v || `${this.label} is required`)
  //     }

  //     return clone
  //   }
  // }
  created() {
    if (this.required) {
      this.rules.push((v) => !!v || `${this.label} is required`)
    }
  }
}
