export function getCognitoLogoutUrl() {
  const cognitoLogoutUrl = process.env.VUE_APP_COGNITO_LOGOUT_URI
  const clientId = process.env.VUE_APP_SNSW_AUTH_CLIENT_ID
  const cognitoCallBackUrl = `${window.location.protocol}//${window.location.host}/logout`
  return `${cognitoLogoutUrl}?client_id=${clientId}&logout_uri=${cognitoCallBackUrl}`
}

export function getCognitoLogoutOoaUrl() {
  const cognitoLogoutUrl = process.env.VUE_APP_COGNITO_LOGOUT_URI
  const clientId = process.env.VUE_APP_SNSW_AUTH_CLIENT_ID
  const cognitoCallBackUrl = `${window.location.protocol}//${window.location.host}/ooa/logout`
  return `${cognitoLogoutUrl}?client_id=${clientId}&logout_uri=${cognitoCallBackUrl}`
}

export function getSnswAccountUrl() {
  const snswAccountUrl = process.env.VUE_APP_SNSW_ACCOUNT_URI
  if (snswAccountUrl) {
    return snswAccountUrl
  }
  return ''
}
