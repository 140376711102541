<template>
  <!-- eslint-disable max-len -->
  <div data-testid="welcome-ooa">
    <Banner v-if="school" class="ooaBanner">
      <v-card
        v-if="school.logo"
        :class="$vuetify.breakpoint.xsOnly ? 'mr-4' : 'mr-10'"
        :max-height="$vuetify.breakpoint.xsOnly ? '50' : '118'"
        :max-width="$vuetify.breakpoint.xsOnly ? '50' : '118'"
      >
        <v-img
          :class="$vuetify.breakpoint.xsOnly ? 'imgMoble' : 'imgDesktop'"
          :height="$vuetify.breakpoint.xsOnly ? '38' : '89'"
          :width="$vuetify.breakpoint.xsOnly ? '38' : '89'"
          contain
          :src="school.logo"
          alt="school logo"
          data-testid="school-logo"
        />
      </v-card>
      <div
        :class="{
          mobleTitle: $vuetify.breakpoint.xsOnly,
          tabletTitle: $vuetify.breakpoint.smOnly,
          desktopTtile: $vuetify.breakpoint.mdAndUp
        }"
      >
        <h2 data-testid="school-name">{{ school.schoolName }}</h2>
        <p class="mt-2 mb-0">Out-of-area enrolment application</p>
      </div>
    </Banner>

    <v-container v-if="school" fluid class="ooaContent py-12">
      <!-- section: Who can apply? -->
      <ContentBox
        class="pt-6"
        img-file-name="icon-whats-online.svg"
        image-class="largeImage"
        title="Who can apply?"
      >
        <template slot="copyContent">
          <p>
            School age children living in NSW who are Australian or New Zealand
            citizens or Australian permanent residents.
          </p>
        </template>
      </ContentBox>

      <v-card class="mb-6 pt-2">
        <!-- section:  Additional learning and support needs -->
        <ContentBox
          class="pt-6"
          img-file-name="additional-learning-support.svg"
          image-class="largeImage"
        >
          <div slot="title">
            <h2 class="pb-2">Additional learning and support needs</h2>
          </div>
          <div slot="copyContent">
            <p>
              If your child has additional learning and support needs, contact
              your
              <a
                href="https://my.education.nsw.gov.au/school-finder"
                target="_blank"
                >local school principal</a
              >
              as soon as possible, before applying out-of-area.
              <a
                href="https://education.nsw.gov.au/teaching-and-learning/disability-learning-and-support/primary/getting-started"
                target="_blank"
                >Read more about inclusive learning support.</a
              >
            </p>
          </div>
        </ContentBox>
      </v-card>

      <v-card class="mb-6 pt-2">
        <!-- section: Will the school have a place for my child?
        underCapacity-->
        <ContentBox
          v-if="isUnderCap"
          class="pt-6"
          img-file-name="icon-will-have-space.svg"
          title="Will the school have a place for my child?"
          content="We currently can consider out-of-area applications. However, placement is not guaranteed."
          data-testid="under-capacity"
        />

        <!-- approchingCapacity -->
        <ContentBox
          v-if="isApproachingCap || isNACap"
          class="pt-6"
          img-file-name="icon-will-have-space.svg"
          title="Will the school have a place for my child?"
          data-testid="approaching-capacity"
        >
          <div slot="copyContent">
            <p>
              If places are available after local enrolments have been processed
              we can consider out-of-area applications. Placement is not
              guaranteed for out-of-area students.
            </p>
          </div>
        </ContentBox>

        <!-- overCapacity -->
        <ContentBox
          v-if="isOverCap"
          class="pt-6"
          img-file-name="icon-will-have-space.svg"
          title="Will the school have a place for my child?"
          data-testid="over-capacity"
        >
          <div slot="copyContent">
            <p>
              <strong>Our school has reached its enrolment cap.</strong> As per
              the
              <a
                href="https://education.nsw.gov.au/policy-library/policies/pd-2002-0006"
                target="_blank"
                >department’s policy</a
              >, we are not considering out-of-area applications unless
              exceptional circumstances apply. <br />Our enrolment cap has been
              set to support student wellbeing, quality education and avoid
              overcrowding.
            </p>
          </div>
        </ContentBox>

        <v-divider />

        <!-- section: How does the school assess applications? -->
        <ContentBox
          class="pt-6"
          img-file-name="icon-assess.svg"
          title="Assessing applications"
          data-testid="assessing-applications"
        >
          <div slot="copyContent">
            <p class="mb-6" :class="{ 'pb-6': $vuetify.breakpoint.xlOnly }">
              We consider applications that meet one or more of the following
              criteria.
            </p>
            <div v-if="schoolData" class="criteria">
              <div class="criteriaTitle">
                <img src="@/assets/icon-circle-tick.svg" alt="tick icon" />
                <h3 class="pb-2">Criteria we prioritise:</h3>
              </div>
              <div class="description">
                <ul class="criteriaPriorityList mb-6">
                  <li v-for="(pc, index) in priorityCriteriasList" :key="index">
                    {{ pc.value }}
                  </li>
                </ul>
              </div>
              <div class="criteriaTitle">
                <h3 class="pb-2">Criteria we consider:</h3>
              </div>
              <div class="description">
                <ul class="criteriaList mb-6">
                  <li
                    v-for="(criteria, i) in criteriaWithActiveStatus"
                    :key="i"
                  >
                    {{ criteria.value }}
                  </li>
                </ul>
              </div>
              <div class="criteriaTitle">
                <img src="@/assets/icon-circle-cross.svg" alt="cross icon" />
                <h3 class="errorRed">We will not consider the following:</h3>
              </div>
              <div class="description">
                <p>
                  Student ability, performance or achievement, as described in
                  the<br />
                  <a
                    href="https://education.nsw.gov.au/policy-library/policies/pd-2002-0006"
                    target="_blank"
                    >department's enrolment policy.</a
                  >
                </p>
              </div>
            </div>
          </div>
        </ContentBox>

        <v-divider />

        <!-- section: When are applications assessed? -->
        <ContentBox
          v-if="schoolData"
          class="pt-6"
          img-file-name="icon-timeframes.svg"
          title="When are applications assessed?"
        >
          <div slot="copyContent">
            <p data-testid="time-frames">
              {{ timeFrameCopy }}
              School holiday periods may increase assessment times.
            </p>
          </div>
        </ContentBox>

        <v-divider />

        <!-- section: Information exchange with currently enrolled and/or local school -->
        <ContentBox
          class="pt-6"
          img-file-name="icon-info-exchange.svg"
          title="Information exchange between schools"
        >
          <div slot="copyContent">
            <p>
              Schools exchange information about online and paper out-of-area
              applications to assist in planning and student care. We may share
              your child's application with:
              <br />- the NSW Government school they currently attend <br />-
              your local NSW Government school.
            </p>
            <p>
              Principals establish an enrolment panel for out-of-area enrolment
              applications, which includes a community member. The enrolment
              panel will have access to the information provided in your
              out-of-area application.
            </p>
          </div>
        </ContentBox>
      </v-card>

      <!-- Catchment finder -->
      <v-card class="pa-10">
        <template v-if="address">
          <h2 class="mt-2">Apply for out-of-area enrolment</h2>
          <ul>
            <li>
              Check the address you have entered below and make any necessary
              changes.
            </li>
          </ul>
        </template>
        <template v-else>
          <h2 class="mb-2">Apply for out-of-area enrolment</h2>
        </template>
        <CatchmentFinder
          :school.sync="school"
          :address="address"
          :enrolment-year="enrolmentYear"
          :scholastic-year="scholasticYear"
          :catchment-level="catchmentLevel"
        />
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import CatchmentFinder from '@/components/CatchmentFinder'
import ContentBox from '@/components/ContentBox'
import { mapGetters } from 'vuex'

export default {
  name: 'WelcomeView',
  components: {
    Banner,
    CatchmentFinder,
    ContentBox
  },
  data() {
    return {
      address: window.sessionStorage.getItem('address'),
      enrolmentYear: window.sessionStorage.getItem('enrolmentYear'),
      scholasticYear: window.sessionStorage.getItem('scholasticYear'),
      catchmentLevel: window.sessionStorage.getItem('catchmentLevel'),
      entries: []
    }
  },
  computed: {
    ...mapGetters([
      'school',
      'schoolData',
      'isOverCap',
      'isUnderCap',
      'isApproachingCap',
      'isNACap'
    ]),
    priorityCriteriasList() {
      return (
        this.schoolData?.criteria?.filter((c) => c.active && c.priority) || []
      )
    },
    criteriaWithActiveStatus() {
      // Get the filtered active criteria without priority
      const activeCriteria =
        this.schoolData?.criteria?.filter((c) => c.active && !c.priority) || []

      // Check if 'enableOtherCriteria' is true, then append 'Other considerations' as an object.
      if (this.schoolData?.enableOtherCriteria) {
        activeCriteria.push({ value: 'Other considerations' })
      }

      return activeCriteria
    },
    timeFrameCopy() {
      if (
        this.schoolData.currentYearTimeFrame.code !==
        this.schoolData.nextYearTimeFrame.code
      ) {
        return `${this.schoolData.currentYearTimeFrame.description} ${this.schoolData.nextYearTimeFrame.description}`
      }
      const copy = this.schoolData.currentYearTimeFrame.value
      // values are stored like 'In November this year' so lowerCase the first letter only
      return `We will assess your application ${
        copy.charAt(0).toLowerCase() + copy.slice(1)
      } and contact you.`
    }
  },
  created() {
    this.$store.commit('setIsResuming', false)

    const schoolCode = window.sessionStorage.getItem('schoolCode')

    if (!schoolCode) return

    this.getSchoolDetails(schoolCode)
  },
  methods: {
    async getSchoolDetails(schoolCode) {
      await this.$store.dispatch('isValidSchool', schoolCode).catch(() => {
        this.$router.push({ name: 'error' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-search {
  width: 100%;
}
.errorRed {
  color: $ads-error-red;
}
.criteria {
  img {
    position: absolute;
    left: 60px;
  }
  h3 {
    font-size: 1rem;
  }
}
.mobile .criteria {
  text-align: left;
  .criteriaTitle {
    display: flex;
    align-items: center;
  }
  img {
    position: relative;
    left: 0;
    float: left;
    margin-right: 8px;
  }
  .description {
    clear: both;
    padding-top: 20px;
  }
}
.catchmentFinder {
  padding: 12px 0;
}

::v-deep .ooaBanner .container--fluid {
  display: flex;
}

::v-deep .v-sheet.v-card {
  border-radius: 0;
}

.imgMoble {
  margin: 0.3rem;
}

.imgDesktop {
  margin: 1rem;
}

.mobleTitle {
  margin-top: 4px;
}

.tabletTitle {
  margin-top: 26px;
}

.tabletTitle h2 {
  font-size: 30px !important;
}

.tabletTitle p {
  font-size: 18px !important;
}

.desktopTtile {
  margin-top: 12px;
}

.criteriaPriorityList {
  list-style-type: decimal;
}
</style>
