<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0" aria-live="polite">
    <p class="pb-3">
      Please provide details of up to two parents/carers who we can contact
      regarding this application.
    </p>

    <v-alert
      v-if="ehubPrefill === 'parent' || ehubPrefill === 'student and parent'"
      border="left"
      colored-border
      color="blue"
      icon="mdi-alert-circle"
      class="alert--light-blue mb-8"
    >
      <p>
        We have pre-filled these details based on information you have already
        provided to NSW Department of Education.
      </p>
      <strong
        >Please ensure the pre-filled information below is accurate before
        continuing to the next section</strong
      >
    </v-alert>

    <v-form
      ref="form"
      v-model="validParentCarers"
      class="ooa-form"
      data-testid="parent-carer-form"
    >
      <div v-for="(parent, index) in parentCarers" :key="index">
        <v-card class="mb-6">
          <div class="pa-6">
            <v-row class="d-flex align-end justify-space-between mb-8">
              <v-col cols="2" md="1" order="last">
                <v-btn
                  v-if="index !== 0"
                  text
                  icon
                  class="blue-icon"
                  aria-label="remove second parent / carer"
                  @click="showDialog = true"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
              <!-- order=first so tab doesn't go onto close button -->
              <v-col cols="10" md="11" order="first">
                <h2 data-testid="parent-carer-name">
                  <span class="de-emphasise">
                    <h2
                      v-if="
                        !parent.parentCarerGivenName ||
                        !parent.parentCarerFamilyName
                      "
                      :id="`parentCarerHeading${index + 1}`"
                      class="py-1"
                      tabindex="-1"
                    >
                      {{ index ? '2nd ' : '' }}Parent/carer details
                    </h2>
                    <span v-else>Parent/carer:</span>
                  </span>
                  {{ name(parent) }}
                </h2>
              </v-col>
            </v-row>
            <div v-if="showDialog">
              <ooa-dialog
                :visible="showDialog"
                title="Remove parent/carer"
                max-width="520px"
                :open-dialog="showDialog"
                :display-close-btn="true"
                :actions="actionsList"
                @close="showDialog = false"
                @Cancel="showDialog = false"
                @Remove="removeParentCarer(index)"
              >
                <div slot="message">
                  Are you sure you want to remove
                  <span
                    v-if="
                      parent.parentCarerGivenName ||
                      parent.parentCarerFamilyName
                    "
                    >{{ parent.parentCarerGivenName }}
                    {{ parent.parentCarerFamilyName }}</span
                  >
                  <span v-else>this parent/carer</span>? This action cannot be
                  undone.
                </div>
              </ooa-dialog>
            </div>

            <SelectMenu
              v-model="parent.parentCarerRelation"
              data-initial-focus
              class="mb-6"
              :items="relationships"
              label="Relationship"
              hint="e.g. Mother, father"
              required
              data-testid="parentCarerRelation"
            />

            <SelectMenu
              v-model="parent.parentCarerTitle"
              class="mb-6"
              :items="titles"
              label="Title"
              required
              data-testid="parentCarerTitle"
            />
            <TextField
              v-model="parent.parentCarerGivenName"
              class="mb-6"
              label="Given name"
              placeholder="Enter given name"
              required
              :rules="givenNameRule"
              maxlength="100"
              data-testid="parentCarerGivenName"
              :disabled="
                isEhubPrefilledParentInfo(index) ||
                isEnrolmentOwnerNameChanged(index)
              "
              :show-label-on-disabled="true"
            />

            <TextField
              v-model="parent.parentCarerFamilyName"
              class="mb-6"
              label="Family name"
              placeholder="Enter family name"
              required
              :rules="familyNameRule"
              maxlength="100"
              data-testid="parentCarerFamilyName"
              :disabled="
                isEhubPrefilledParentInfo(index) ||
                isEnrolmentOwnerNameChanged(index)
              "
              :show-label-on-disabled="true"
            />

            <div
              class="ml-n6 mr-n6 pa-6 mb-6 address-section"
              :class="
                !parent.liveOnResidentialAddress || addressValid(index)
                  ? 'valid'
                  : 'invalid'
              "
            >
              <h2 class="mb-6">Residential Address</h2>
              <RadioGroup
                v-model="parent.liveOnResidentialAddress"
                class="mb-2"
                :items="[
                  { text: 'Yes', value: true },
                  { text: 'No', value: false }
                ]"
                :label="`Does ${
                  parent.parentCarerGivenName
                    ? parent.parentCarerGivenName
                    : 'parent/carer'
                } live at ${studentAddress}?`"
                :rules="[rules.mandatoryAddress, validParentAddressError]"
                data-testid="radioLivesWithStudent"
                @input="handleLiveOnResidentialAddressChange(index)"
              />
              <div
                v-if="parent.liveOnResidentialAddress"
                aria-live="polite"
                class="student-address mb-4 pa-4 grey lighten-4 rounded"
              >
                {{ studentAddress }}
              </div>
              <div
                v-if="
                  parent.liveOnResidentialAddress != null &&
                  !parent.liveOnResidentialAddress
                "
                aria-live="polite"
                class="address-section-extended"
              >
                <div class="mb-6">
                  Please provide
                  {{
                    parent.parentCarerGivenName
                      ? parent.parentCarerGivenName
                      : 'parent/carer'
                  }}'s residential address
                  <template v-if="!parent.liveOnResidentialAddress">
                    (Optional)
                  </template>
                </div>
                <SelectMenu
                  v-model="parent.residentialAddress.countryCode"
                  class="mb-6"
                  :items="countries"
                  label="Country"
                  placeholder="Select Country"
                  data-testid="carer-countryCode"
                  @input="setCountry($event, index)"
                />
                <template
                  v-if="parent.residentialAddress.countryCode === 'AUS'"
                >
                  <AddressLookup
                    :value="parent.residentialAddress.addressLine1"
                    class="mb-6"
                    label="Address Line 1"
                    placeholder="Address Line 1"
                    hint="e.g. 10 High Street"
                    :address-key="index"
                    @select="resolveAddress"
                    @blur="onAuAddressChange"
                  />
                  <v-row>
                    <v-col cols="12" md="4">
                      <TextField
                        v-model="parent.residentialAddress.suburbName"
                        class="mb-0"
                        label="Suburb"
                        placeholder="Suburb"
                        maxlength="100"
                        hint="e.g. Sydney"
                        :rules="[rules.emptyOrStrictAlpha]"
                        data-testid="carer-suburb"
                        @input="onAuAddressChange(index)"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <SelectMenu
                        v-model="parent.residentialAddress.stateCode"
                        class="mb-0"
                        :items="stateCodes"
                        label="State"
                        placeholder="Select State"
                        data-testid="carer-state"
                        @input="onAuAddressChange(index)"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <TextField
                        v-model="parent.residentialAddress.postCode"
                        class="mb-0"
                        label="PostCode"
                        placeholder="PostCode"
                        maxlength="4"
                        :rules="[rules.emptyOrPostcode]"
                        data-testid="carer-postcode"
                        @input="onAuAddressChange(index)"
                      />
                    </v-col>
                  </v-row>
                  <div
                    v-if="!addressValid(index)"
                    class="error-message theme--light error--text"
                    role="alert"
                    aria-live="assertive"
                  >
                    Please provide a valid street address, suburb, state and
                    postcode for this parent/carer.
                  </div>
                </template>
                <template
                  v-if="
                    parent.residentialAddress.countryCode &&
                    parent.residentialAddress.countryCode !== 'AUS'
                  "
                >
                  <TextField
                    v-model="parent.residentialAddress.addressLine1"
                    class="mb-6"
                    label="Address line 1"
                    placeholder="Address line 1: Start typing here"
                    :rules="[rules.mandatory]"
                    data-testid="addressLine1"
                  />
                  <TextField
                    v-model="parent.residentialAddress.addressLine2"
                    class="mb-6"
                    label="Address line 2"
                    placeholder="Address line 2"
                    maxlength="100"
                    data-testid="addressLine2"
                  />
                  <div
                    v-if="!addressValid(index)"
                    class="error-message theme--light error--text"
                    role="alert"
                  >
                    Please provide your address details
                  </div>
                </template>
              </div>
            </div>

            <h2 class="mb-6">
              {{ pluralisedFirstName(parent.parentCarerGivenName) }}
              {{ parent.parentCarerGivenName ? 'c' : 'C' }}ontact email
            </h2>

            <RadioGroup
              v-model="parent.parentCarerCompletingApp"
              class="mb-6"
              :items="[
                { text: 'Yes', value: true },
                { text: 'No', value: false }
              ]"
              :label="`Is ${
                parent.parentCarerGivenName
                  ? parent.parentCarerGivenName
                  : 'parent/carer'
              } completing this application for ${studentName()}?`"
              :rules="validParentCompletingRule[index]"
              data-testid="parentCarerCompletingApp"
              :disabled="isPrefilledFromErn(parent)"
              @input="validateParentCompleting(index)"
            />

            <TextField
              v-if="displayParentCarerEmail(index)"
              v-model="parent.parentCarerEmail"
              class="mb-6"
              :label="`Email address${
                parent.parentCarerCompletingApp ? '' : ' (Optional)'
              }`"
              placeholder="Enter email address"
              :rules="emailRule"
              :readonly="parent.parentCarerCompletingApp"
              :filled="parent.parentCarerCompletingApp"
              maxlength="132"
              hint="NOTE: This email address will be included in any email notifications about this application"
              data-testid="parentCarerEmail"
            />
            <div
              v-for="(contact, idx) in parent.contactDetails"
              :key="idx"
              class="ml-n6 mr-n6 mt-6 contact-border"
            >
              <div class="ml-6 mr-6">
                <v-row class="d-flex align-end justify-space-between mt-6 mb-6">
                  <v-col cols="2" md="1" order="last">
                    <v-btn
                      v-if="idx !== 0"
                      text
                      icon
                      class="blue-icon"
                      :aria-label="`remove parent / carer contact ${idx + 1}`"
                      @click="removeContactType(parent, idx)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                  <!-- order=first so tab doesn't go onto close button -->
                  <v-col cols="10" md="11" order="first">
                    <h2
                      :id="`contactTypeHeading-${index + 1}-${idx + 1}`"
                      class="py-1"
                      data-testid="contactDetails-heading"
                      tabindex="-1"
                    >
                      <span class="de-emphasise">
                        {{ pluralisedFirstName(parent.parentCarerGivenName) }}
                        contact number {{ idx + 1 }}:
                      </span>
                      {{ contactTypeShortName(contact) }}
                    </h2>
                    <span v-if="idx === 0" class="hint"
                      >Add at least one contact number
                      <span v-if="parent.parentCarerGivenName"
                        >for {{ parent.parentCarerGivenName }}
                      </span>
                    </span>
                  </v-col>
                </v-row>

                <SelectMenu
                  v-model="contact.contactType"
                  class="mb-6"
                  :items="contactTypes"
                  label="Phone number type"
                  placeholder="Enter type"
                  required
                  data-testid="contact-contactType"
                />

                <TextField
                  v-model="contact.contactValue"
                  class="mb-6"
                  label="Enter number"
                  placeholder="Enter number"
                  required
                  :rules="phoneNumberRule"
                  maxlength="20"
                  data-testid="contact-contactValue"
                  @handleOnBlur="
                    updateAndFormatContactValue(
                      contact.contactValue,
                      idx,
                      index
                    )
                  "
                />
                <TextArea
                  v-model="contact.comments"
                  label="Comments"
                  placeholder="Enter comments"
                  :rules="rules.maxLength"
                  maxlength="50"
                  hint="(Optional) e.g. best time to call"
                  data-testid="contact-comments"
                />
              </div>

              <v-btn
                v-if="parent.contactDetails.length < contactTypes.length"
                class="ml-md-6 no-outline"
                color="primary"
                outlined
                x-large
                data-testid="add-another-contact-btn"
                @click="addContactType(parent, index)"
              >
                <v-icon class="pr-2">add_circle_outline</v-icon>Add another
                contact number
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </v-form>

    <v-btn
      v-if="parentCarers.length === 1"
      color="primary"
      outlined
      x-large
      data-testid="add-another-parentCarer-btn"
      @click="addParentCarer"
    >
      <v-icon class="pr-2">add_circle_outline</v-icon>Add another parent/carer
    </v-btn>

    <v-row v-if="parentCarers.length > 1" data-testid="max-parentCarer-msg">
      <v-col>
        <span>
          <strong class="note">Note:</strong>
          <br />
          This form allows a maximum of 2 parent/carers only. Should your
          application be successful, additional parent/carers can be added
          during the full enrolment process.
        </span>
      </v-col>
    </v-row>
    <div class="d-flex justify-end mt-6">
      <v-btn
        class="button-next"
        color="primary"
        x-large
        data-testid="save-parentCarer-details-btn"
        @click="
          enableLoader()
          save()
        "
      >
        <ButtonSpinner :loading="loading" class="mr-2" />
        Save &amp; Continue
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import SelectMenu from '@/components/form/SelectMenu'
import TextArea from '@/components/form/TextArea'
import TextField from '@/components/form/TextField'
import ButtonSpinner from '@/components/ButtonSpinner'
import OoaDialog from '@/components/OoaDialog'
import RadioGroup from '@/components/form/RadioGroup'
import handleFirstFocus from '@/helpers/form/focus'
import AddressLookup from '@/components/AddressLookup'
import { ADDRESS_VALIDATION } from '@/constants'
import validators from '@/helpers/form/validators'

const emptyContact = {
  contactType: null,
  contactValue: null,
  comments: null
}

const emptyAddress = {
  addressLine1: null,
  addressLine2: null,
  countryCode: null,
  postCode: null,
  stateCode: null,
  suburbName: null,
  validationFlag: ADDRESS_VALIDATION.UNKNOWN
}

const emptyParentCarer = {
  parentCarerRelation: null,
  parentCarerTitle: null,
  parentCarerGivenName: null,
  parentCarerFamilyName: null,
  parentCarerEmail: null,
  liveOnResidentialAddress: null,
  residentialAddress: { ...emptyAddress },
  contactDetails: [
    {
      contactType: null,
      contactValue: null,
      comments: null
    }
  ]
}

export default {
  name: 'ParentCarers',
  components: {
    AddressLookup,
    SelectMenu,
    TextArea,
    TextField,
    ButtonSpinner,
    OoaDialog,
    RadioGroup
  },
  data() {
    return {
      loading: false,
      validParentCarers: true,
      validParentCompletingRule: [[true], [true]],
      validParentAddressError: true,
      parentCarers: [
        {
          parentCarerRelation: null,
          parentCarerTitle: null,
          parentCarerGivenName: null,
          parentCarerFamilyName: null,
          parentCarerCompletingApp: null,
          parentCarerEmail: this.$store.getters.email,
          liveOnResidentialAddress: null,
          residentialAddress: { ...emptyAddress },
          contactDetails: [
            {
              contactType: null,
              contactValue: null,
              comments: null
            }
          ]
        }
      ],
      // validation rules
      rules: {
        mandatory: (v) => !!v, // cannot be empty
        postcode: validators.postcode,
        emptyOrPostcode: validators.emptyOrPostcode,
        strictAlpha: validators.strictAlpha,
        emptyOrStrictAlpha: validators.emptyOrStrictAlpha,
        mandatoryAddress: (v) =>
          v !== null ||
          "Please provide parent/carer's residential address details",
        maxLength: [(v) => !v || v.length <= 50 || 'Max 50 characters']
      },
      // TODO: Find way of consolidating given & family name rule without causing error with wrong
      // text appearing next to the field.
      givenNameRule: [validators.validName],
      familyNameRule: [validators.validName],
      emailRule: [
        (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          'Please enter a valid email address.'
      ],
      phoneNumberRule: [
        validators.containsNonAsciiOrValidCharactersForPhoneNumber
      ],
      isSectionCommitted: false,
      showDialog: false,
      actionsList: [
        {
          name: 'Cancel',
          color: '#041E42',
          size: 'large',
          outlined: false,
          btnText: true,
          action: 'Cancel'
        },
        {
          name: 'Remove',
          color: '#CE0037',
          size: 'large',
          outlined: false,
          btnText: false,
          action: 'Remove'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'form',
      'currentStep',
      'countries',
      'contactTypes',
      'relationships',
      'titles',
      'states',
      'studentName',
      'viaEhub',
      'parentId',
      'ernEnrolmentOwner',
      'ehubPrefill',
      'ernEnrolmentOwnerNameNotMatched'
    ]),
    studentAddress() {
      return `${this.form.residentialAddress.addressLine1}, ${this.form.residentialAddress.suburbName}, ${this.form.residentialAddress.stateCode} ${this.form.residentialAddress.postCode}`
    },
    stateCodes() {
      return this.states.map((s) => ({ text: s.value, value: s.value }))
    },
    noOfCarersLiveWithStudent() {
      // check if either one of the carer is marked as liveOnResidentialAddress and have valid addresses
      return this.parentCarers.filter((carer) => carer.liveOnResidentialAddress)
        .length
    },
    parentCarerCompletingApp1() {
      return this.parentCarers[0].parentCarerCompletingApp
    },
    parentCarerCompletingApp2() {
      return this.parentCarers[1]?.parentCarerCompletingApp
    }
  },
  watch: {
    parentCarerCompletingApp1: {
      handler(newVal, oldVal) {
        if (newVal === false && oldVal === true) {
          // Yes -> No
          this.parentCarers[0].parentCarerEmail = null
        }
      }
    },
    parentCarerCompletingApp2: {
      handler(newVal, oldVal) {
        if (newVal === false && oldVal === true) {
          // Yes -> No
          this.parentCarers[1].parentCarerEmail = null
        }
      }
    }
  },
  mounted() {
    if (this.form.parentCarers && !this.viaEhub) {
      this.validate()
    }
    this.$nextTick(() => {
      handleFirstFocus(this.$refs.form.inputs)
    })
  },
  created() {
    if (this.form.parentCarers) {
      this.parentCarers = this.form.parentCarers.map((parent) => {
        const pc = parent
        if (!pc.residentialAddress) {
          pc.residentialAddress = emptyAddress
        }
        return pc
      })
    }
  },
  beforeDestroy() {
    this.commitSection()
  },
  methods: {
    updateAndFormatContactValue(val, contactIdex, parentIndex) {
      if (val) {
        const newVal = this.trimSpacesAndRemoveNonAsciiCharacters(val)
        this.parentCarers[parentIndex].contactDetails[
          contactIdex
        ].contactValue = newVal // required so that the validation rules will run
        // Only format numbers
        if (/^[0-9]+$/.test(newVal)) {
          this.formattedContactValue(newVal, contactIdex, parentIndex)
        }
      }
    },
    trimSpacesAndRemoveNonAsciiCharacters(val) {
      // eslint-disable-next-line no-control-regex
      return val ? val.trim().replace(/[^\x00-\x7F]/g, '') : val
    },
    formattedContactValue(val, contactIdex, parentIndex) {
      if (val) {
        // landline number, eg: 13 00 00
        if (val.replace(/\s/g, '').length === 6) {
          this.parentCarers[parentIndex].contactDetails[
            contactIdex
          ].contactValue = val
            .replace(/\W/gi, '')
            .replace(/(.{2})(.{2})/g, '$1 $2 ')
        }

        // landline number, eg: 1234 4321
        if (val.replace(/\s/g, '').length === 8) {
          this.parentCarers[parentIndex].contactDetails[
            contactIdex
          ].contactValue = val
            .replace(/\W/gi, '')
            .replace(/(.{4})/g, '$1 ')
            .trim()
        }

        if (val.replace(/\s/g, '').length === 10) {
          // for mobile and Australia-wide landline number. eg: 0400 000 000 || 1300 975 707
          if (
            val.replace(/\W/gi, '').substring(0, 2) === '04' ||
            val.replace(/\W/gi, '').substring(0, 2) === '05' ||
            val.replace(/\W/gi, '').substring(0, 2) === '13' ||
            val.replace(/\W/gi, '').substring(0, 2) === '18'
          ) {
            this.parentCarers[parentIndex].contactDetails[
              contactIdex
            ].contactValue = val
              .replace(/\W/gi, '')
              .replace(/(.{4})(.{3})/g, '$1 $2 ')
          }
          // for landline area code, eg: 02 1234 4321
          if (
            val.replace(/\W/gi, '').substring(0, 2) === '02' ||
            val.replace(/\W/gi, '').substring(0, 2) === '03' ||
            val.replace(/\W/gi, '').substring(0, 2) === '07' ||
            val.replace(/\W/gi, '').substring(0, 2) === '08'
          ) {
            this.parentCarers[parentIndex].contactDetails[
              contactIdex
            ].contactValue = val
              .replace(/\W/gi, '')
              .replace(/(.{2})(.{4})/g, '$1 $2 ')
          }
        }
      }
    },
    enableLoader() {
      this.loading = true
    },
    disableLoader() {
      this.loading = false
    },
    validate() {
      let validParentCompleting = true
      for (let i = 0; i < this.parentCarers.length; i += 1) {
        this.validateParentCompleting(i)
      }
      if (
        this.validParentCompletingRule[0][0] !== true ||
        this.validParentCompletingRule[1][0] !== true
      ) {
        validParentCompleting = false
      }
      this.validateLiveOnResidentialAddress()
      this.validParentCarers = this.$refs.form.validate()
      if (this.validParentCarers) {
        // at lease one parent/carer needs to reside with the student
        this.validParentCarers =
          validParentCompleting && this.noOfCarersLiveWithStudent > 0
      }
      this.$store.dispatch('setIsValidParentCarers', {
        isValid: this.validParentCarers
      })
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    name(parent) {
      const firstName = parent.parentCarerGivenName
        ? parent.parentCarerGivenName
        : ''
      const lastName = parent.parentCarerFamilyName
        ? parent.parentCarerFamilyName
        : ''

      if (firstName !== '' && lastName !== '') {
        return `${firstName} ${lastName}`
      }
      return ''
    },
    pluralisedFirstName(firstName) {
      if (firstName) {
        return firstName.trim().slice(-1) === 's'
          ? `${firstName}'`
          : `${firstName}'s`
      }
      return ''
    },
    contactTypeShortName(contact) {
      const selectedType = this.contactTypes.filter(
        (item) => item.value === contact.contactType
      )
      if (selectedType && selectedType.length > 0) {
        if (selectedType[0].value === 'PHMOB') {
          return 'Mobile'
        }
        if (selectedType[0].value === 'PHHOM') {
          return 'Home'
        }
        if (selectedType[0].value === 'PHWRK') {
          return 'Business/Work'
        }
      }
      return ''
    },
    resolveAddress(addressData, parentIndex) {
      Vue.set(this.parentCarers[parentIndex], 'residentialAddress', addressData)
    },
    setCountry(value, parentIndex) {
      // resets the parent address when we change the country
      Vue.set(this.parentCarers[parentIndex], 'residentialAddress', {
        ...emptyAddress,
        countryCode: value,
        validationFlag: ADDRESS_VALIDATION.UNKNOWN
      })
    },
    addContactType(parent, parentIndex) {
      parent.contactDetails.push(JSON.parse(JSON.stringify(emptyContact)))
      const nextContactIndex = parent.contactDetails.length
      this.$nextTick(() => {
        document
          .getElementById(
            `contactTypeHeading-${parentIndex + 1}-${nextContactIndex}`
          )
          .focus()
      })
    },
    removeContactType(parent, index) {
      parent.contactDetails.splice(index, 1)
    },
    addParentCarer() {
      const emptyPC = JSON.parse(JSON.stringify(emptyParentCarer))
      if (
        this.ehubPrefill === 'parent' ||
        this.ernEnrolmentOwnerNameNotMatched === false ||
        this.ehubPrefill === 'student and parent'
      ) {
        emptyPC.parentCarerCompletingApp = false
      }
      if (
        !!this.parentId &&
        (this.ehubPrefill === 'parent' ||
          this.ehubPrefill === 'student and parent')
      ) {
        emptyPC.isEnrolmentOwner = true
      }
      this.parentCarers.push(emptyPC)
      this.$nextTick(() => {
        document.getElementById('parentCarerHeading2').focus()
      })
    },
    removeParentCarer(index) {
      this.showDialog = false
      this.parentCarers.splice(index, 1)
    },
    commitSection() {
      if (!this.isSectionCommitted) {
        if (this.noOfCarersLiveWithStudent > 0) {
          this.adjustCarerAddresses()
        }
        this.$store.commit('setParentCarers', this.parentCarers)
        this.isSectionCommitted = true
        this.validate()
      }
    },
    adjustCarerAddresses() {
      this.parentCarers = this.parentCarers.map((carer) => {
        const adjusted = { ...carer }
        // copy the student address to parent address if carer lives with the student before updating store
        if (carer.liveOnResidentialAddress) {
          adjusted.residentialAddress = this.form.residentialAddress
        }
        return adjusted
      })
    },
    onAuAddressChange(parentIndex) {
      // Flag the parent/carer address but we let it go through
      Vue.set(
        this.parentCarers[parentIndex].residentialAddress,
        'validationFlag',
        ADDRESS_VALIDATION.UNKNOWN
      )
    },
    validateAddress(address) {
      // validate address fields against regex
      // AU addresses require fully structured address
      if (address.countryCode === 'AUS') {
        return (
          address.addressLine1 !== '' &&
          this.rules.strictAlpha(address.suburbName) === true &&
          this.rules.postcode(address.postCode) &&
          address.stateCode !== ''
        )
      }
      if (address.countryCode !== '') {
        // just check the line1 one as mandatory ? // TO BE CONFIRMED
        return address.addressLine1 !== ''
      }
      return false
    },
    addressValid(parentIndex) {
      const { residentialAddress, liveOnResidentialAddress } =
        this.parentCarers[parentIndex]
      if (
        !liveOnResidentialAddress ||
        this.validateAddress(residentialAddress) ||
        liveOnResidentialAddress
      ) {
        return true
      }
      return false
    },
    handleLiveOnResidentialAddressChange(parentIndex) {
      const { liveOnResidentialAddress } = this.parentCarers[parentIndex]

      // set up address to be empty to start with
      // note: if liveOnResidentialAddress = true, the parent address is set to the application address on commitSection
      if (!liveOnResidentialAddress) {
        // explicitly setting the values as the object is deeply nested
        // if this is set in the normal way the changes to the nested property is not immediately reflecting
        Vue.set(this.parentCarers[parentIndex], 'residentialAddress', {
          ...emptyAddress
        })
      }
      this.validateLiveOnResidentialAddress()
    },
    validateLiveOnResidentialAddress() {
      this.validParentAddressError =
        this.parentCarers.some((p) => p.liveOnResidentialAddress) ||
        "Please ensure at least one parent/carer lives at the student's residential address"
    },
    validateParentCompleting(index) {
      const { parentCarerCompletingApp } = this.parentCarers[index]
      const altIndex = index === 0 ? 1 : 0
      const validationMessage =
        'Please ensure at least one parent/carer is the one completing this application'
      if (parentCarerCompletingApp === false) {
        if (this.parentCarers.length > 1) {
          if (!this.parentCarers[altIndex].parentCarerCompletingApp) {
            this.validParentCompletingRule[index] = [validationMessage]
            this.validParentCompletingRule[altIndex] = [validationMessage]
          } else {
            this.validParentCompletingRule[altIndex] = [true]
          }
        } else {
          this.validParentCompletingRule[index] = [validationMessage]
        }
      } else if (parentCarerCompletingApp) {
        this.parentCarers[index].parentCarerEmail = this.$store.getters.email
        this.validParentCompletingRule[index] = [true]
        if (this.parentCarers.length === 1) {
          this.validParentCompletingRule[altIndex] = [true] // Bug fix for FUS-742
        }
        if (this.parentCarers.length > 1) {
          this.parentCarers[altIndex].parentCarerCompletingApp = false
          this.validParentCompletingRule[altIndex] = [true]
        }
      } else {
        this.validParentCompletingRule[index] = [validationMessage]
      }
    },
    displayParentCarerEmail(index) {
      const pcCompleting = this.parentCarers[index].parentCarerCompletingApp
      if (pcCompleting === undefined || pcCompleting === null) {
        return false
      }
      return true
    },
    async save() {
      try {
        this.validate()
        this.commitSection()
        // persist student details to data store
        await this.$store.dispatch('updateApplication')
        await this.$store.dispatch('setCurrentStep', {
          currentStep: this.currentStep + 1
        })
        await this.$router.push('/form/considerations')
      } finally {
        this.disableLoader()
      }
    },
    isEhubPrefilledParentInfo(index) {
      return (
        (this.ehubPrefill === 'parent' ||
          this.ehubPrefill === 'student and parent') &&
        this.parentCarers[index].parentCarerCompletingApp
      )
    },
    isEnrolmentOwnerNameChanged(index) {
      return (
        this.ernEnrolmentOwner &&
        this.parentCarers[index].parentCarerGivenName ===
          this.ernEnrolmentOwner.firstName &&
        this.parentCarers[index].parentCarerFamilyName ===
          this.ernEnrolmentOwner.lastName
      )
    },
    isPrefilledFromErn(parent) {
      let isPrefilledFromErn = false
      if (parent) {
        const isStampedParent = !!this.parentId
        const isEnrolmentOwner = parent.isEnrolmentOwner
        const isPrefilled =
          this.ehubPrefill === 'parent' ||
          this.ehubPrefill === 'student and parent'
        if (isPrefilled && isStampedParent && isEnrolmentOwner) {
          isPrefilledFromErn = true
        }
      }
      return isPrefilledFromErn
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  color: $ads-navy;
  font-size: 1.375rem;
  line-height: 1.2;
}
.de-emphasise {
  font-weight: normal;
}
.blue-icon {
  color: $ads-blue-1 !important;
}
.no-outline {
  border: none !important;
}
.note {
  color: $color-text-body;
}
.hint {
  font-size: 0.75rem;
  color: $ads-dark;
}
.address-section {
  border-top: 1px solid $ads-light-20;
  border-bottom: 1px solid $ads-light-20;
}
.address-section.invalid {
  border-left: 1px solid $ads-error-red;
}
.contact-border {
  border-top: 1px solid $ads-light-20 !important;
}
.address-auto-complete {
  position: relative;
}
.address-suggestion-list {
  position: absolute; // Necessary for all the calculations to work in scrollSelectionIntoView()
  top: 50px;
  font-size: 1rem;
  max-height: 260px;
  z-index: 99;
  overflow-y: auto;
  width: 100%;
  padding: 0.1em 0;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  background-color: white;
  color: black;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

  .type-ahead-option {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 2em 0.5em 1em;
    cursor: pointer;

    &:hover {
      display: flex !important; // that allow to make dropdown clickable on IE10=>UP
      border-color: $ads-blue-2-dark;
      outline: none;
    }
  }
  .selected {
    // keydown (arrows keys action) style
    background-color: white;
    border-color: grey;
    color: black;
  }
}
.ooa-form .address-section-extended .v-text-field.v-input {
  max-width: 500px;
}
</style>
