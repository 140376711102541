<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0" data-testid="sibling-details">
    <div class="ooa-form" ref="siblingsForm">
      <!-- creating a generic dialog driven from the selected sibling -->
      <div v-if="showDialog">
        <ooa-dialog
          :visible="showDialog"
          title="Remove sibling"
          max-width="520px"
          :open-dialog="showDialog"
          :display-close-btn="true"
          :actions="actionsList"
          data-testid="remove-sibling-dialog"
          @close="showDialog = false"
          @Cancel="showDialog = false"
          @Remove="removeSibling(activeSibling)"
        >
          <div slot="message">
            Are you sure you want to remove
            <span
              v-if="
                siblings[activeSibling].siblingsFirstName ||
                siblings[activeSibling].siblingsFamilyName
              "
              >{{ siblings[activeSibling].siblingsFirstName }}
              {{ siblings[activeSibling].siblingsFamilyName }}</span
            ><span v-else>this sibling</span>? This action cannot be undone.
          </div>
        </ooa-dialog>
      </div>
      <div>
        <p class="mt-4 mb-8 provideDetails">
          Please provide details of all {{ pluralisedStudentName() }} siblings
          currently enrolled at this school:
        </p>
        <div
          v-for="(sibling, index) in siblings"
          :key="index"
          class="mb-6"
          data-testid="siblingContainer"
        >
          <div class="d-flex align-start justify-space-between">
            <h2 class="mb-6">
              <span class="noWeight">Sibling: </span
              ><span
                v-if="sibling.siblingsFirstName && sibling.siblingsFamilyName"
                >{{
                  sibling.siblingsFirstName + ' ' + sibling.siblingsFamilyName
                }}</span
              >
            </h2>
            <v-btn
              v-if="index !== 0"
              text
              icon
              color="grey"
              data-testid="remove-sibling-btn"
              @click="confirmRemoveSibling(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <TextField
            v-model="sibling.siblingsFirstName"
            class="mb-6"
            label="Given name"
            placeholder="Enter given name"
            hint="As shown on their birth certificate."
            :rules="splTextNameRule"
            required
            maxlength="100"
            data-testid="siblingsFirstName"
            :autofocus="autofocus"
          />

          <TextField
            v-model="sibling.siblingsOtherName"
            class="mb-6"
            label="Middle name"
            placeholder="Enter middle name"
            hint="(optional)"
            :rules="splTextMiddleNameRule"
            maxlength="100"
            data-testid="siblingsOtherName"
          />

          <TextField
            v-model="sibling.siblingsFamilyName"
            label="Family name"
            placeholder="Enter family name"
            :rules="splTextFamilyNameRule"
            required
            maxlength="100"
            data-testid="siblingsFamilyName"
          />

          <RadioGroup
            v-model="sibling.siblingsGenderCode"
            class="mb-4 mt-0"
            :items="[
              { text: 'Male', value: 'M' },
              { text: 'Female', value: 'F' }
            ]"
            label="Gender"
            required
            data-testid="siblingsGenderCode"
          />

          <DatePicker
            v-model="sibling.siblingsDOB"
            label="Date of birth"
            class="mb-6"
            :date-picker-attrs="{
              min: minBirthDate,
              max: pastDates
            }"
            year-picker
            date-format="DD-MM-YYYY"
            output-date-format="DD-MM-YYYY"
            required
            :rules="sibling.validBirthDate"
            data-testid="siblingsDOB"
            @input="validateBirthDate(index, $event)"
            persistent-placeholder
          />
        </div>
      </div>
    </div>
    <v-btn
      v-if="siblings.length < maxNoOfSiblings"
      class="mb-6"
      color="primary"
      outlined
      x-large
      data-testid="add-sibling"
      @click="addSibling"
    >
      <v-icon class="pr-2">add_circle_outline</v-icon> Add another sibling
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import RadioGroup from '@/components/form/RadioGroup'
import TextField from '@/components/form/TextField'
import { DatePicker } from '@nswdoe/doe-ui-core'
import OoaDialog from '@/components/OoaDialog'
import moment from 'moment'
import validators from '@/helpers/form/validators'

const emptySibling = {
  siblingsFirstName: null,
  siblingsOtherName: null,
  siblingsFamilyName: null,
  siblingsGenderCode: null,
  siblingsDOB: null,
  validBirthDate: [true]
}

export default {
  name: 'SiblingsComponent',
  components: {
    RadioGroup,
    TextField,
    DatePicker,
    OoaDialog
  },
  data() {
    return {
      maxNoOfSiblings: 10,
      loading: false,
      activeSibling: null,
      splTextNameRule: [validators.validName],
      splTextMiddleNameRule: [validators.validName],
      splTextFamilyNameRule: [validators.validName],
      pastDates: null,
      minBirthDate: new Date('1990-01-01').toISOString().slice(0, 10),
      siblings: [{ ...emptySibling }],
      isSectionCommitted: false,
      showDialog: false,
      actionsList: [
        {
          name: 'Cancel',
          color: '#041E42',
          size: 'large',
          outlined: false,
          btnText: true,
          action: 'Cancel'
        },
        {
          name: 'Remove',
          color: '#CE0037',
          size: 'large',
          outlined: false,
          btnText: false,
          action: 'Remove'
        }
      ],
      autofocus: false
    }
  },
  computed: {
    ...mapGetters([
      'form',
      'currentStep',
      'studentName',
      'pluralisedStudentName'
    ]),
    siblingName() {
      if (this.siblings.siblingsFirstName && this.siblings.siblingsFamilyName) {
        return `${this.siblings.siblingsFirstName} ${this.siblings.siblingsFamilyName}`
      }
      return ''
    }
  },
  created() {
    if (this.form.siblings) {
      this.siblings = this.form.siblings
    }
    let date = new Date().toISOString().slice(0, 10)
    date = new Date(date)
    date.setDate(date.getDate() - 7)
    this.pastDates = date.toISOString().slice(0, 10)
  },
  beforeDestroy() {
    this.commitSection()
  },
  methods: {
    enableLoader() {
      this.loading = true
    },
    disableLoader() {
      this.loading = false
    },
    confirmRemoveSibling(index) {
      this.showDialog = true
      this.activeSibling = index
    },
    commitSection() {
      if (!this.isSectionCommitted) {
        // retain the sibling data even if user has selected No Siblings until the last application submit
        this.$store.commit('setSiblings', this.siblings)
        this.isSectionCommitted = true
      }
    },
    async save() {
      try {
        this.commitSection()
        await this.$store.dispatch('updateApplication')
      } finally {
        this.disableLoader()
      }
    },
    removeSibling(index) {
      this.showDialog = false
      this.siblings.splice(index, 1)
    },
    addSibling() {
      this.siblings.push(JSON.parse(JSON.stringify(emptySibling)))
      this.autofocus = true
    },
    validateBirthDate(index, date) {
      // "YYYY-MM-DD"
      const regPattern = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
      // "DD-MM-YYYY"
      // const dateRegPattern = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/
      const selectedDate = moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(
        'YYYY-MM-DD'
      )
      this.siblings[index].siblingsDOB = date
      if (date === null || date === '') {
        this.siblings[index].validBirthDate = ['Date of birth is required']
      } else if (regPattern.test(selectedDate)) {
        const pastDates = moment(this.pastDates)
        const minBirthDate = moment(
          new Date('1990-01-01').toISOString().slice(0, 10)
        )
        const enteredDate = moment(selectedDate, 'YYYY-MM-DD')
        if (enteredDate.diff(pastDates) > 0) {
          this.siblings[index].validBirthDate = [
            'Please ensure this date is at least one week prior to today’s date'
          ]
        } else if (enteredDate.diff(minBirthDate) < 0) {
          this.siblings[index].validBirthDate = [
            'Please ensure the date is no earlier than 1990 and no later than one week ago.'
          ]
        } else {
          this.siblings[index].validBirthDate = [true]
        }
      } else {
        this.siblings[index].validBirthDate = ['Invalid date']
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  color: $ads-navy;
  font-size: 1.375rem;
  line-height: 1.2;
  overflow: auto;
}
.noWeight {
  font-weight: initial;
}
.provideDetails {
  overflow-wrap: break-word;
}
</style>
