<template>
  <v-combobox
    ref="autocomplete"
    :value="value"
    :label="label"
    :placeholder="placeholder"
    :items="optionsList"
    :search-input.sync="searchInput"
    :rules="computedRules"
    return-object
    hide-no-data
    clearable
    outlined
    validate-on-blur
    @input="onTypeAheadSelect"
    persistent-placeholder
  />
</template>
<script>
export default {
  name: 'SchoolLookUp',
  props: {
    selectedState: {
      type: String
    },
    label: {
      type: String,
      default: 'School name'
    },
    placeholder: {
      type: String,
      default: 'Enter school name'
    },
    value: {
      type: Object
    },
    required: {
      type: Boolean
    }
  },
  data() {
    return {
      optionsList: [],
      tempSchoolList: [],
      searchInput: '',
      selectedSchool: null,
      invalidSchool: false
    }
  },
  computed: {
    computedRules() {
      const myRules = []
      if (
        this.required &&
        (this.searchInput === '' || this.searchInput === null)
      ) {
        myRules.push((v) => !!v || `${this.label} is required`)
      }

      if (this.invalidSchool) {
        myRules.push(
          'We cannot verify this school. Please clear the field and search for the school again.'
        )
      }
      return myRules
    }
  },
  watch: {
    searchInput(val) {
      if (!val) {
        this.optionsList = []
        this.invalidSchool = false
        this.selectedSchool = null
        return
      }
      if (val && val.length < 3) {
        this.optionsList = []
      } else {
        this.onTypeAheadSearch(val)
      }
    }
  },
  mounted() {
    if (this.value) {
      this.selectedSchool = this.value
    }
  },
  created() {
    if (this.$store.getters.predictiveSchools === null) {
      this.$store.dispatch('getPredictiveSchools').then(() => {
        this.tempSchoolList = this.$store.getters.predictiveSchools
      })
    } else {
      this.tempSchoolList = this.$store.getters.predictiveSchools
    }
  },
  methods: {
    onTypeAheadSelect(val) {
      if (this.tempSchoolList.length > 0) {
        if (val) {
          const isValid = this.optionsList.filter(
            (item) => item.value === val.value
          )
          if (isValid.length) {
            this.selectedSchool = val
            this.$emit('input', this.selectedSchool)
            this.invalidSchool = false
          } else {
            this.invalidSchool = true
            this.selectedSchool = null
            this.$emit('input', null)
          }
        } else {
          this.selectedSchool = null
          this.$emit('input', null)
        }
      }
    },
    onTypeAheadSearch(val) {
      if (this.tempSchoolList.length > 0) {
        const filteredArr = this.tempSchoolList
          .filter((item) => item.stateCode === this.selectedState)
          .filter((item) =>
            item.orgUnitName.toLowerCase().startsWith(val.toLowerCase())
          )
        this.optionsList = this.mapSchools(filteredArr)
      }
    },
    mapSchools(schoolList) {
      const optionsList = schoolList.map((item) => {
        if (item.suburb !== 'null') {
          const suburb = item.suburb.trim()
          return {
            text: `${item.orgUnitName} (${suburb})`,
            value: item.orgUnitCode
          }
        }
        return {
          text: item.orgUnitName,
          value: item.orgUnitCode
        }
      })
      return optionsList
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__icon.v-input__icon--append {
  display: none;
}
</style>
