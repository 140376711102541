<template>
  <AdsExpansionPanel v-model="expandedPanels" :items="items">
    <template slot="content1">
      <div class="pa-7">
        <div class="d-flex pb-3">
          <v-icon class="pr-4">mdi-map-marker-outline</v-icon>
          <a
            data-testid="school-expander-panel--address"
            :href="addressHref"
            target="_blank"
            class="schoolAddress localSchool"
            >{{ address }}</a
          >
        </div>
        <div class="d-flex pb-3">
          <v-icon class="pr-4">mdi-phone-outline</v-icon>
          <div data-testid="school-expander-panel--phone">
            {{ phoneWithNswAreaCode }}
          </div>
        </div>
        <div class="d-flex">
          <v-icon class="pr-4">mdi-web</v-icon>
          <div>
            <a
              data-testid="school-expander-panel--website"
              :href="websiteHref"
              target="_blank"
              class="schoolWebsite localSchool"
              >{{ websiteDisplay }}</a
            >
          </div>
        </div>
        <v-btn
          v-if="showApply"
          x-large
          color="primary"
          class="mt-8"
          @click="$emit('click', $event)"
        >
          Apply
        </v-btn>
      </div>
    </template>
  </AdsExpansionPanel>
</template>

<script>
import { AdsExpansionPanel } from '@nswdoe/doe-ui-core'

export default {
  name: 'SchoolExpanderPanel',
  components: {
    AdsExpansionPanel
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    lat: {
      type: String,
      default: ''
    },
    long: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
    website: {
      type: String,
      default: ''
    },
    showApply: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expandedPanels: [0]
    }
  },
  computed: {
    items() {
      return [{ icon: 'mdi-school-outline', title: `<b>${this.name}</b>` }]
    },
    websiteHref() {
      const url = this.website
      return url.indexOf('://') === -1 ? `https://${url}` : url
    },
    websiteDisplay() {
      return this.website.replace(/^https?:\/\//, '')
    },
    addressHref() {
      if (this.lat && this.long) {
        return `https://www.google.com.au/maps?q=${this.lat},${this.long}`
      }

      return `https://www.google.com.au/maps?q=${this.address}`
    },
    phoneWithNswAreaCode() {
      if (/^\(?02.*$/.test(this.phone)) {
        return this.phone
      }

      return `${this.phone}`
    }
  }
}
</script>
