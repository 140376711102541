<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0" data-testid="considerations-section">
    <div>
      <div v-for="(item, index) in form.criteria" :key="index" class="mb-7">
        <div class="criteriaName mb-5">
          {{ criteriaValue(item.code) }}
        </div>
        <SiblingsView v-if="item.code === 'SIB'" />
        <v-row>
          <v-col>
            <div class="fieldLabel">Details</div>
            <div class="fieldValue">
              {{ item.description ? item.description : '(empty)' }}
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="
            supportingDocuments &&
            supportingDocuments[item.code] &&
            supportingDocuments[item.code].length > 0
          "
        >
          <v-col>
            <div class="fieldLabel">Medical supporting documents</div>
            <div class="fieldValue pt-2">
              <div
                v-for="file in supportingDocuments[item.code]"
                :key="file.objectKey"
                class="mt-1"
              >
                <v-icon
                  dark
                  color="primary"
                  outlined
                  class="material-icons-outlined"
                >
                  insert_drive_file
                </v-icon>
                {{ file.name }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="form.otherConsiderations && form.otherConsiderations.length > 0"
      >
        <div class="criteriaName mb-2">Other considerations</div>
        <v-row>
          <v-col>
            <div class="fieldLabel">Details</div>
            <div class="fieldValue">
              {{
                form.otherConsiderations ? form.otherConsiderations : '(empty)'
              }}
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import SiblingsView from '@/views/form/Review/SiblingsView'

export default {
  name: 'ConsiderationsView',
  components: {
    SiblingsView
  },
  computed: {
    ...mapGetters(['form']),
    criterias() {
      return this.$store.getters.schoolData.criteria
    },
    supportingDocuments() {
      return this.form.supportingDocuments
    }
  },
  methods: {
    criteriaValue(code) {
      const criteria = this.criterias.filter((item) => item.code === code)
      if (criteria.length > 0) {
        return criteria[0].value
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.criteriaName {
  border-bottom: 1px solid $ads-light-20;
  font-size: 16px;
  font-weight: bold;
  color: $color-text-body;
}
.fieldLabel {
  font-size: 14px;
  color: $ads-dark-70;
}
.fieldValue {
  font-size: 14px;
  color: $color-text-body;
  overflow-wrap: break-word;
}
</style>
