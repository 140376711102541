<template>
  <EhubWelcomeOoa />
</template>

<script>
import EhubWelcomeOoa from '@/views/ehub/EhubWelcomeOoa.vue'

export default {
  name: 'EhubLanding',
  components: {
    EhubWelcomeOoa
  }
}
</script>
