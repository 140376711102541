import Vue from 'vue'
import store from '@/store'
import jwtDecode from 'jwt-decode'
import { ERROR_CODES } from '@/constants'
import { getGtmApplication } from '@/helpers'

export default function home(to, from, next) {
  let { schoolCode } = to.query
  const { address, enrolmentYear, scholasticYear, catchmentLevel } = to.query

  // Conduct some very basic input checks up front so we can reject if necessary
  // as soon as possible.

  // reset the school data in session storage before running the guard
  window.sessionStorage.removeItem('schoolCode')

  // reset logout flag
  store.commit('setIsLoggedOut', false)
  // set idToken and refreshToken from Service NSW login
  // this has been added to guard to prevent return Service NSW user from
  // seeing landing page after authentication
  const idToken = window.localStorage.getItem('id_token')
  const refreshToken = window.localStorage.getItem('refresh_token')
  const school = JSON.parse(window.sessionStorage.getItem('school'))
  const localResidentialAddress = JSON.parse(
    window.sessionStorage.getItem('localResidentialAddress')
  )
  const isResuming = window.sessionStorage.getItem('isResuming')

  if (idToken && refreshToken) {
    store.commit('setIdToken', idToken)
    store.commit('setRefreshToken', refreshToken)
    store.commit('setSchool', school)
    store.commit('setIsUserActive', true)
    store.commit('setLocalResidentialAddress', localResidentialAddress)
    store.commit('setIsResuming', isResuming === 'true')
    store.commit(
      'setCalendarYear',
      parseInt(window.sessionStorage.getItem('calendarYear'), 10)
    )

    const userId = jwtDecode(idToken).email
    store.commit('setEmail', userId)
    schoolCode = store.getters.school ? store.getters.school.schoolCode : null

    window.sessionStorage.removeItem('school')
    window.sessionStorage.removeItem('localResidentialAddress')
    window.sessionStorage.removeItem('isResuming')
    window.sessionStorage.removeItem('calendarYear')
    window.localStorage.removeItem('id_token')
    window.localStorage.removeItem('refresh_token')

    store
      .dispatch('fetchApplication')
      .then(() => {
        if (store.getters.isPendingApplication) {
          // There is a pending application for the authenticated user. Set the appropriate
          // school if there's a mismatch between URL schoolCode, & form schoolCode
          if (
            store.getters.school === null ||
            store.getters.school.schoolCode !== store.getters.form.schoolCode
          ) {
            const { schoolCode } = { ...store.getters.form }
            store.dispatch('isValidSchool', schoolCode).then(() => {
              window.sessionStorage.setItem('schoolCode', schoolCode)
            })
          }
          next({ name: 'form', params: { viaServiceNSW: true } })
        } else if (store.getters.isResuming) {
          // No Pending Application, yet user is resuming. Raise appropriate error
          const errorType =
            store.getters.school && store.getters.school.schoolCode !== null
              ? ERROR_CODES.NO_PENDING
              : ERROR_CODES.NO_SCHOOL_CODE
          store.dispatch('setErrorType', { errorType })
          next('/error')
        } else {
          // No Pending Application & User is creating a new application
          store
            .dispatch('createNewApplication')
            .then(() => {
              // Google Analytics
              this.$gtm.trackEvent({
                event: 'interaction',
                category: 'Form Attempt',
                action: 'start',
                label: 'ooa',
                application: getGtmApplication(false)
              })
              next({ name: 'form', params: { viaServiceNSW: true } })
            })
            .catch(() => {
              next('/error')
            })
        }
      })
      .catch(() => {
        next('/error')
      })
    return
  }

  // school code query string must be present to begin an application
  if (!schoolCode) {
    Vue.$log.error('Not set: schoolCode')
    store.commit('setError', new Error('schoolCode must be set'))
    next('/error')
    return
  }

  // `schoolCode` must be comprised of digits with a length between one and four
  if (!/^\d{1,4}$/.test(schoolCode)) {
    Vue.$log.error(`Incorrect schoolCode: ${schoolCode}`)
    store.commit('setError', new Error(`Incorrect schoolCode (${schoolCode})`))
    next('/error')
    return
  }

  window.sessionStorage.setItem('schoolCode', schoolCode)

  // `address` is essentially a free text field and could contain any number of
  // or type of characters, and could potentially be URL encoded. The actual
  // physical address is validated against a Geo DB in subsequent parts of the
  // app, so here we're only concerned with its presence (or lack of).
  if (address) {
    window.sessionStorage.setItem('address', window.decodeURIComponent(address))
  } else {
    window.sessionStorage.removeItem('address')
  }

  if (enrolmentYear) {
    window.sessionStorage.setItem(
      'enrolmentYear',
      window.decodeURIComponent(enrolmentYear)
    )
  } else {
    window.sessionStorage.removeItem('enrolmentYear')
  }

  if (scholasticYear) {
    window.sessionStorage.setItem(
      'scholasticYear',
      window.decodeURIComponent(scholasticYear)
    )
  } else {
    window.sessionStorage.removeItem('scholasticYear')
  }

  if (catchmentLevel) {
    window.sessionStorage.setItem(
      'catchmentLevel',
      window.decodeURIComponent(catchmentLevel)
    )
  } else {
    window.sessionStorage.removeItem('catchmentLevel')
  }

  next()

  // Explicitly remove the query strings from the browser's URL bar
  // TODO: Further discussion with Robert & Namrata
  // window.history.replaceState(null, '', '/')
}
