<template>
  <v-container class="main-content">
    <spinner />
    <p>{{ loadingText }}</p>
  </v-container>
</template>

<script>
import Spinner from '@/components/Spinner'

export default {
  name: 'FullScreenLoader',
  components: { Spinner },
  props: {
    loadingText: {
      type: String,
      default: 'Loading...'
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30vh;

  p {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
</style>
