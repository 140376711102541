import axios from 'axios'
import store from '@/store'
import { API_ERROR_SUBTYPES } from '@/constants'

// Environment variables
const API_REFDATA = process.env.VUE_APP_API_REFDATA
const API_BASE = process.env.VUE_APP_API_BASE

const getReferenceData = () => {
  const url = `${API_REFDATA}/pi-reference-data.json`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error(
        'Failed to load reference data (pi-reference-data.json).'
      )
      store.commit('setError', error)
      throw error
    })
}

const getScholasticYears = (schoolCode) => {
  const url = `${API_BASE}/scholasticYr?schoolCode=${schoolCode}`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error(
        `Failed to get scholastic years for school (${schoolCode}).`
      )
      store.commit('setError', error)
      throw error
    })
}

const getTermDates = () => {
  const url = `${API_REFDATA}/pi-school-term-dates.json`
  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      const error = new Error(
        'Failed to load reference data (pi-school-term-dates.json).'
      )
      store.commit('setError', error)
      throw error
    })
}

const getPredictiveSchools = () => {
  const url = `${API_REFDATA}/all-schools.json`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error(
        'Failed to load reference data (all-schools.json).'
      )
      store.commit('setError', error)
      throw error
    })
}

const getOESProperties = () => {
  const url = `${API_REFDATA}/oes-properties.json`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error('Network Error')
      store.dispatch('setAPIError', {
        error,
        fallbackCode: API_ERROR_SUBTYPES.oesPropertiesJson
      })
      throw error
    })
}

export default {
  getReferenceData,
  getScholasticYears,
  getTermDates,
  getPredictiveSchools,
  getOESProperties
}
