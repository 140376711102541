<template>
  <div>
    <v-textarea
      :ref="inputRef"
      :value="value"
      :placeholder="placeholder"
      :hint="hint"
      :persistent-hint="hint != null"
      :required="required"
      :rules="rules"
      :rows="rows"
      :counter="counter"
      :error-messages="errorMessage"
      outlined
      v-bind="$attrs"
      :aria-describedby="messageBoxId"
      :aria-invalid="!isValid"
      @input="$emit('input', $event)"
      persistent-placeholder
    >
      <template #label>
        <h3 class="text-field-label">{{ label }}</h3>
      </template>
      <template #message="data">
        <div :id="messageBoxId" class="v-messages__message">
          {{ data.message }}
        </div>
      </template>
    </v-textarea>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form'
import accessibilityMixin from '@/mixins/fieldAccessibility'

export default {
  name: 'TextArea',
  mixins: [FormMixin, accessibilityMixin('textarea')],
  props: {
    value: {
      type: String
    },
    rows: {
      type: Number
    },
    counter: {
      type: Number
    },
    errorMessage: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
