<template>
  <div class="address-auto-complete">
    <v-text-field
      ref="input"
      v-model="addressLine1"
      v-bind="$attrs"
      class="mb-6"
      :label="label"
      :placeholder="placeholder"
      data-testid="carer-addressLine1"
      min-length="4"
      max-length="100"
      uppercase="true"
      persistent-hint
      persistent-placeholder
      outlined
      :rules="rules"
      :hint="hint"
      aria-described-by="street-hint-text"
      @input="searchInput"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.down.prevent="moveSelectionDown"
      @keydown.up.prevent="moveSelectionUp"
      @keydown.enter.prevent="onTypeAheadSelect(currentIndex)"
    />
    <div
      v-if="(addressSuggestions.length > 0 && isLookupFocused) || isLoading"
      ref="droplist"
      class="address-suggestion-list"
    >
      <v-progress-linear
        v-if="isLoading"
        color="accent"
        indeterminate
        height="3"
      />
      <div
        v-for="(option, idx) in addressSuggestions"
        :key="idx"
        class="type-ahead-option"
        :class="{ selected: idx === currentIndex }"
        tabindex="0"
        @mousedown="onTypeAheadSelect(idx)"
      >
        <span class="AppTypeAhead_option_text">
          {{ option.address }}
        </span>
      </div>
    </div>
    <v-container v-if="showAlert">
      <Alert
        :show-alert="showAlert"
        type="error"
        :timeout="-1"
        allow-dismiss
        text="Sorry, we can’t retrieve your address at the moment. Please try again later."
      />
    </v-container>
  </div>
</template>

<script>
import catchmentFinder from 'api-client/CatchmentFinder'
import _uniqBy from 'lodash/uniqBy'

import { ADDRESS_VALIDATION } from '@/constants'
import { Alert } from '@nswdoe/doe-ui-core'

export default {
  name: 'AddressLookup',
  components: {
    Alert
  },
  props: {
    value: String,
    label: String,
    placeholder: String,
    hint: String,
    rules: Array, // array of validation rules
    addressKey: Number, // key for the onSelect
    select: Function // callback function for on select address
  },
  data() {
    return {
      addressSuggestions: [],
      addressLine1: this.value,
      currentIndex: null,
      isLookupFocused: false,
      isLoading: false,
      isValueSelected: false,
      showAlert: false
    }
  },
  methods: {
    sanitiseValue(val) {
      return val.replace(/^\s+/, '').replace(/\s+/g, ' ')
    },
    searchInput(val) {
      this.showAlert = false
      this.isLoading = false
      this.isValueSelected = false
      if (!val) {
        this.addressSuggestions = []
        return
      }
      // Remove multiple instances of whitespace, but preserve spaces in the address sent to the API
      const sanitisedVal = this.sanitiseValue(val)

      if (sanitisedVal.trim().length > 4) {
        this.searchAddress(sanitisedVal)
      }
    },
    searchAddress(address) {
      clearTimeout(this.searchTimerId)
      this.searchTimerId = setTimeout(() => {
        this.onTypeAheadSearch(address)
      }, 200)
    },
    showError() {
      this.showAlert = true
    },
    onTypeAheadSearch(value) {
      this.isLoading = true
      // Send typed address to predictive API
      catchmentFinder
        .getPredictiveAddresses(value)
        .then(this.resolvePredictiveAddresses)
        .catch(() => {
          this.isLoading = false
          this.showError()
        })
    },
    onTypeAheadSelect(idx) {
      this.isLoading = true
      const address = this.addressSuggestions[idx]
      catchmentFinder
        .getFullAddressInfo(address)
        .then((response) => this.resolveFullAddressInfo(response))
        .catch(() => {
          this.isLoading = false
          this.showError()
        })
    },
    resolvePredictiveAddresses(resp) {
      this.isLoading = false
      const addresses = _uniqBy(resp.data, (i) => i.address)
      // Sometimes the API returns something other than an array. For some
      // reason this has been historically ignored. Bail early if we do have an
      // array, but it's empty.
      if (!Array.isArray(addresses) || addresses.length === 0) {
        return
      }
      this.addressSuggestions = addresses
    },
    resolveFullAddressInfo(resp) {
      const addressData = resp.data.data.addressDetails
      // Set the primitive address object
      const address = {
        addressLine1: addressData.formattedAddress.split(',')[0],
        suburbName: addressData.localityName,
        postCode: addressData.postcode,
        stateCode: addressData.stateTerritory,
        countryCode: 'AUS',
        validationFlag: ADDRESS_VALIDATION.VALID
      }
      this.addressLine1 = address.addressLine1
      this.isValueSelected = true

      const inputEl = this.$refs.input
      setTimeout(() => {
        inputEl.focus()
        this.isLoading = false
      }, 0)

      // Clear the dropdown list
      this.addressSuggestions = []
      this.$emit('select', address, this.addressKey) // emit value back to the parent
    },
    onFocus() {
      this.isLookupFocused = true
    },
    onBlur() {
      this.isLookupFocused = false
      // when not selected from lookup, invalidate the address
      if (!this.isValueSelected) {
        this.$emit('blur', this.addressKey)
      }
    },
    moveSelectionUp() {
      if (this.addressSuggestions.length) {
        this.currentIndex -= 1
        if (this.currentIndex < 0) {
          this.currentIndex = this.addressSuggestions.length - 1
        }
        this.scrollSelectionIntoView()
      }
    },
    moveSelectionDown() {
      if (this.addressSuggestions.length) {
        this.currentIndex += 1
        if (this.currentIndex >= this.addressSuggestions.length) {
          this.currentIndex = 0
        }
        this.scrollSelectionIntoView()
      }
    },
    scrollSelectionIntoView() {
      // Ensures the currently selected item in the droplist is visble,
      // i.e. is not scrolled out of view.
      const droplistEl = this.$refs.droplist
      const hasScrollBar =
        droplistEl && droplistEl.scrollHeight !== droplistEl.clientHeight

      if (hasScrollBar) {
        // Timeout ensures UI has rendered the new currentIndex (set when
        // moving the selection up or down)
        setTimeout(() => {
          const selectedEl = droplistEl.getElementsByClassName('selected')[0]
          if (selectedEl.offsetTop < droplistEl.scrollTop) {
            droplistEl.scrollTop = selectedEl.offsetTop
          } else {
            const lastVisibleRowPos =
              droplistEl.scrollTop +
              droplistEl.clientHeight -
              selectedEl.clientHeight

            if (selectedEl.offsetTop > lastVisibleRowPos) {
              droplistEl.scrollTop =
                selectedEl.offsetTop -
                droplistEl.clientHeight +
                selectedEl.clientHeight
            }
          }
        }, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.address-auto-complete {
  position: relative;
}
.address-suggestion-list {
  position: absolute; // Necessary for all the calculations to work in scrollSelectionIntoView()
  top: 50px;
  font-size: 1rem;
  max-height: 260px;
  z-index: 99;
  overflow-y: auto;
  width: 100%;
  padding: 0.1em 0;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  background-color: white;
  color: black;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

  .type-ahead-option {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 2em 0.5em 1em;
    cursor: pointer;
    border: 2px solid transparent;

    &:hover {
      display: flex !important; // that allow to make dropdown clickable on IE10=>UP
      background: #f4f4f7;
      outline: none;
    }
  }
  .selected {
    // keydown (arrows keys action) style
    border-color: $ads-blue-2-dark;
    color: black;
  }
}

.address-auto-complete .v-text-field .v-input {
  max-width: 500px;
  input {
    text-transform: uppercase;
  }
}
</style>
