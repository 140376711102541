import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import authModule from './modules/auth'
import errorModule from './modules/error'
import formModule from './modules/form'
import refDataModule from './modules/refData'
import schoolModule from './modules/school'
import catchmentFinderModule from './modules/catchmentFinder'
import documentUpload from './modules/documentUpload'

Vue.use(Vuex)

export default new Store({
  modules: {
    auth: authModule,
    error: errorModule,
    form: formModule,
    refData: refDataModule,
    school: schoolModule,
    catchmentFinder: catchmentFinderModule,
    documentUpload
  }
})
