<template>
  <div>
    <Banner>
      <h2 class="welcome-back--title" data-testid="welcome-username">
        Welcome back,<br />{{ userEmail }}
      </h2>
    </Banner>

    <v-container fluid class="ooaContent text-center">
      <v-btn
        v-if="userEmail"
        class="primary mt-12 mb-12"
        x-large
        data-testid="btn-resumeApplication"
        @click="resumeApplication"
      >
        Resume application
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import base64url from 'base64url'

export default {
  name: 'WelcomeBack',
  components: {
    Banner
  },
  data() {
    return {
      userEmail: null
    }
  },
  created() {
    if (this.$route.query.userId) {
      this.userEmail = base64url.decode(this.$route.query.userId)
    }
  },
  methods: {
    resumeApplication() {
      this.$store.commit('setIsResuming', true)
      this.$router.push({
        name: 'login',
        params: { resumeViaEmail: true, userEmail: this.userEmail }
      })
    }
  }
}
</script>

<style scoped>
.welcome-back--title {
  word-break: break-word;
}
</style>
