import { ERROR_CODES } from '@/constants'

/* eslint-disable no-param-reassign */

const errorModule = {
  state: {
    errorObject: null, // The last error manually encountered
    response: null,
    fallbackCode: null,
    type: null
  },
  getters: {
    error: (state) => state,
    errorType: (state) => state.type
  },
  mutations: {
    setError(state, error) {
      state.errorObject = error
    },
    setErrorType(state, errorType) {
      state.type = errorType
    },
    setAPIError(state, { errorObject, response, fallbackCode, type }) {
      state.errorObject = errorObject
      state.response = response
      state.fallbackCode = fallbackCode
      state.type = type
    }
  },
  actions: {
    setError({ commit }, { error }) {
      commit('setError', error)
    },
    setErrorType({ commit }, { errorType }) {
      commit('setErrorType', errorType)
    },
    setAPIError({ commit }, { error, fallbackCode }) {
      commit('setAPIError', {
        errorObject: error,
        response: error.response,
        fallbackCode,
        type: ERROR_CODES.API
      })
    }
  }
}

export default errorModule
