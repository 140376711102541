<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0">
    <v-alert
      v-if="isOverCap"
      border="left"
      colored-border
      color="blue"
      icon="mdi-alert-circle"
      class="alert--light-blue mb-8"
      data-testid="over-cap-alert"
    >
      <p>
        <strong
          >We are not considering applications for out-of-area
          enrolments</strong
        >
      </p>
      <span>
        However, we may take into account exceptional circumstances that involve
        this student and if siblings are enrolled at our school.
      </span>
    </v-alert>
    <div>
      <p>
        Provide details about any of the criteria below that support
        {{ pluralisedStudentName() }} application. You can upload supporting
        documents about medical conditions here or present them at the school.
      </p>

      <p class="mt-4">
        When considering this application, we may ask for more information,
        supporting documents, or arrange an interview.
      </p>
      <p class="mt-4">
        Learn more about how we
        <a :href="privacyLink" target="_blank"
          >manage and protect your personal information</a
        >
      </p>
    </div>
    <v-form
      ref="form"
      v-model="validConsiderations"
      data-testid="considerations-form"
    >
      <div
        v-for="criteriaType in criteriaTypes"
        :key="criteriaType.type"
        class="mt-8"
      >
        <h3 class="pb-3">{{ criteriaType.description }}</h3>
        <v-card class="pl-6 pr-6 pt-2">
          <div
            v-for="(criteria, index) in criteriaType.criteria"
            :key="criteria.code"
          >
            <v-checkbox
              v-model="criteria.selected"
              :data-initial-focus="index === 0"
              class="priority"
              data-testid="criteria-checkbox"
              @change="checkCriteria(criteria)"
            >
              <template #label>
                <div>
                  <span v-if="criteria.priority">
                    Priority {{ index + 1 }}:
                  </span>
                  <strong v-if="criteria.code === 'LAW'"
                    >Learning and wellbeing needs requiring reasonable
                    adjustments and/or additional education support,
                    including:</strong
                  >
                  <strong v-else>{{ criteria.value }}</strong>
                </div>
              </template>
            </v-checkbox>
            <div
              class="mt-n3 ml-8 mb-4 ads-navy"
              v-if="criteria.code === 'LAW'"
            >
              <ul>
                <li>cultural considerations</li>
                <li>disability</li>
                <li>developmental age</li>
                <li>trauma</li>
                <li>child protection concerns</li>
                <li>medical</li>
                <li>individual needs and circumstances</li>
              </ul>
            </div>
            <div
              v-if="criteria.text && criteria.selected"
              class="ml-7 mb-5 criteria-text"
            >
              {{ criteria.text }}
            </div>
            <Siblings
              v-if="criteria.code === 'SIB' && criteria.selected"
              class="ml-4"
              data-testid="sibling-details"
              ref="siblingForm"
            />
            <TextArea
              v-if="criteria.selected"
              v-model="criteria.description"
              class="pb-6 mt-3 ml-6"
              :label="
                criteria.code === 'SIB'
                  ? 'Further details (optional)'
                  : 'Provide details'
              "
              placeholder=" "
              :rules="
                criteria.code === 'SIB'
                  ? [rules.maxLength]
                  : [rules.mandatory, rules.maxLength]
              "
              :counter="counter"
              data-testid="criteria-description"
            />
            <SupportingDocumentUpload
              v-if="criteria.code === 'LAW' && criteria.selected"
              ref="supportingDocumentUpload"
              :value="files"
              class="ml-6 pb-10"
              :category="criteria.code"
              :mime-types="criteria.supportingDocConfig.supportedMIMETypes"
              :hint="criteria.supportingDocConfig.helpText"
              :file-limit="criteria.supportingDocConfig.fileLimit"
              :size-limit="criteria.supportingDocConfig.sizeLimitMB"
              @input="onFileUpload"
              data-testid="medical-supporting-documents"
            />
            <div v-if="index + 1 !== activeCriteria.length" class="criteria" />
          </div>
        </v-card>
      </div>
      <div v-if="showOtherConsiderations">
        <v-card class="mt-8 pa-8">
          <p class="mb-4">
            Please outline any other considerations that relate to your child's
            out-of-area application.
          </p>
          <TextArea
            v-model="form.otherConsiderations"
            label="Other considerations"
            placeholder=" "
            :rules="maxLength"
            :counter="counter"
            data-testid="criteria-otherConsiderations"
          />
        </v-card>
      </div>
      <div class="mt-8" v-if="savedInActiveCriteria.length > 0">
        <h3 class="pb-3">No longer assessed criteria</h3>
        <v-card class="pl-6 pr-6 pt-2">
          <div v-for="criteria in savedInActiveCriteria" :key="criteria.code">
            <div class="d-flex pt-3 pb-3 text-wrap">
              <v-icon disabled class="mr-3 align-start"
                >mdi-block-helper</v-icon
              >
              <div class="disabled-consideration">
                <strong>Note: </strong
                ><span
                  >"{{ criteria.value }}" is no longer assessed for out-of-area
                  enrolment at this school</span
                >
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </v-form>
    <div class="d-flex justify-end mt-6">
      <v-btn
        xclass="button-next primary"
        color="primary"
        x-large
        data-testid="save-criteria-btn"
        @click="
          enableLoader()
          save()
        "
      >
        <ButtonSpinner :loading="loading" class="mr-2" />
        Save &amp; Continue
      </v-btn>
    </div>
  </v-container>
</template>

<script>
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["criteria"] }] */
import TextArea from '@/components/form/TextArea'
import ButtonSpinner from '@/components/ButtonSpinner'
import Siblings from '@/components/Siblings'
import SupportingDocumentUpload from '@/components/SupportingDocumentUpload'
import { mapGetters } from 'vuex'
import handleFirstFocus from '@/helpers/form/focus'
import { FILE_UPLOAD_ERROR, FILE_REMOVE_ERROR } from '@/constants'

export default {
  name: 'FormConsiderations',
  components: {
    TextArea,
    ButtonSpinner,
    Siblings,
    SupportingDocumentUpload
  },
  data() {
    return {
      loading: false,
      details: null,
      counter: 800,
      validConsiderations: true,
      criterias: [],
      rules: {
        mandatory: (v) => !!v || 'Please provide details',
        maxLength: (v) => !v || v.length <= 800 || 'Max 800 characters'
      },
      maxLength: [(v) => !v || v.length <= 800 || 'Max 800 characters'],
      isSectionCommitted: false,
      files: []
    }
  },
  computed: {
    ...mapGetters([
      'form',
      'states',
      'currentStep',
      'schoolData',
      'isOverCap',
      'pluralisedStudentName',
      'supportingDocuments',
      'prevCriterias',
      'oesProperties'
    ]),
    activeCriteria() {
      return this.criterias.filter((c) => c.active)
    },
    inActiveCriteria() {
      return this.criterias.filter((c) => !c.active)
    },
    priorityCriteria() {
      return this.activeCriteria?.filter((c) => c.priority === true) || []
    },
    considerCriteria() {
      return this.activeCriteria?.filter((c) => c.priority === false) || []
    },
    criteriaTypes() {
      return [
        {
          type: 'priority',
          description: 'The school will prioritise the following criteria',
          criteria: this.priorityCriteria
        },
        {
          type: 'consider',
          description: 'The school will consider the following criteria',
          criteria: this.considerCriteria
        }
      ]
    },
    savedInActiveCriteria() {
      return (
        this.inActiveCriteria.filter((c) =>
          this.form?.criteria?.map(({ code }) => code).includes(c.code)
        ) || []
      )
    },
    showOtherConsiderations() {
      return this?.schoolData?.enableOtherCriteria
    },
    privacyLink() {
      return this.oesProperties?.footer?.privacy?.legislation?.link || ''
    }
  },
  mounted() {
    //This code is required for release/8.7.0 for new policy changes start and can be removed at later stage
    if (this.form?.supportingDocuments?.MED) {
      const lawDocs = []
      this.form?.supportingDocuments?.MED.forEach((doc) => {
        const lawObjectKey = doc.objectKey.split('/')
        const lawDocObjectKey = `/LAW/${lawObjectKey[2]}/${lawObjectKey[3]}`
        const docDetails = {
          name: doc.name,
          uploadStatus: doc.uploadStatus,
          size: doc.size,
          type: doc.type,
          objectKey: lawDocObjectKey
        }
        lawDocs.push(docDetails)
      })
      this.form.supportingDocuments.LAW = lawDocs
    }
    //This code is required for release/8.7.0 for new policy changes end and can be removed at later stage
    this.files = this.form?.supportingDocuments?.LAW || []
    if (this.prevCriterias) {
      this.criterias.forEach((criteria) => {
        this.prevCriterias.forEach((prevCriteria) => {
          if (
            criteria.code === prevCriteria.code &&
            prevCriteria.selected === true
          ) {
            criteria.selected = true
            criteria.description = prevCriteria.description
          }
        })
      })
    }

    if (this.form.criteria) {
      this.validate()
    }

    this.$nextTick(() => {
      const formInputs = this.$refs.form.inputs
      handleFirstFocus(formInputs)
    })
  },
  created() {
    // Load reference data criteria & create default array of criteria objects with a code & value
    if (this.schoolData && this.schoolData?.criteria?.length) {
      // eslint-disable-next-line

      this.criterias = this.schoolData.criteria.map((c) => {
        return {
          selected: null,
          code: c.code,
          value: c.value,
          description: null, // the value entered by the user. Flows through to SI! Don't change!
          text: c.text, // criteria description set in SI settings
          active: c.active,
          supportingDocConfig: c.supportingDocConfig,
          priority: c.priority
        }
      })
    }
    // Set description data returned by API against each criteria.
    if (this.form.criteria) {
      //This code is required for release/8.7.0 for new policy changes start and can be removed at later stage
      const sibCriteria = this.form.criteria.some((c) => c.code === 'SIB')
      if (
        !sibCriteria &&
        this.form?.hasAnySiblings === true &&
        this.form?.siblings?.length > 0
      ) {
        this.form.criteria.push({
          code: 'SIB',
          description: ''
        })
      }
      //This code is required for release/8.7.0 for new policy changes end and can be removed at later stage
      const savedCriterias = this.form.criteria
      const medCriteria = savedCriterias.find((c) => c.code === 'MED')
      // eslint-disable-next-line
      if (!!medCriteria) {
        const lawCriteria = {
          code: 'LAW',
          description: medCriteria.description
        }
        Object.assign(medCriteria, lawCriteria)
      }
      savedCriterias.forEach((item) => {
        this.criterias.forEach((criteria) => {
          if (criteria.code === item.code) {
            criteria.selected = true
            criteria.description = item.description
          }
        })
      })
    }
  },
  beforeDestroy() {
    this.commitSection()
  },
  methods: {
    enableLoader() {
      this.loading = true
    },
    disableLoader() {
      this.loading = false
    },
    validate() {
      const isValid = this.$refs.form.validate()
      this.$store.dispatch('setIsValidConsiderations', { isValid })
    },
    getCriteriaById(criteriaId) {
      return this.criterias.find((criteria) => criteria.code === criteriaId)
    },
    onFileUpload(files) {
      this.files = files
      this.$store.commit('setSupportingDocuments', { LAW: files })
    },
    checkCriteria(criteria) {
      if (criteria.selected) {
        if (
          this.prevCriterias?.forEach((prevCriteria) => {
            if (
              prevCriteria.code === criteria.code &&
              prevCriteria.description
            ) {
              criteria.description = prevCriteria.description
            }
          })
        ) {
          if (criteria.description) {
            this.$store.commit('setSelectedPrevCriteria', criteria)
          }
        }
      }
    },
    commitSection() {
      if (!this.isSectionCommitted) {
        const chkedList = this.criterias.filter(
          (criteria) => criteria.selected && criteria.active
        )
        const criteria = chkedList.map((item) => ({
          code: item.code,
          description: item.description
        }))
        this.$store.commit('setCriteria', criteria)
        if (!this.showOtherConsiderations) {
          this.form.otherConsiderations = null
        }
        this.$store.commit('setCapacityDetails', this.schoolData.capacity)
        this.$store.commit(
          'setOtherConsiderations',
          this.form.otherConsiderations
        )
        this.isSectionCommitted = true
        this.validate()
      }
    },
    async save() {
      try {
        this.$store.commit('setPrevCriterias', this.criterias)
        if (this.$store.getters.isFileUploadInProgress) {
          this.$emit('notification', FILE_UPLOAD_ERROR)
        } else if (this.$store.getters.isFileDeleteInProgress) {
          this.$emit('notification', FILE_REMOVE_ERROR)
        } else {
          this.validate()
          this.commitSection()
          const sibCriteria = this.form.criteria.some((c) => c.code === 'SIB')
          if (sibCriteria) {
            await this.$refs.siblingForm[0].save()
          }
          await this.$store.dispatch('updateApplication')
          await this.$store.dispatch('setCurrentStep', {
            currentStep: this.currentStep + 1
          })
          await this.$router.push('/form/review')
        }
      } finally {
        this.disableLoader()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  color: $ads-navy;
  font-size: 1.375rem;
  line-height: 1.2;
}
.criteria {
  margin-right: -24px;
  margin-left: -24px;
  border-bottom: 1px solid #e4e4e6;
}
::v-deep .priority {
  .theme--light.v-label {
    color: $color-text-body;
  }
  .v-input__slot {
    align-items: stretch;
  }
  .v-input--selection-controls__input {
    height: 22px;
  }
}
.disabled-consideration {
  color: $ads-dark-60;
}

.ads-navy {
  color: $ads-navy;
}

.considerations-hint {
  font-size: 0.875rem;
  color: $ads-dark-60;
}
</style>
