<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0" data-testid="student-details-section">
    <v-row>
      <v-col>
        <div class="fieldLabel">First name</div>
        <div class="fieldValue">
          <span v-if="form.student">{{
            form.student.firstName ? form.student.firstName : '(empty)'
          }}</span>
          <span v-if="!form.student">(empty)</span>
        </div>
      </v-col>
      <v-col>
        <div class="fieldLabel">Middle name</div>
        <div class="fieldValue">
          <span v-if="form.student">{{
            form.student.otherName ? form.student.otherName : '(empty)'
          }}</span>
          <span v-if="!form.student">(empty)</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="fieldLabel">Family name</div>
        <div class="fieldValue">
          <span v-if="form.student">{{
            form.student.familyName ? form.student.familyName : '(empty)'
          }}</span>
          <span v-if="!form.student">(empty)</span>
        </div>
      </v-col>
      <v-col>
        <div class="fieldLabel">Preferred name</div>
        <div class="fieldValue">
          <span v-if="form.student">{{
            form.student.prefFirstName ? form.student.prefFirstName : '(empty)'
          }}</span>
          <span v-if="!form.student">(empty)</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="fieldLabel">Gender</div>
        <div class="fieldValue">
          <span v-if="form.student">{{
            genderCode(form.student.genderCode)
              ? genderCode(form.student.genderCode)
              : '(empty)'
          }}</span>
          <span v-if="!form.student">(empty)</span>
        </div>
      </v-col>
      <v-col>
        <div class="fieldLabel">Date of birth</div>
        <div class="fieldValue">
          <span v-if="form.student">{{
            form.student.dateOfBirth ? form.student.dateOfBirth : '(empty)'
          }}</span>
          <span v-if="!form.student">(empty)</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="fieldLabel">Intended start date</div>
        <div class="fieldValue">
          <span v-if="form.otherStudentDetails">{{
            form.otherStudentDetails.intendedStartDate ===
            'Choose a specific start date'
              ? form.otherStudentDetails.intendedStartDate
              : form.otherStudentDetails.intendedStartDatePrint
          }}</span>
          <span v-if="!form.otherStudentDetails">(empty)</span>
        </div>
      </v-col>
      <v-col
        v-if="
          form.otherStudentDetails &&
          form.otherStudentDetails.intendedStartDate ===
            'Choose a specific start date'
        "
      >
        <div class="fieldLabel">Start date</div>
        <div class="fieldValue">
          <span>
            {{
              form.otherStudentDetails.enterStartDate
                ? form.otherStudentDetails.enterStartDate
                : '(empty)'
            }}</span
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="fieldLabel">Previous school attended</div>
        <div class="fieldValue">
          <span v-if="form.otherStudentDetails">{{
            form.otherStudentDetails.prevAttendSchool
              ? form.otherStudentDetails.prevAttendSchool
              : '(empty)'
          }}</span>
          <span v-if="!form.otherStudentDetails">(empty)</span>
        </div>
      </v-col>
      <v-col>
        <div class="fieldLabel">Residency Status</div>
        <div class="fieldValue">
          <span v-if="form.residencyStatus">{{
            form.residencyStatus
              ? residencyStatus(form.residencyStatus)
              : '(empty)'
          }}</span>
          <span v-if="!form.residencyStatus">(empty)</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="fieldLabel">Intended scholastic year</div>
        <div class="fieldValue">
          <span v-if="form.otherStudentDetails">{{
            scholasticYear(form.otherStudentDetails.scholasticYear)
              ? scholasticYear(form.otherStudentDetails.scholasticYear)
              : '(empty)'
          }}</span>
          <span v-if="!form.otherStudentDetails">(empty)</span>
        </div>
      </v-col>
      <v-col
        v-if="
          form.otherStudentDetails &&
          form.otherStudentDetails.prevAttendSchool === 'Yes'
        "
      >
        <div class="fieldLabel">
          Where did {{ preferredName }} most recently attend school?
        </div>
        <div class="fieldValue">
          {{
            form.otherStudentDetails.prevSchoolType
              ? prevSchoolType(form.otherStudentDetails.prevSchoolType)
              : '(empty)'
          }}
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="
        form.otherStudentDetails &&
        form.otherStudentDetails.prevAttendSchool === 'Yes' &&
        form.otherStudentDetails.prevSchoolType === 'OVS'
      "
    >
      <v-col>
        <div class="fieldLabel">School name</div>
        <div class="fieldValue">
          {{
            form.otherStudentDetails.otherSchoolAttendedName
              ? form.otherStudentDetails.otherSchoolAttendedName
              : '(empty)'
          }}
        </div>
      </v-col>
      <v-col>
        <div class="fieldLabel">School location</div>
        <div class="fieldValue">
          {{
            form.otherStudentDetails.otherSchoolAttendedLocation
              ? form.otherStudentDetails.otherSchoolAttendedLocation
              : '(empty)'
          }}
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="
        form.otherStudentDetails &&
        form.otherStudentDetails.prevAttendSchool === 'Yes' &&
        form.otherStudentDetails.prevSchoolType !== 'OVS'
      "
    >
      <v-col>
        <div class="fieldLabel">School name</div>
        <div class="fieldValue">
          {{
            form.otherStudentDetails.prevSchool
              ? form.otherStudentDetails.prevSchool.text
              : '(empty)'
          }}
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="
        form.otherStudentDetails &&
        form.otherStudentDetails.prevAttendSchool === 'Yes'
      "
    >
      <v-col>
        <div class="fieldLabel">Attended from</div>
        <div class="fieldValue">
          {{
            form.otherStudentDetails.prevSchoolStartDate
              ? form.otherStudentDetails.prevSchoolStartDate
              : '(empty)'
          }}
        </div>
      </v-col>
      <v-col>
        <div class="fieldLabel">Attended until</div>
        <div class="fieldValue">
          {{
            form.otherStudentDetails.prevSchoolLastDate
              ? form.otherStudentDetails.prevSchoolLastDate
              : '(empty)'
          }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StudentView',
  computed: {
    ...mapGetters({
      form: 'form'
    }),
    preferredName() {
      const studentDetails = this.form.student
      if (studentDetails) {
        if (studentDetails.prefFirstName || studentDetails.firstName) {
          return studentDetails.prefFirstName || studentDetails.firstName
        }
      }
      return 'Student'
    }
  },
  methods: {
    genderCode(code) {
      if (code) {
        return code === 'M' ? 'Male' : 'Female'
      }
      return null
    },
    scholasticYear(value) {
      const date = new Date()
      let scholasticYears =
        this.$store.getters.scholasticYears[date.getFullYear()]
      scholasticYears = scholasticYears.filter((year) => year.value === value)
      if (scholasticYears.length > 0) {
        return scholasticYears[0].text
      }
      return null
    },
    prevSchoolType(value) {
      const state = this.$store.getters.states.filter(
        (state) => state.value === value
      )
      if (state.length > 0) {
        return state[0].text
      }
      return null
    },
    residencyStatus(value) {
      const res = this.$store.getters.residencyStatuses.filter(
        (res) => res.value === value
      )
      if (res.length > 0) {
        return res[0].text
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.fieldValue {
  color: $color-text-body;
  font-weight: bold;
  font-size: 14px;
  overflow-wrap: break-word;
}
.fieldLabel {
  font-size: 14px;
  color: $ads-dark-70;
}
</style>
