<template>
  <v-progress-circular
    v-if="loading"
    :size="26"
    :width="4"
    :color="color"
    indeterminate
    aria-label="loading"
    class="icon spin"
  />
</template>

<script>
export default {
  name: 'ButtonSpinner',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>

<style lang="scss" scoped></style>
