import { ERROR_MESSAGES } from '@/constants'

const validName = (v) => !v || /^[a-zA-Z ()'-]+$/.test(v) || ERROR_MESSAGES.NAME

/**
 * This is required because the vuetify rules prop is run before the onBlur event
 * Later on the non ascii characters are removed before formatting the phone number
 * See method ParentCarer.updateAndFormatContactValue()
 *
 * \x00-\x1f = non ascii characters (char code 0-31 in hex)
 * \x7f-\uffff = non ascii characters (char code 127-65535 in hex)
 * x30-\x39 = ascii characters (digit 0-9)
 * x20\ = ascii characters (space)
 * x28\x29 = ascii characters (left and right bracket)
 * x2b\x2d = ascii characters (+ and -)
 * @param {*} v
 * @returns
 */
// eslint-disable-next-line no-control-regex
const containsNonAsciiOrValidCharactersForPhoneNumber = (v) =>
  !v ||
  // eslint-disable-next-line no-control-regex
  /^[\x00-\x1f\x7f-\uffff\x30-\x39\x20\x28\x29\x2b\x2d]+$/.test(v) ||
  ERROR_MESSAGES.PHONE_NUM

// Can only contain 4 digits
const postcode = (v) => /^(\d{4})$/.test(v)

// Can only be empty or contain 4 digits
const emptyOrPostcode = (v) => !v || /^(\d{4})$/.test(v)

// Can only contain letters, spaces, ' and -
const strictAlpha = (v) => /^[a-zA-Z '\\-]+$/.test(v)

// Can only be empty or contain letters, spaces, ' and -
const emptyOrStrictAlpha = (v) => /^[a-zA-Z '\\-]*$/.test(v)

export default {
  validName,
  containsNonAsciiOrValidCharactersForPhoneNumber,
  postcode,
  emptyOrPostcode,
  strictAlpha,
  emptyOrStrictAlpha
}
