import { CATCHMENTS, EHUB, OOA } from '@/constants'
/* eslint camelcase: ["error", {allow: ["calendar_year", "catchment_level"]}] */

export function pluralise(str) {
  const trimmedStr = str && str.trim()
  if (trimmedStr) {
    return trimmedStr.slice(-1) === 's' ? `${trimmedStr}'` : `${trimmedStr}'s`
  }
  return ''
}

export function capitalize(str) {
  if (!str) {
    return str
  }

  return str
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export function isSecondary(catchmentLevel, selectedCatchment) {
  return (
    CATCHMENTS.SECONDARY === catchmentLevel ||
    CATCHMENTS.SECONDARY === selectedCatchment
  )
}

export function getQueryYear(queryYearLookupArray, catchment, selectedYear) {
  if (!Array.isArray(queryYearLookupArray)) {
    return new Date().getFullYear()
  }
  // Maps selectCatchment and selectedYear to the year we must send in the SQL request.
  return queryYearLookupArray.find(
    (obj) =>
      obj.catchmentLevel === catchment &&
      obj.calendarYear === parseInt(selectedYear, 10)
  )?.queryYear
}

export function getFallBackQueryYear(
  queryYearLookupArray,
  selectedCatchment,
  selectedYear
) {
  if (!Array.isArray(queryYearLookupArray)) {
    return new Date().getFullYear()
  }
  // Maps selectCatchment and selectedYear to the year we must send in the SQL request.
  return queryYearLookupArray.find(
    (obj) =>
      obj.catchmentLevel === selectedCatchment &&
      obj.calendarYear === parseInt(selectedYear, 10)
  )?.fallbackQueryYear
}

export function getQueryYearLookupArray(
  rows,
  uniqueCatchmentLevels,
  baseYear,
  nextYear
) {
  // There calendar_year variable we send to the catchment area SQL query will not
  // always match up with the actual selected calendar year.
  // The actual sent value can vary based on the catchmentLevel selected and the results of the
  // getCatchmentAndYearData request.
  // The year were send in the request (referred to as queryYear) will be based
  // on the following logic:
  // 1. the actual calendarYear. Only if it has been specified as a value in the DB. OR
  // 2. 0. Only if 1. is not true and 0 is specified as a value in the DB. OR
  // 3. baseYear. Only if 1. and 2. are not true.
  // This function generates an array we can use to map the selectedYear
  // and selectedCatchment to the year we send in the request; this mapping
  // is done in 'getQueryYear'

  const queryYearLookupArray = []
  uniqueCatchmentLevels.forEach((catchmentLevel) => {
    ;[baseYear, nextYear].forEach((calendarYear) => {
      // Check if the actual year exists as a SQL year, if true, then use that
      const actualYearEntry = rows.find(
        ({ calendar_year, catchment_level }) =>
          calendar_year === calendarYear && catchment_level === catchmentLevel
      )
      if (actualYearEntry) {
        queryYearLookupArray.push({
          catchmentLevel,
          calendarYear,
          queryYear: calendarYear
        })
        return
      }

      queryYearLookupArray.push({
        catchmentLevel,
        calendarYear,
        queryYear: 0,
        fallbackQueryYear: calendarYear - 1
      })
    })
  })
  return queryYearLookupArray
}

export function nameNotMatched(pInDraft, pInErn) {
  return (
    pInDraft.parentCarerGivenName !== pInErn.firstName ||
    pInDraft.parentCarerFamilyName !== pInErn.lastName
  )
}

export function isFromEhub() {
  return EHUB === JSON.parse(window.sessionStorage.getItem('comesFrom'))
}

export function getGtmApplication(viaEhub) {
  if (isFromEhub() || viaEhub) {
    return EHUB
  }
  return OOA
}

export default {
  pluralise,
  capitalize,
  isSecondary,
  getQueryYear,
  getFallBackQueryYear,
  getQueryYearLookupArray,
  nameNotMatched,
  isFromEhub,
  getGtmApplication
}
