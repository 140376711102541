import Vue from 'vue'
import VueRouter from 'vue-router'

import Error from '@/views/Error'
import Form from '@/views/Form'
import Home from '@/views/Home'
import LoginOTP from '@/views/LoginOTP'
import LoginView from '@/views/LoginView'
import NotFound from '@/views/NotFound'
import Privacy from '@/views/Privacy'
import SchoolReminder from '@/views/SchoolReminder'
import WelcomeBack from '@/views/WelcomeBack'
import Confirmation from '@/views/Confirmation'
import SubmittedApplication from '@/views/SubmittedApplication'
import Timeout from '@/views/Timeout'
import LogoutView from '@/views/LogoutView'
import EhubLandingView from '@/views/ehub/EhubLandingView'
import EHubResume from '@/views/ehub/EHubResume'

import store from '@/store'
import homeGuard from './guards'
import formGuard from './formGuards'
import preFormGuard from './preFormGuard'
import ehubGuard from './ehubGuards'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: homeGuard
  },
  {
    path: '/schoolreminder',
    name: 'schoolreminder',
    component: SchoolReminder,
    meta: {
      title: 'Local School'
    },
    beforeEnter: preFormGuard
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    meta: {
      title: 'Privacy'
    },
    beforeEnter: preFormGuard
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    props: true,
    meta: {
      title: 'Login'
    },
    beforeEnter: preFormGuard
  },
  {
    path: '/login/otp',
    name: 'otp',
    component: LoginOTP,
    props: true,
    meta: {
      title: 'Verification'
    },
    beforeEnter: preFormGuard
  },
  {
    path: '/login/return',
    name: 'return',
    component: WelcomeBack,
    meta: {
      title: 'Resume Application'
    }
  },
  {
    path: '/login/timeout',
    name: 'timeout',
    component: Timeout,
    meta: {
      title: 'Session Expired'
    }
  },
  {
    path: '/form/:step?',
    name: 'form',
    component: Form,
    props: true,
    beforeEnter: formGuard
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: Confirmation,
    meta: {
      title: 'Confirmation'
    }
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    meta: {
      title: 'Error'
    }
  },
  {
    path: '/submitted',
    name: 'submitted',
    component: SubmittedApplication,
    meta: {
      title: 'Application Submitted'
    }
  },
  {
    path: '/ehub',
    name: 'eHubRouteWrapper',
    component: {
      render: (c) => c('router-view')
    },
    children: [
      {
        name: 'ehubLanding',
        path: 'new',
        component: EhubLandingView,
        beforeEnter: ehubGuard,
        meta: {
          title: 'Ehub Landing Page'
        }
      },
      {
        name: 'ehubResume',
        path: 'resume',
        component: EHubResume
      }
    ]
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      title: 'NotFound'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
    meta: {
      title: 'Logout'
    }
  }
]

const isEhubApp = window.location.origin.indexOf('ehub') !== -1
const isEhubLocalDev = process.env.VUE_APP_EHUB_LOCAL_DEV === 'true'

const router = new VueRouter({
  mode: 'history',
  base: isEhubApp || isEhubLocalDev ? '/ooa/' : '',
  routes
})

router.beforeEach((to, from, next) => {
  const formTitles = {
    student: 'Student Details',
    parentcarer: 'Parent/carer Details',
    considerations: 'Considerations',
    review: 'Review & Submit'
  }
  const titlePrefix =
    to.name === 'form' ? formTitles[to.params.step] : to.meta.title
  const DEFAULT_TITLE = 'Out-of-Area Enrolment Application'
  document.title = titlePrefix
    ? `${titlePrefix} | ${DEFAULT_TITLE}`
    : DEFAULT_TITLE
  Object.keys(formTitles).forEach((key, index) => {
    if (key === to.params.step) {
      store.dispatch('setCurrentStep', { currentStep: index + 1 })
    }
  })
  next()
})

router.afterEach(() => {
  window.scroll(0, 0)
})

export default router
