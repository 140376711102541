/* eslint-disable no-param-reassign */
import catchmentFinderApi from '@/api/server/CatchmentFinder'
import { capitalize } from '@/helpers'

const catchmentFinderModule = {
  state: {
    localCatchmentSchools: null
  },
  getters: {
    localCatchmentSchools: (state) => state.localCatchmentSchools
  },
  mutations: {
    setLocalCatchmentSchools(state, localCatchmentSchools) {
      state.localCatchmentSchools = localCatchmentSchools
    }
  },
  actions: {
    fetchLocalCatchmentSchool(
      { commit, getters },
      { lat, long, catchmentLevel, selectedYear, scholasticYear }
    ) {
      const endPoints = getters.oesProperties?.endpoints
      const schoolFinderUrl = endPoints?.schoolFinder?.urls
      if (!lat || !long) {
        return commit('setLocalCatchmentSchools', null)
      }
      return catchmentFinderApi
        .getAddressCatchment(
          schoolFinderUrl,
          [long, lat],
          catchmentLevel,
          selectedYear,
          scholasticYear
        )
        .then((resp) => {
          // school finder sometimes returns non array or empty array
          if (Array.isArray(resp.data.rows) && resp.data.rows.length) {
            const localCatchmentSchools = resp.data.rows.map((cfData) => {
              const pilotSchoolData = getters.pilotSchool(cfData.school_code)
              const suburb = capitalize(cfData.town_suburb).trim()
              const catchmentFinderAddress = `${cfData.street}, ${suburb}, NSW ${cfData.postcode}`

              return {
                schoolCode: cfData.school_code,
                schoolName: cfData.school_name,
                schoolEmail: pilotSchoolData?.email || cfData.school_email,
                schoolWebsite: pilotSchoolData?.website || cfData.website,
                schoolPhone: pilotSchoolData?.phone || cfData?.phone.toString(),
                schoolAddress:
                  pilotSchoolData?.address || catchmentFinderAddress,
                long: cfData.longitude,
                lat: cfData.latitude,
                isOesSchool: !!pilotSchoolData
              }
            })
            commit('setLocalCatchmentSchools', localCatchmentSchools)
          } else {
            commit('setLocalCatchmentSchools', null)
          }
          return resp
        })
    }
  }
}

export default catchmentFinderModule
