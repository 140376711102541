<template>
  <WelcomeOoa />
</template>

<script>
import WelcomeOoa from '@/views/WelcomeOoa.vue'

export default {
  name: 'HomeView',
  components: {
    WelcomeOoa
  }
}
</script>
