<template>
  <v-container>
    <v-card class="pa-8 pr-6">
      <v-row>
        <v-col cols="12" md="3" class="ServiceNSWLogo">
          <img
            alt="Service NSW logo"
            :src="require('@/assets/Service_NSW.svg')"
            data-testid="Service-NSW-Logo"
          />
        </v-col>
        <v-col cols="12" md="7" class="headline-1">
          <span v-if="isResuming">
            <h3 class="mb-2">Resume using your Service NSW account</h3>
            If you used your <strong>Service NSW account</strong>
            to begin your out-of-area application, login to resume your progress
          </span>
          <span v-else>
            <h3 class="mb-2">Use your Service NSW account to begin</h3>
            Logging in with your <strong>Service NSW account</strong>
            will allow you to start the out-of-area application, save it and
            come back later
          </span>
          <div class="mt-4">
            By using this, you will be logged into your Service NSW account.
            When you are done, please remember to go to your Service NSW account
            and log out if you are on a public computer.
          </div>
        </v-col>
        <v-col cols="12" md="2" class="login">
          <v-btn
            class="primary"
            x-large
            data-testid="continue-login"
            @click="serviceNSWLogin"
          >
            Continue
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { getGtmApplication } from '@/helpers'

export default {
  name: 'ServiceNSWLogin',
  computed: {
    isResuming() {
      return this.$store.getters.isResuming
    }
  },
  methods: {
    serviceNSWLogin() {
      // Google Analytics
      const gtmApplication = getGtmApplication(false)
      this.$gtm.trackEvent({
        event: 'interaction',
        category: 'Authentication',
        action: 'Attempt',
        label: 'ServiceNSW',
        application: gtmApplication
      })
      window.sessionStorage.setItem(
        'school',
        JSON.stringify(this.$store.getters.school)
      )
      window.sessionStorage.setItem(
        'localResidentialAddress',
        JSON.stringify(this.$store.getters.localResidentialAddress)
      )
      window.sessionStorage.setItem('isResuming', this.isResuming)
      window.sessionStorage.setItem(
        'calendarYear',
        this.$store.getters.calendarYear
      )
      this.$OAuth.getAuth()
    }
  }
}
</script>

<style lang="scss" scoped>
.headline-1 {
  font-size: 1rem; //equals 16px when root font-size is 16px.
  font-weight: 500;
  color: $color-text-body;
}
.ServiceNSWLogo {
  text-align: center;
}
.tablet.login,
.login {
  align-self: flex-end;
  direction: rtl;
}
.mobile .login {
  text-align: right;
}
</style>
