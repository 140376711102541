<template>
  <v-app
    :class="{
      mobile: $vuetify.breakpoint.smAndDown,
      tablet: $vuetify.breakpoint.mdOnly
    }"
  >
    <NavigationDrawer
      v-if="displayMobileNavDrawer"
      :color="drawerColor"
      class="pl-4 pr-4"
    >
      <div slot="preMenu" data-testid="mobile-navDrawer">
        <v-list-item class="justify-end pr-0 pl-0">
          <v-list-item-icon class="mr-0">
            <v-btn icon @click.stop="$eventHub.$emit('toggleNavigationDrawer')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <Stepper
          :steps="getSteps"
          :current-step="getCurrentStep"
          @select="selectStep"
        />
      </div>
    </NavigationDrawer>

    <AppBar
      app-name="my-nsw-gov"
      :search-filter-mutator="false"
      :show-full-screen="false"
      :show-nav-icon="displayMobileNavDrawer"
      :class="{
        contentPadding: $vuetify.breakpoint.mdAndUp,
        'app-bar--training': isTraining
      }"
      :home-link="getHomeLink"
      :enable-home-link="false"
      data-testid="app-bar"
    >
      <template
        v-if="$vuetify.breakpoint.smAndUp || !isFormRoute"
        slot="appIcon"
      >
        <a :href="nswEducationUrl" target="_blank">
          <v-img
            class="ml-3 mr-2"
            width="40"
            height="40"
            src="@/assets/logo.svg"
            alt="NSW Government"
          />
        </a>
      </template>
      <template slot="title">
        <div class="app-bar__title-container" :style="xsOnlyTitleWidth">
          <v-toolbar-title class="pl-0" :aria-label="title">
            <div class="d-flex align-center">
              <div class="app-bar--title ml-0 pl-2 mr-5">
                <h1 v-if="$vuetify.breakpoint.xsOnly" :style="xsOnlyH1Style">
                  {{ title }}
                </h1>
                <h1 v-else>{{ title }}</h1>
              </div>
              <ChipDropdown
                v-if="isTraining"
                :menu-items="trainingMenuItems"
                sub-header="Training Options"
                chip-class="training-dropdown"
              >
                <template #title>Training - OOA</template>
              </ChipDropdown>
            </div>
          </v-toolbar-title>
        </div>
      </template>
      <template v-if="showResumeBtn" slot="profile">
        <v-btn
          color="accent"
          class="resume-application--button"
          data-testid="resume-application-btn"
          @click="resumeApplication"
        >
          <v-icon left>restore</v-icon>
          Resume application
        </v-btn>
      </template>
      <template v-if="isFormRoute || isEhubLandingRoute" slot="profile">
        <v-menu data-testid="profile-menu">
          <template #activator="{ on, attrs }">
            <v-btn class="settingsBtn" v-bind="attrs" v-on="on">
              <v-icon class="material-icons-outlined">settings</v-icon>
            </v-btn>
          </template>
          <v-list>
            <div v-if="!fromEhub">
              <v-list-item>
                <v-list-item-title class="listHeading">
                  Enrolment Options
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="displayDialog">
                <v-list-item-icon class="mr-4">
                  <v-icon color="primary">mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="profile-title-font">
                  Sign out
                </v-list-item-title>
              </v-list-item>
            </div>
            <div v-else>
              <v-list-item>
                <v-list-item-title class="listHeading">
                  Enrolment Options
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToEhubDashboard">
                <v-list-item-icon class="mr-4">
                  <v-icon color="primary">mdi-arrow-left</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="profile-title-font">
                  Dashboard
                </v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item @click="logoutEhub">
                <v-list-item-icon class="mr-4">
                  <v-icon color="primary">mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="profile-title-font">
                  Log out
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </template>
    </AppBar>

    <v-main data-testid="content">
      <v-container class="pa-0" fluid>
        <v-fade-transition mode="out-in">
          <IeBlocker v-if="isIe" :block="true" :public-app="true" />
          <router-view v-if="!isIe" @trackInactivity="userActivityThrottler" />
        </v-fade-transition>
        <template v-if="showDialog">
          <ooa-dialog
            :visible="showDialog"
            title="Sign out of online enrolment?"
            max-width="520px"
            :open-dialog="showDialog"
            :display-close-btn="true"
            :actions="actionsList"
            @close="showDialog = false"
            @logout="logout"
          >
            <div slot="message">
              Are you sure you want to sign out of online enrolment? Your
              progress will be saved, and you can resume your application at any
              time.
            </div>
          </ooa-dialog>
        </template>
      </v-container>
    </v-main>

    <ooa-footer />
    <EnvironmentInfo />
  </v-app>
</template>

<script>
import {
  AppBar,
  Stepper,
  NavigationDrawer,
  IeBlocker
} from '@nswdoe/doe-ui-core'
import OoaFooter from '@/components/OoaFooter'
import EnvironmentInfo from '@/components/EnvironmentInfo'
import { isFromEhub, getGtmApplication } from '@/helpers'
import { steps, currentStep, selectStep } from '@/helpers/form/stepper'
import doeColors from '@/settings/doe-colors'
import OoaDialog from '@/components/OoaDialog'
import ChipDropdown from '@/components/ChipDropdown'
import { NSW_EDUCATION_URL } from '@/constants'
import { removeSessionItem, removeLocalStorageItem } from '@/helpers/storage'
import { getCognitoLogoutUrl, getCognitoLogoutOoaUrl } from '@/utils/general'

const INACTIVITY_TIMEOUT = process.env.VUE_APP_INACTIVITY_TIMEOUT
const INACTIVITY_THROTTLE_TIMEOUT =
  process.env.VUE_APP_INACTIVITY_THROTTLE_TIMEOUT

export default {
  name: 'App',
  components: {
    AppBar,
    Stepper,
    NavigationDrawer,
    OoaFooter,
    EnvironmentInfo,
    IeBlocker,
    ChipDropdown,
    OoaDialog
  },
  data() {
    return {
      isTraining:
        process.env.VUE_APP_ENV_NAME === 'TRAIN' ||
        (['LOCAL', 'DEV', 'TEST'].includes(process.env.VUE_APP_ENV_NAME) &&
          window.sessionStorage.getItem('trainingMode') === 'true'),
      drawerColor: doeColors.userInterface.lightgrey10,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
      isIe: false,
      showDialog: false,
      actionsList: [
        {
          name: 'Sign out',
          color: '#041E42',
          size: 'large',
          outlined: false,
          btnText: false,
          action: 'logout'
        }
      ],
      nswEducationUrl: NSW_EDUCATION_URL
    }
  },
  computed: {
    school() {
      return this.$store.getters.school
    },
    showResumeBtn() {
      if (this.$route.name === 'home' && !this.isIe) return true
      return false
    },
    title() {
      if (!this.isFormRoute && this.school && this.$vuetify.breakpoint.mdAndUp)
        return `Online Enrolment - ${this.school.schoolName}`
      if (!this.isFormRoute && this.$vuetify.breakpoint.smAndUp)
        return 'Online Enrolment'
      if (!this.isFormRoute && this.$vuetify.breakpoint.xsOnly) return ''
      if (this.isFormRoute && this.school && this.$vuetify.breakpoint.mdAndUp)
        return `Out-of-Area Enrolment Application - ${this.school.schoolName}`
      if (this.isFormRoute && this.school && this.$vuetify.breakpoint.smAndUp)
        return 'Out-of-Area Enrolment'
      if (this.isFormRoute && this.school && this.$vuetify.breakpoint.xsOnly)
        return this.school.schoolName
      return 'Out-of-Area Enrolment'
    },
    getSteps() {
      return steps()
    },
    getCurrentStep() {
      return currentStep()
    },
    displayMobileNavDrawer() {
      return this.$vuetify.breakpoint.smAndDown && this.isFormRoute
    },
    isFormRoute() {
      return this.$route.name === 'form'
    },
    isEhubLandingRoute() {
      return this.$route.name === 'ehubLanding'
    },
    getHomeLink() {
      const schoolCode = window.sessionStorage.getItem('schoolCode')
      return schoolCode == null ? '' : `/?schoolCode=${schoolCode}`
    },
    oAuthLoaded() {
      return this.$OAuth.loaded
    },
    trainingMenuItems() {
      const schoolCode = this.school && this.school.schoolCode
      const catchmentLevel = this.school && this.school.catchmentLevel
      return [
        {
          text: 'Training - Local area enrolment (Parent Interface)',
          externalLink: `${process.env.VUE_APP_PI_CORE_TRAIN_BASE_URL}/?schoolCode=${schoolCode}&catchmentLevel=${catchmentLevel}`
        },
        {
          text: 'Training - Out of area enrolment (Parent Interface)',
          selected: true,
          disabled: true
        },
        {
          text: 'Training - Moving to high school (Parent Interface)',
          externalLink: `${process.env.VUE_APP_Y67T_CORE_TRAIN_BASE_URL}/?schoolCode=${schoolCode}`
        },
        {
          text: 'Training - SI (School Interface)',
          externalLink: process.env.VUE_APP_SI_TRAIN_BASE_URL
        }
      ]
    },
    fromEhub() {
      return isFromEhub()
    },
    xsOnlyTitleWidth() {
      return { 'max-width': this.$vuetify.breakpoint.xsOnly ? '63%' : '70%' }
    },
    xsOnlyH1Style() {
      return {
        'white-space': 'nowrap',
        overflow: 'hidden',
        'text-overflow': 'ellipsis'
      }
    }
  },
  watch: {
    oAuthLoaded: {
      immediate: true,
      handler(val) {
        if (val) {
          const gtmApplication = getGtmApplication(false)
          // Google analytics
          this.$gtm.trackEvent({
            event: 'interaction',
            category: 'Authentication',
            action: 'Success',
            label: 'ServiceNSW',
            application: gtmApplication
          })
        }
      }
    }
  },
  beforeMount() {
    this.activateActivityTracker()
  },
  beforeDestroy() {
    this.$log.debug('in beforeDestroy, deactivating the activity tracker')
    this.deactivateActivityTracker()
    clearTimeout(this.userActivityTimeout)
    clearTimeout(this.userActivityThrottlerTimeout)
  },
  created() {
    if (IeBlocker.isIe()) {
      this.isIe = true
    }
  },
  methods: {
    resumeApplication() {
      this.$store.commit('setIsResuming', true)
      // TODO: Determine whether we should detect auth state & bypass login
      this.$router.push({ name: 'login' })
    },
    selectStep(step) {
      selectStep(step)
    },
    // User In-activity timeout functions. Description of what happens follows:
    // 1. When the SPA is loaded by a client, we register a set of events to listen for that
    // determine whether a user has interacted with the application or not.
    // 2. When the user authenticates by providing a valid OTP we start tracking the session
    // inactivity by setting a timeout period of 60 min using the Window setTimeout() method.
    // 3. Each time the user triggers one of the registered events, we reset the remaining time back
    // to 60 min by firstly using the Window clearTimeout() method then re-setting the timeout.
    // 4. We throttle how often the timer is reset so as not to overwhelm the client. i.e. track
    // necessary events, but only reset the timeout every X seconds (currently set to 1sec).
    // If we were to enable mousemove events, we'd probably need to increase the throttle timeout.
    activateActivityTracker() {
      window.addEventListener('keydown', this.userActivityThrottler)
      // window.addEventListener('mousemove', this.userActivityThrottler)
      // window.addEventListener('scroll', this.userActivityThrottler)
      // window.addEventListener('resize', this.userActivityThrottler)
    },
    deactivateActivityTracker() {
      window.removeEventListener('keydown', this.userActivityThrottler)
      // window.removeEventListener('mousemove', this.userActivityThrottler)
      // window.removeEventListener('scroll', this.userActivityThrottler)
      // window.removeEventListener('resize', this.userActivityThrottler)
    },
    resetUserActivityTimeout() {
      this.$log.debug('in resetUserActivityTimeout')
      // reset the user activity timeout due to one of the listener events being triggered.
      clearTimeout(this.userActivityTimeout)
      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction()
      }, INACTIVITY_TIMEOUT)
    },
    userActivityThrottler() {
      // Only execute the resetTimerActivity once the user is authenticated and the
      // isUserActive Flag has been set to true.
      if (
        !this.userActivityThrottlerTimeout &&
        this.$store.getters.isUserActive
      ) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout()
          clearTimeout(this.userActivityThrottlerTimeout)
          this.userActivityThrottlerTimeout = null
        }, INACTIVITY_THROTTLE_TIMEOUT)
      }
    },
    inactiveUserAction() {
      this.$log.info('User is now inactive, timing out user session')
      this.$store.dispatch('inactivateSession').then(() => {
        // FUS-582 Don't timeout after an application was just created
        if (!this.$store.getters.applicationId) {
          this.$router.push({ name: 'timeout' })
        }
      })
    },
    logout() {
      this.showDialog = false
      this.$store.dispatch('updateApplication').then(() => {
        this.$store.dispatch('logout')
        this.$store.commit('setIsLoggedOut', true)
        // this.$router.push({ name: 'logout' })
        window.location.href = getCognitoLogoutOoaUrl()
      })
    },
    displayDialog() {
      this.showDialog = true
    },
    logoutEhub() {
      // eslint-disable-next-line no-console
      console.log('logoutEhub')
      this.$store.dispatch('updateApplication').then(() => {
        this.$store.dispatch('logout')
        this.$store.commit('setIsLoggedOut', true)
        removeSessionItem('idToken')
        removeSessionItem('refreshToken')
        removeLocalStorageItem('id_token')
        removeLocalStorageItem('refresh_token')
        // eslint-disable-next-line no-console
        console.log('Remove session and local storage')
        // window.location.href = `${window.location.origin}/logout`
        window.location.href = getCognitoLogoutUrl()
      })
    },
    goToEhubDashboard() {
      window.location.href = `${window.location.origin}/dashboard`
    }
  }
}
</script>
<style lang="scss">
@import '@/scss/app.scss';
.contentPadding {
  padding: 0 120px;
}
.tablet .contentPadding {
  padding: 0 20px;
}
.mobile .contentPadding {
  padding: 0 10px;
}
.mobile .v-toolbar__title {
  font-size: 16px !important;
  span > span {
    font-weight: 500 !important;
  }
}
.ie-blocker--container {
  position: relative !important;
  .ie-blocker--not-compatible-text {
    color: $ads-navy;
  }
  .v-icon {
    color: $ads-navy;
  }
}
.v-btn.settingsBtn.theme--dark.v-size--default {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  min-width: 40px;
  padding: 0px;
  background-color: $ads-light-blue !important;
  .v-btn__content .v-icon {
    color: $ads-navy;
  }
}
.listHeading {
  font-weight: bold;
  color: $ads-dark;
}
</style>

<style scoped lang="scss">
::v-deep .v-btn.resume-application--button {
  height: 39px;
  font-size: 0.875rem;
}

.v-application header.v-app-bar {
  background-color: $ads-navy !important;
  border-color: $ads-navy !important;
  &.app-bar--training {
    background-color: $ads-error-red !important;
    border-color: $ads-error-red !important;
  }
}

.app-bar__title-container {
  .app-bar--title {
    max-width: 100%;
    vertical-align: middle;
    line-height: 40px;
    h1 {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }
}
</style>
