<template>
  <header class="banner" :class="getCls" data-testid="application-banner">
    <v-container fluid :class="containerCls">
      <slot />
    </v-container>
  </header>
</template>

<script>
export default {
  name: 'BannerComponent',
  props: {
    compact: {
      type: Boolean
    }
  },
  computed: {
    getCls() {
      return {
        compact: this.compact,
        'py-12': !this.compact && !this.$vuetify.breakpoint.smAndDown,
        'pt-10 pb-2': this.compact && !this.$vuetify.breakpoint.smAndDown,
        'py-6': this.$vuetify.breakpoint.smAndDown
      }
    },
    containerCls() {
      if (this.compact) {
        return 'contentPadding py-0'
      }
      return 'ooaContent py-0'
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  margin-top: 1px;
  background-image: url(../assets/bg-banner@3x.png);
  background-position: right top;
  background-size: auto 218px;
  background-repeat: no-repeat;
  background-color: $ads-navy;
  color: $ads-white;

  h2 {
    font-size: 3.125rem;
    line-height: 1;
  }

  p {
    font-size: 1.375rem;
    line-height: 1.25;
  }
}

.banner.compact {
  h2 {
    font-size: 2.5rem;
    line-height: 1.3;
  }
}

.mobile .banner {
  background-size: auto 100%;

  h2 {
    font-size: 1.125rem;
  }

  p {
    font-size: 0.875rem;
  }
}
</style>
