import axios from 'axios'
import store from '@/store'

const API_BASE = process.env.VUE_APP_PARENTID_ENROL

const initiateAuth = (userId, recaptchaToken) => {
  const url = `${API_BASE}/passwordless/initiateAuth`

  return axios
    .post(url, { userId, recaptchaToken })
    .then((response) => response.data.body)
    .catch((error) => {
      store.dispatch('setAPIError', { error, fallbackCode: 'PILIA' })
      throw error
    })
}

const authenticate = (otpCode, cognitoSession, userId) => {
  const url = `${API_BASE}/passwordless/authenticate`

  return axios
    .post(url, { code: otpCode, cognitoSession, userId })
    .then((response) => response.data.body)
    .catch((error) => {
      store.commit('setError', error)
      throw error
    })
}

const refreshToken = (refreshToken, userId) => {
  const url = `${API_BASE}/passwordless/refreshToken`

  return axios
    .post(url, { userId, refreshToken })
    .then((response) => response.data)
    .catch((error) => {
      store.commit('setError', error)
      throw error
    })
}

export default { initiateAuth, authenticate, refreshToken }
