<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0" aria-live="polite">
    <p class="pb-3">
      Please check the details of your application carefully. You can still
      change anything before you submit it.
    </p>
    <div v-if="showExpandBtn" data-testid="expand-btn" class="text-right pb-3">
      <v-btn class="textBtn" text @click="expandAll"
        ><v-icon class="pr-4">expand_less</v-icon>Expand All</v-btn
      >
    </div>
    <div
      v-if="showCollapseBtn"
      data-testid="collaps-btn"
      class="text-right pb-3"
    >
      <v-btn class="textBtn" text @click="collapseAll"
        ><v-icon class="pr-4">expand_more</v-icon>Collapse All</v-btn
      >
    </div>

    <AdsExpansionPanel
      v-model="expandedPanels"
      :items="panels"
      data-initial-focus
      multiple
    >
      <template slot="content1">
        <span class="editBtn pb-4"
          ><v-btn
            text
            data-testid="edit-student-details-btn"
            @click="editStudentDetails"
            ><v-icon class="material-icons-outlined pr-1">edit</v-icon
            >Edit</v-btn
          ></span
        >
        <StudentView />
      </template>
      <template slot="content2">
        <span class="editBtn pb-4"
          ><v-btn
            text
            data-testid="edit-parent/carer-details-btn"
            @click="editParentcarerDetails"
            ><v-icon class="material-icons-outlined pr-1">edit</v-icon
            >Edit</v-btn
          ></span
        >
        <ParentCarerView />
      </template>
      <template v-if="displayConsiderationsPanel" slot="content3">
        <span class="editBtn pb-4"
          ><v-btn
            text
            data-testid="edit-considerations-btn"
            @click="editConsiderationsDetails"
            ><v-icon class="material-icons-outlined pr-1">edit</v-icon
            >Edit</v-btn
          ></span
        >
        <ConsiderationsView />
      </template>
    </AdsExpansionPanel>

    <v-card class="pa-6 mt-6 mb-6" data-testid="declaration-section">
      <h2 class="pb-3">Declaration</h2>
      <div class="declarationText">
        <p>
          I have read and understand the information in this expression of
          interest including about the collection of personal information,
          publishing student information and student access to the department's
          network and learning applications.
        </p>

        <p>
          Where I have given personal information about people other than myself
          or my child(ren) I have done so with their authorisation.
        </p>

        <p>
          I am aware that if information I have given is false or misleading,
          any decision made as a result of this application may be changed. I
          understand that any enrolments following this placement process will
          be subject to consideration and acceptance of an Application to enrol
          in a NSW Government school.
        </p>

        <p>
          I consent to the school/Department of Education seeking information
          from previous schools, other NSW government departments, public
          hospitals, health professionals or other organisations that may also
          hold information related to this assessment for the student named in
          the "Student details" section.
        </p>

        <p class="navy">
          <strong>Please complete the declaration below</strong>
        </p>

        <v-checkbox
          v-model="declarationCheckbox"
          :disabled="!formValid"
          class="priority"
          data-testid="declaration-checkbox"
        >
          <template #label>
            <div class="declaration-label">
              I,
              <b>{{
                parentCarerCompleting.displayFullname
                  ? parentCarerCompleting.displayFullname
                  : '(empty)'
              }}</b>
              of
              <b>{{
                parentCarerCompleting.email
                  ? parentCarerCompleting.email
                  : '(empty)'
              }}</b>
              declare that the information provided in this application is, to
              the best of my knowledge and belief, accurate and complete.
            </div>
          </template>
        </v-checkbox>
      </div>
    </v-card>
    <div class="d-flex justify-end mt-6">
      <v-btn
        color="primary"
        :disabled="!canSubmit"
        x-large
        data-testid="submit-application-btn"
        @click="
          enableLoader()
          submitApplication()
        "
      >
        <ButtonSpinner :loading="loading" class="mr-2" />
        Submit Application
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import ButtonSpinner from '@/components/ButtonSpinner'
import { AdsExpansionPanel } from '@nswdoe/doe-ui-core'
import StudentView from '@/views/form/Review/StudentView'
import ConsiderationsView from '@/views/form/Review/ConsiderationsView'
import ParentCarerView from '@/views/form/Review/ParentCarerView'
import { mapGetters } from 'vuex'
import { selectStep } from '@/helpers/form/stepper'
import { getGtmApplication } from '@/helpers'
import Application from '@/api/server/application'
import moment from 'moment'
import handleFirstFocus from '@/helpers/form/focus'

const valueOrDefaultTrue = (val) => val == null || val

export default {
  name: 'FormReview',
  components: {
    ButtonSpinner,
    AdsExpansionPanel,
    StudentView,
    ParentCarerView,
    ConsiderationsView
  },
  data() {
    return {
      loading: false,
      expandedPanels: [0, 1, 2],
      showExpandBtn: false,
      showCollapseBtn: true,
      displayBtn: true,
      declarationCheckbox: false,
      parentCarerCompleting: {},
      formSubmitted: false
    }
  },
  computed: {
    ...mapGetters([
      'form',
      'schoolData',
      'validStudent',
      'validParentCarers',
      'validConsiderations',
      'isReviewed',
      'viaEhub',
      'isEhubLinkedStudent',
      'timestamp',
      'ehubFormData'
    ]),
    hasSupportingDocuments() {
      return this.form.criteria?.some((c) => c.code === 'LAW')
    },
    panels() {
      const errorProps = { headerClass: 'sectionError', icon: 'error_outline' }
      const sections = [
        { ...(this.validStudent ? {} : errorProps), title: 'Student details' },
        {
          ...(this.validParentCarers ? {} : errorProps),
          title: 'Parent/carer details'
        }
      ]

      if (this.displayConsiderationsPanel) {
        // valid siblings is null/undefined if no siblings
        sections.push({
          ...(this.validConsiderations ? {} : errorProps),
          title: 'Considerations'
        })
      }

      return sections
    },
    displayConsiderationsPanel() {
      const hasSchoolSpecificConsiderations =
        this.form.criteria && this.form.criteria.length
      const hasOtherConsiderations = this.form.otherConsiderations

      return hasSchoolSpecificConsiderations || hasOtherConsiderations
    },
    formValid() {
      return (
        valueOrDefaultTrue(this.validStudent) &&
        valueOrDefaultTrue(this.validParentCarers) &&
        valueOrDefaultTrue(this.validConsiderations)
      )
    },
    canSubmit() {
      return this.declarationCheckbox && this.formValid && !this.formSubmitted
    }
  },
  created() {
    if (this.form.parentCarers && this.form.parentCarers.length > 0) {
      this.form.parentCarers.forEach((parent) => {
        if (
          parent.parentCarerCompletingApp !== null &&
          parent.parentCarerCompletingApp
        ) {
          if (parent.parentCarerGivenName && parent.parentCarerFamilyName) {
            const item = `${parent.parentCarerGivenName} ${parent.parentCarerFamilyName}`
            this.parentCarerCompleting.displayFullname = item
          }
          this.parentCarerCompleting.email = parent.parentCarerEmail
        }
      })
    }
    this.$store.commit('setIsReviewed', true)
  },
  mounted() {
    handleFirstFocus()
  },
  methods: {
    enableLoader() {
      this.loading = true
    },
    disableLoader() {
      this.loading = false
    },
    expandAll() {
      this.showExpandBtn = false
      this.showCollapseBtn = true
      this.expandedPanels = [0, 1, 2, 3]
    },
    collapseAll() {
      this.showExpandBtn = true
      this.showCollapseBtn = false
      this.expandedPanels = []
    },
    editStudentDetails() {
      selectStep(1)
    },
    editParentcarerDetails() {
      selectStep(2)
    },
    editConsiderationsDetails() {
      selectStep(3)
    },
    cleanUpSupportingDocs() {
      if (
        !this.hasSupportingDocuments &&
        this.form.supportingDocuments?.LAW.length > 0
      ) {
        // removes all supported docs from s3 when submitted with LAW criteria unchecked
        const files = this.form.supportingDocuments.LAW
        // eslint-disable-next-line no-restricted-syntax
        for (const f of files) {
          this.deleteSupportingDocument(f, 'LAW')
        }
      }
    },
    deleteSupportingDocument(file, criteria) {
      this.$store
        .dispatch('deleteSupportingDocument', {
          file,
          category: criteria,
          path: this.timestamp
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
        })
    },
    async submitApplication() {
      try {
        this.formSubmitted = true
        const hasSiblingCriteria = this.form.criteria?.some(
          (criteria) => criteria.code === 'SIB'
        )
        const numberOfSiblings = this.form.siblings
          ? this.form.siblings.length
          : 0
        const studentDetails = {
          dateOfBirth: moment(
            this.form.student.dateOfBirth,
            'DD-MM-YYYY'
          ).format('YYYY-MM-DD'),
          firstName: this.form.student.firstName,
          prefFirstName: this.form.student.prefFirstName,
          genderCode: this.form.student.genderCode,
          otherName: this.form.student.otherName,
          familyName: this.form.student.familyName,
          srn:
            this.isEhubLinkedStudent && this.ehubFormData?.child?.srn
              ? this.ehubFormData.child.srn
              : this.form.student.srn
        }
        const residentialAddressDetails = {
          addressLine1: this.form.residentialAddress.addressLine1,
          countryCode: this.form.residentialAddress.countryCode,
          postCode: this.form.residentialAddress.postCode,
          stateCode: this.form.residentialAddress.stateCode,
          suburbName: this.form.residentialAddress.suburbName
        }
        let previousSchoolDetails = null
        if (this.form.otherStudentDetails.prevAttendSchool === 'Yes') {
          // remove suburb from prevSchoolName and prevSchoolId
          let prevSchoolName = this.form.otherStudentDetails.prevSchool
            ? this.form.otherStudentDetails.prevSchool.text
            : ''
          // check for index of '('
          if (prevSchoolName.indexOf('(') >= 0) {
            prevSchoolName = prevSchoolName
              .substring(0, prevSchoolName.indexOf('('))
              .trim()
          }
          previousSchoolDetails = {
            prevSchoolLastDate: moment(
              `01-${this.form.otherStudentDetails.prevSchoolLastDate}`,
              'DD-MM-YYYY'
            ).format('YYYY-MM-DD'),
            prevSchoolStartDate: moment(
              `01-${this.form.otherStudentDetails.prevSchoolStartDate}`,
              'DD-MM-YYYY'
            ).format('YYYY-MM-DD'),
            prevSchoolType:
              this.form.otherStudentDetails.prevSchoolType === 'OVS'
                ? 'O'
                : 'A',
            prevSchoolTypeValue: this.form.otherStudentDetails.prevSchoolType,
            prevSchoolId: this.form.otherStudentDetails.prevSchool
              ? `${this.form.otherStudentDetails.prevSchool.value}|${prevSchoolName}`
              : null,
            prevSchoolName:
              this.form.otherStudentDetails.prevSchoolType === 'OVS'
                ? this.form.otherStudentDetails.otherSchoolAttendedName
                : prevSchoolName,
            otherSchoolAttendedLocation:
              this.form.otherStudentDetails.otherSchoolAttendedLocation
          }
        }
        const siblings =
          this.form.siblings?.map((sibling) => {
            const siblingItem = {}
            siblingItem.siblingsFirstName = sibling.siblingsFirstName
            siblingItem.siblingsOtherName = sibling.siblingsOtherName
            siblingItem.siblingsFamilyName = sibling.siblingsFamilyName
            siblingItem.siblingsGenderCode = sibling.siblingsGenderCode
            siblingItem.siblingsDOB = moment(
              sibling.siblingsDOB,
              'DD-MM-YYYY'
            ).format('YYYY-MM-DD')
            return siblingItem
          }) || []

        const data = {
          schoolCode: this.form.schoolCode,
          schoolName: this.form.schoolName,
          catchmentLevel: this.form.catchmentLevel,
          residencyStatus: this.form.residencyStatus,
          student: studentDetails,
          intendedStartDate:
            this.form.otherStudentDetails.intendedStartDate ===
            'Choose a specific start date'
              ? moment(
                  this.form.otherStudentDetails.enterStartDate,
                  'DD-MM-YYYY'
                ).format('YYYY-MM-DD')
              : this.form.otherStudentDetails.intendedStartDate,
          intendedStartDatePrint:
            this.form.otherStudentDetails.intendedStartDate ===
            'Choose a specific start date'
              ? ''
              : this.form.otherStudentDetails.intendedStartDatePrint,
          enterStartDateSelected:
            this.form.otherStudentDetails.intendedStartDate ===
            'Choose a specific start date',
          scholasticYear: this.form.otherStudentDetails.scholasticYear,
          prevAttendSchool:
            this.form.otherStudentDetails.prevAttendSchool === 'Yes',
          previousSchool: previousSchoolDetails,
          residentialAddress: residentialAddressDetails,
          parentCarers: this.form.parentCarers,
          noOfSiblings: hasSiblingCriteria ? numberOfSiblings : 0,
          supportingDocuments: this.hasSupportingDocuments
            ? this.form.supportingDocuments
            : [],
          siblings: hasSiblingCriteria ? siblings : [],
          criteria: this.form.criteria,
          otherConsiderations: this.form.otherConsiderations,
          declaredBy: this.parentCarerCompleting.displayFullname,
          submittedTime: new Date().toISOString(),
          applicationStatus: 'New',
          viaEhub: this.viaEhub,
          isEhubLinkedStudent: this.isEhubLinkedStudent
        }
        this.cleanUpSupportingDocs()

        // Google Analytics
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'Form Attempt',
          action: 'submit',
          label: 'ooa',
          application: getGtmApplication(this.viaEhub)
        })

        await Application.submitApplication(data).then((resp) => {
          this.$store.commit('setApplicationId', resp.body.applicationID)
          this.$router.push({ name: 'confirmation' })
        })
        if (
          data.supportingDocuments &&
          data.supportingDocuments.LAW &&
          data.supportingDocuments.LAW.length > 0
        ) {
          const gtmApplication = getGtmApplication(this.viaEhub)
          this.$gtm.trackEvent({
            category: 'Document Upload',
            action: 'Added',
            label: 'Medical Reasons',
            value: data.supportingDocuments.LAW.length,
            application: gtmApplication
          })
        }
      } finally {
        this.disableLoader()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-icon,
::v-deep .theme--light.v-btn.textBtn .v-btn__content,
::v-deep
  .theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon,
h2,
.navy {
  color: $color-text-body;
}
::v-deep .theme--light.v-btn.textBtn {
  padding: 0;
}
::v-deep .v-item-group.v-expansion-panels {
  .v-expansion-panel-header {
    font-size: 18px;
    color: $color-text-body;
    font-weight: bold;
    background-color: $ads-light-10;
    padding: 18px 38px;
    &:before {
      background-color: transparent;
    }
    .subtitle-1 {
      font-weight: bold;
      font-size: 1.125rem !important;
    }
  }
  .v-expansion-panel-header.v-expansion-panel-header--active {
    background-color: $ads-light-10;
  }
  .v-expansion-panel-content__wrap {
    padding: 24px;
  }
  .editBtn {
    font-size: 16px;
    display: none;
    font-weight: bold;
  }
  .editBtn .v-btn__content {
    font-size: 16px;
    color: $color-text-body;
  }

  .selectMenu {
    width: 60%;
  }

  .v-expansion-panel.v-expansion-panel--active.v-item--active .editBtn {
    display: inline-block;
  }

  .v-expansion-panel-header.sectionError {
    span i {
      color: $ads-error-red;
    }
    span {
      color: $ads-error-red;
      font-weight: bold;
    }
  }

  .v-expansion-panel--active + .v-expansion-panel,
  .v-expansion-panel--active:not(:first-child) {
    margin-top: 0;
  }
}

::v-deep
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active.v-expansion-panel {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

::v-deep
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active {
  border-radius: 0;
}

.declaration-label {
  word-break: break-word;
}

.declarationText {
  font-size: 14px;
  color: $ads-dark-70;
  font-weight: 500;
}

::v-deep
  div.v-item-group.theme--light.v-expansion-panels.v-expansion-panels--accordion.v-expansion-panels--focusable
  > div:nth-child(1)
  > button:focus {
  outline: $ads-light-blue auto 1px !important;
}
</style>
