/* eslint-disable no-param-reassign */
import axios from 'axios'

const documentUploadModule = {
  state: {
    timestamp: null
  },
  getters: {
    timestamp: (state) => state.timestamp
  },
  mutations: {
    setTimestamp(state, timestamp) {
      state.timestamp = timestamp
    }
  },
  actions: {
    getSignedUrl(_, { path, file, category, action, previewMode }) {
      const cleanedPath = path ? path.toString().replace(/^\/|\/$/g, '') : null

      return axios
        .post(
          `${process.env.VUE_APP_API_BASE}/application/self/uploads/generateSignedUrl`,
          {
            filename: cleanedPath ? `${cleanedPath}/${file.name}` : file.name,
            filetype: file.type,
            category,
            action,
            previewMode
          },
          true
        )
        .then((resp) =>
          resp && resp.data && resp.data.body ? resp.data.body.Url : null
        )
    },
    async uploadSupportingDocument(
      { dispatch },
      { file, path, category, progressCallback }
    ) {
      const signedUrl = await dispatch('getSignedUrl', {
        path,
        file,
        category,
        action: 'UPLOAD'
      })

      const axiosConfig = {
        onUploadProgress: progressCallback
      }

      await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type
        },
        ...axiosConfig
      })

      // extract out the path "https://domain.com/path/to/the/file.pdf?params=abc" => "path/to/the/file.pdf"
      // can't use new URL().pathname because IE11 is a baby
      const filePath = signedUrl.match(/https:\/\/[a-zA-Z0-9.\-_]+\/(.*)\?/)[1]
      return decodeURIComponent(filePath)
    },
    async deleteSupportingDocument({ dispatch }, { file, path, category }) {
      const signedUrl = await dispatch('getSignedUrl', {
        path,
        file,
        category,
        action: 'REMOVE'
      })

      return axios.delete(signedUrl)
    }
  }
}

export default documentUploadModule
