/* eslint-disable no-param-reassign */

import refDataApi from 'api-client/ReferenceData'

const refDataModule = {
  state: {
    states: null,
    residencyStatuses: null,
    scholasticYears: null,
    termDates: null,
    relationships: null,
    titles: null,
    contactTypes: null,
    countries: null,
    predictiveSchools: null,
    oesProperties: null
  },
  getters: {
    states: (state) => state.states,
    residencyStatuses: (state) => state.residencyStatuses,
    scholasticYears: (state) => state.scholasticYears,
    termDates: (state) => state.termDates,
    relationships: (state) => state.relationships,
    titles: (state) => state.titles,
    contactTypes: (state) => state.contactTypes,
    countries: (state) => state.countries,
    predictiveSchools: (state) => state.predictiveSchools,
    oesProperties: (state) => state.oesProperties
  },
  mutations: {
    setStates(state, states) {
      state.states = states
    },
    setResidencyStatuses(state, residencyStatuses) {
      state.residencyStatuses = residencyStatuses
    },
    setScholasticYears(state, scholasticYears) {
      state.scholasticYears = scholasticYears
    },
    setTermDates(state, termDates) {
      state.termDates = termDates
    },
    setRelationships(state, relationships) {
      state.relationships = relationships
    },
    setTitles(state, titles) {
      state.titles = titles
    },
    setContactTypes(state, contactTypes) {
      state.contactTypes = contactTypes
    },
    setCountries(state, countries) {
      state.countries = countries
    },
    setPredictiveSchools(state, schools) {
      state.predictiveSchools = schools
    },
    setOESProperties(state, properties) {
      state.oesProperties = properties
    }
  },
  actions: {
    async getReferenceData({ commit }) {
      await refDataApi.getReferenceData().then((resp) => {
        if (resp) {
          let states = resp.find((item) => item.name === 'States').data
          // Remap from retrieved API key/vals to useable key/vals
          states = states.map((child) => ({
            text: child.value,
            value: child.code
          }))
          commit('setStates', states)

          let residencyStatuses = resp.find(
            (item) => item.name === 'ResidencyStatus'
          ).data
          // Remap from retrieved API key/vals to useable key/vals
          residencyStatuses = residencyStatuses.map((child) => ({
            text: child.code === 'TMP' ? 'Temporary Resident' : child.value,
            value: child.code
          }))
          commit('setResidencyStatuses', residencyStatuses)

          let relationships = resp.find(
            (item) => item.name === 'Relationship'
          ).data
          // Remap from retrieved API key/vals to useable key/vals
          relationships = relationships
            .filter((child) => child.active === 'A')
            .map((child) => ({
              text: child.value,
              value: child.code
            }))
          commit('setRelationships', relationships)

          let titles = resp.find((item) => item.name === 'Title').data
          // Remap from retrieved API key/vals to useable key/vals
          titles = titles
            .filter((child) => child.active === 'A')
            .map((child) => ({
              text: child.value,
              value: child.code
            }))
          commit('setTitles', titles)

          let contactTypes = resp.find(
            (item) => item.name === 'ContactType'
          ).data
          // Remap from retrieved API key/vals to useable key/vals
          contactTypes = contactTypes
            .filter((child) => child.active === 'A')
            .map((child) => ({
              text: child.value,
              value: child.code
            }))
          commit('setContactTypes', contactTypes)

          let countries = resp.find((item) => item.name === 'Countries').data
          // Remap from retrieved API key/vals to usable key/vals
          countries = countries
            .filter((child) => child.active === 'A')
            .map((child) => ({
              text: child.value,
              value: child.code
            }))
          commit('setCountries', countries)
        }
      })
    },
    async getScholasticYears({ commit }, { schoolCode }) {
      await refDataApi.getScholasticYears(schoolCode).then((resp) => {
        if (resp) {
          const scholasticYears = {}
          resp.forEach((item) => {
            scholasticYears[item.calenderYear] = item.scholasticYears.map(
              (child) => ({
                text: child.schoolYearName,
                value: child.schoolYearCode
              })
            )
          })
          commit('setScholasticYears', scholasticYears)
        }
      })
    },
    getTermDates({ commit }) {
      return refDataApi.getTermDates().then((resp) => {
        commit('setTermDates', resp)
      })
    },
    async initReferenceData({ dispatch, state, rootState }, schoolCode) {
      const refData =
        state.states === null ||
        state.residencyStatuses === null ||
        state.relationships === null ||
        state.titles === null ||
        state.contactTypes === null
      await Promise.all([
        refData ? dispatch('getReferenceData') : null,
        state.scholasticYears === null
          ? dispatch('getScholasticYears', { schoolCode })
          : null,
        rootState.school.schoolData === null
          ? dispatch('getSchoolCustomisations', schoolCode)
          : null,
        state.termDates === null ? dispatch('getTermDates') : null
      ])
    },
    async getPredictiveSchools({ commit }) {
      await refDataApi.getPredictiveSchools().then((resp) => {
        commit('setPredictiveSchools', resp)
      })
    },
    async getOESProperties({ commit }) {
      await refDataApi.getOESProperties().then((resp) => {
        commit('setOESProperties', resp)
      })
    }
  }
}

export default refDataModule
