<template>
  <div class="file-upload">
    <v-file-input
      ref="fileInput"
      :accept="acceptedFileTypes"
      class="file-input"
      :value="files"
      :multiple="maxFileCount > 0"
      :clearable="false"
      :placeholder="placeholder"
      :messages="uploadingMessage"
      v-bind="attrs"
      aria-describedby="fileUploadRestrictions"
      @change="onChange"
      v-on="$listeners"
      persistent-placeholder
    >
      <template #prepend-inner>
        <div class="v-input__icon v-input__icon--prepend-inner">
          <v-icon
            class="file-input__upload-icon"
            aria-label="upload file"
            icon
            @click="$refs.fileInput.$refs.input.click()"
          >
            mdi-paperclip
          </v-icon>
        </div>
      </template>
      <template #selection="{ text, file }">
        <v-chip
          class="file-chip"
          :class="{ 'file-chip--removable': !isFileProcessing(file) }"
          small
          :color="isFileUploading(file) ? 'transparent' : 'grey lighten-5'"
        >
          {{ shortenChipText(text) }}
          <v-icon
            v-if="!isFileProcessing(file)"
            class="file-chip__close-btn"
            :aria-label="`Remove file ${file.name}`"
            small
            @click.stop="onDelete(file)"
          >
            mdi-close
          </v-icon>
        </v-chip>
      </template>
      <template #message="{ message }">
        <div>
          <div class="progress-wrapper">
            <v-progress-linear
              v-if="isUploading || isRemoving || isInvalid"
              class="mb-3"
              color="primary lighten-1"
              background-color="primary lighten-4"
              :indeterminate="(isInvalid || isRemoving) && !isUploading"
              :value="progress"
            />
          </div>
          <div>{{ message }}</div>
        </div>
      </template>
    </v-file-input>
    <div id="fileUploadRestrictions">
      <span v-if="hint">{{ hint }}</span>
    </div>

    <v-dialog v-model="showOverwriteConfirmation" max-width="500px">
      <v-card
        class="px-3 pb-3"
        role="alert"
        aria-labelledby="dialogTitle"
        aria-describedby="dialogDesc"
        aria-live="assertive"
      >
        <div class="d-flex align-center ml-3 mb-6">
          <FeatureIcon
            class="mt-10"
            size="40px"
            icon="mdi-file-replace-outline"
          />
          <h3 id="dialogTitle" class="overwriteModalTitle mt-10 ml-3">
            Files already uploaded
          </h3>
          <v-spacer />
          <v-btn
            icon
            aria-label="close"
            @click="handleOverwriteConfirmation('cancel')"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div id="dialogDesc" class="my-3 mx-4">
          <p>
            One or more of the files you have chosen have already been uploaded.
            If you select <strong>Proceed</strong> the following file(s) will be
            overwritten:
          </p>
          <div
            v-for="filename in overwriteFiles"
            :key="filename"
            class="font-weight-bold"
          >
            {{ filename }}
          </div>
        </div>
        <v-card-actions>
          <v-spacer />
          <div class="d-flex justify-end">
            <AdsButton
              tertiary
              button-text="Cancel"
              @click="handleOverwriteConfirmation('cancel')"
            />
            <AdsButton
              button-text="Proceed"
              data-testid="overwriteConfirmationButton"
              @click="handleOverwriteConfirmation('overwrite')"
            />
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { FILE_UPLOAD_ERROR_TYPES } from '@/constants'
// import { isIe } from '@/helpers/generalUtils'
import { FeatureIcon, AdsButton } from '@nswdoe/doe-ui-core'

const UPLOAD_STATUSES = {
  UPLOADING: 'UPLOADING',
  REMOVING: 'REMOVING',
  COMPLETE: 'COMPLETE',
  AWAITING_GROUP: 'AWAITING_GROUP'
}

const ONE_MB_IN_BYTES = 1048576

export default {
  name: 'FileUpload',
  components: {
    FeatureIcon,
    AdsButton
  },
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    fileNameRegEx: {
      type: RegExp,
      default: () => /^[0-9a-zA-Z!\-_*.'() .]+$/
    },
    maxFileCount: {
      type: Number,
      default: null
    },
    maxFileSizeMb: {
      type: Number,
      default: null
    },
    mimeTypes: {
      type: String,
      default: null
    },
    hint: {
      type: String
    }
  },
  data() {
    const files = this.value ? [...this.value] : []
    return {
      // isIe: isIe(),
      files, // array of selected files' metadata. Don't push actual file objects into this object otherwise onChange doesn't trigger consistently
      invalidCount: 0,
      maxFileSize: this.maxFileSizeMb * ONE_MB_IN_BYTES,
      showOverwriteConfirmation: null,
      overwriteFiles: '',
      handleOverwriteConfirmation: null,
      overwriteButtonText: '',
      progress: 0
    }
  },
  computed: {
    attrs() {
      const defaults = {
        chips: true,
        outlined: true,
        prependIcon: ''
      }
      return { ...defaults, ...this.$attrs }
    },
    fileExtensions() {
      return this.mimeTypes
        ? this.mimeTypes
            .split(',')
            .map((mt) => mt.split('/')[1].toLowerCase().trim())
        : []
    },
    supportedFileTypes() {
      return this.fileExtensions.map((ext) => ext.toUpperCase()).join(', ')
    },
    acceptedFileTypes() {
      return this.fileExtensions.map((ext) => `.${ext}`).join(',')
    },
    isInvalid() {
      return this.invalidCount > 0
    },
    isUploading() {
      return this.filesUploading.length > 0
    },
    isRemoving() {
      return this.filesRemoving.length > 0
    },
    filesInUploadGroup() {
      return this.files.filter((file) => this.isFileInUploadGroup(file)) || []
    },
    filesUploading() {
      return this.filesInUploadGroup.filter(this.isFileUploading) || []
    },
    filesRemoving() {
      return this.files.filter(
        (file) => file.uploadStatus === UPLOAD_STATUSES.REMOVING
      )
    },
    totalUploaded() {
      return this.filesInUploadGroup.reduce(
        (total, file) =>
          total +
          (file.uploadStatus === UPLOAD_STATUSES.AWAITING_GROUP
            ? file.size
            : 0),
        0
      )
    },
    totalFileSize() {
      return this.filesInUploadGroup.reduce(
        (total, file) => total + (file.size || 0),
        0
      )
    },
    uploadingMessage() {
      const numFilesInGroup = this.filesInUploadGroup.length
      const numFilesUploading = this.filesUploading.length
      const numFile = numFilesInGroup - numFilesUploading + 1
      if (this.isUploading) {
        return `File ${numFile} of ${numFilesInGroup} uploading...`
      }
      if (this.isRemoving) {
        return `Removing ${this.filesRemoving.length} ${
          this.filesRemoving.length > 1 ? 'files' : 'file'
        }...`
      }
      if (this.$refs.fileInput && this.$refs.fileInput.hasError) {
        return this.$refs.fileInput.errorBucket[0]
      }
      return ''
    }
  },
  watch: {
    isUploading(newValue) {
      // If we're done uploading, set the status of all uploading files to 'complete'
      if (!newValue) {
        this.files.forEach((file) => {
          if (this.isFileInUploadGroup(file)) {
            // eslint-disable-next-line no-param-reassign
            file.uploadStatus = UPLOAD_STATUSES.COMPLETE
          }
        })
        // Re-sync for IE11
        this.changeNoOp()
      }
    },
    value(newValue) {
      this.files = [...newValue]
    }
  },
  methods: {
    findExistingFile(fileName) {
      return this.files.find((f) => f.name === fileName)
    },
    shortenChipText(text) {
      return this.$vuetify.breakpoint.xsOnly && text.length > 10
        ? `${text.substring(0, 2)}...${text.substring(text.length - 5)}`
        : text
    },
    isFileInUploadGroup(file) {
      return (
        file.uploadStatus === UPLOAD_STATUSES.AWAITING_GROUP ||
        file.uploadStatus === UPLOAD_STATUSES.UPLOADING
      )
    },
    isFileUploading(file) {
      return file.uploadStatus === UPLOAD_STATUSES.UPLOADING
    },
    isFileProcessing(file) {
      const processingStatuses = [
        UPLOAD_STATUSES.UPLOADING,
        UPLOAD_STATUSES.REMOVING
      ]
      return processingStatuses.includes(file.uploadStatus)
    },
    async onChange(files) {
      if (files === this.files) {
        // onChange is being triggered when opening the file select window. Bail out if nothing changed.
        return
      }
      if (!files.length) {
        // prevent the vuetify component from clearing the field if no files selected
        this.changeNoOp()
      }

      const newFiles = []
      const existingFiles = []

      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        if (this.findExistingFile(file.name)) {
          existingFiles.push(file)
        } else {
          newFiles.push(file)
        }
      }

      if (this.maxFileCount) {
        // add new files to old files to get total count
        if (this.files.length + newFiles.length > this.maxFileCount) {
          this.changeNoOp()
          this.$emit('validationError', {
            type: FILE_UPLOAD_ERROR_TYPES.EXCEED_MAX_FILES,
            fileName: files.map((f) => f.name)
          })
          return
        }
      }

      if (
        !existingFiles.length ||
        (await this.confirmFileOverride(existingFiles)) === 'overwrite'
      ) {
        this.uploadFiles(files)
      } else {
        // cancel - do nothing
        this.changeNoOp()
      }
    },
    confirmFileOverride(existingFiles) {
      this.showOverwriteConfirmation = true
      return new Promise((resolve) => {
        this.handleOverwriteConfirmation = resolve
        this.overwriteFiles = existingFiles.map((f) => f.name)
      }).then((resolution) => {
        this.showOverwriteConfirmation = false
        return resolution
      })
    },
    uploadFiles(files) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        if (this.validateFile(file)) {
          this.startUpload(file)
        } else {
          this.changeNoOp()
        }
      }
    },
    async startUpload(file) {
      const existingFile = this.findExistingFile(file.name)
      if (existingFile) {
        // remove the existing file first
        this.files.splice(
          this.files.findIndex((f) => f.name === existingFile.name),
          1
        )
      }

      // push a json copy of the file, not the file itself
      this.files.push({
        name: file.name,
        size: file.size,
        type: file.type,
        uploadStatus: UPLOAD_STATUSES.UPLOADING
      })

      this.emitUploadEvent(file)
    },
    emitUploadEvent(file) {
      // trigger the event to start the upload
      this.$emit('upload', file, {
        progress: this.progressCallback,
        success: this.finishUpload, // on success
        failure: this.removeFile // on failure
      })
    },
    progressCallback(e) {
      this.progress = Math.ceil((e.loaded * 100) / e.total)
    },
    finishUpload(uploadedFile) {
      this.progress = 0
      // 'uploadedFile' is the actual file object we passed out, not what we track internally
      // so we need to find and update our internal object
      const file = this.findExistingFile(uploadedFile.name)
      if (file) {
        // s3 path objectKey should be returned in uploaded file
        file.objectKey = uploadedFile.objectKey
        file.uploadStatus = UPLOAD_STATUSES.AWAITING_GROUP
        this.$emit('input', this.files)
        // Re-sync for IE11
        this.changeNoOp()
      }
    },
    changeNoOp() {
      // We must cause a change in the value or else the v-file-input component will change its internal value to something other than our supplied value
      this.files = [...this.files]
    },
    isValidFileSize(file) {
      return !(this.maxFileSize && file.size > this.maxFileSize)
    },
    isValidFileName(file) {
      return this.fileNameRegEx.test(file.name)
    },
    isValidFileType(file) {
      const fileParts = file.name.split('.')
      const ext =
        fileParts.length > 1
          ? fileParts[fileParts.length - 1].toLowerCase()
          : ''
      return (
        !this.mimeTypes ||
        (!!(file.type && this.mimeTypes.includes(file.type)) &&
          !!(ext && this.fileExtensions.includes(ext)))
      )
    },
    validateFile(file) {
      if (!this.isValidFileSize(file)) {
        this.$emit('validationError', {
          type: FILE_UPLOAD_ERROR_TYPES.FILE_SIZE,
          fileName: file.name
        })
        return false
      }
      if (!this.isValidFileType(file)) {
        this.$emit('validationError', {
          type: FILE_UPLOAD_ERROR_TYPES.FILE_TYPE,
          fileName: file.name
        })
        return false
      }
      if (!this.isValidFileName(file)) {
        this.$emit('validationError', {
          type: FILE_UPLOAD_ERROR_TYPES.FILE_NAME,
          fileName: file.name
        })
        return false
      }

      return true
    },
    onDelete(file) {
      const failureCallback = () => {
        file.uploadStatus = UPLOAD_STATUSES.COMPLETE // eslint-disable-line no-param-reassign
        this.$emit('input', this.files)
      }
      // eslint-disable-next-line no-param-reassign
      file.uploadStatus = UPLOAD_STATUSES.REMOVING
      // emit delete with success and failure callbacks
      this.$emit('delete', file, {
        success: this.removeFile,
        failure: failureCallback
      })
    },
    removeFile(deletedFile) {
      // remove file from list once removal is completed by parent
      this.files.splice(
        this.files.findIndex((f) => f.name === deletedFile.name),
        1
      )
      this.$emit('input', this.files)
      // Re-sync for IE11
      this.changeNoOp()
    }
  }
}
</script>

<style lang="scss" scoped>
.file-upload {
  margin-top: 1rem;
  background-color: #ffffff;
  color: $color-text-body;

  ::v-deep button {
    border: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.file-chip {
  padding-right: 31px;

  &--removable {
    padding-right: 0;
  }
}

#fileUploadRestrictions {
  color: $ads-dark-60;
  font-size: 0.933rem;
  margin-left: 2px;
}

.progress-wrapper {
  background-color: $ads-light-blue;
}

//Vuetify components
::v-deep {
  .v-text-field .v-text-field__details {
    padding: 0px 2px;
  }
  .v-messages__wrapper .v-messages__message {
    color: $ads-dark-60;
    font-size: 0.933rem;
    line-height: 1rem;
  }

  //  This CSS is to resolve the bug with the upload component in safari:
  //  https://github.com/vuetifyjs/vuetify/issues/10832
  //  This bug was resolved in this fix:
  //  https://github.com/vuetifyjs/vuetify/commit/d5800aad7dc9e62e7d398c890b7af6580e6060ce
  //  as part of v2.3.11. However, due to circumstances at the time the bug was found
  //  we were unable to do a vuetify update and so are implementing the fix ourselves.
  //  This should be removed once vuetify is updated to this version or higher.

  .v-file-input input[type='file'] {
    pointer-events: none;
  }

  .v-file-input .v-input__icon button {
    width: 48px;
    &:hover {
      text-decoration: none !important;
    }
  }
}

// IE11 fixes
.ie11 {
  ::v-deep .v-file-input {
    .v-input__icon button {
      // fix paper clip icon positioning
      padding: 0px;
      margin-left: -3px;
    }
    .v-text-field__slot .v-label {
      // fix label positioning
      left: -28px !important;
    }
  }
}

.overwriteModalTitle {
  font-size: 1.25rem;
}
</style>
