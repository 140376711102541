<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0" data-testid="parent/carer-details-section">
    <div v-if="form.parentCarers && form.parentCarers.length > 0">
      <div
        v-for="(parent, index) in form.parentCarers"
        :key="index"
        class="mb-3"
      >
        <div class="parentName mb-2">
          {{
            parent.parentCarerGivenName
              ? parent.parentCarerGivenName
              : '(empty)'
          }}
          {{ parent.parentCarerFamilyName }}
        </div>
        <v-row>
          <v-col>
            <div class="fieldLabel">Relationship</div>
            <div class="fieldValue">
              {{
                relationship(parent.parentCarerRelation)
                  ? relationship(parent.parentCarerRelation)
                  : '(empty)'
              }}
            </div>
          </v-col>
          <v-col>
            <div class="fieldLabel">Title</div>
            <div class="fieldValue">
              {{
                parent.parentCarerTitle ? parent.parentCarerTitle : '(empty)'
              }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="fieldLabel">Given name</div>
            <div class="fieldValue">
              {{
                parent.parentCarerGivenName
                  ? parent.parentCarerGivenName
                  : '(empty)'
              }}
            </div>
          </v-col>
          <v-col>
            <div class="fieldLabel">Family name</div>
            <div class="fieldValue">
              {{
                parent.parentCarerFamilyName
                  ? parent.parentCarerFamilyName
                  : '(empty)'
              }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="fieldLabel">
              Does
              {{
                parent.parentCarerGivenName
                  ? parent.parentCarerGivenName
                  : 'parent/carer'
              }}
              live at {{ addressString(studentAddress) }}?
            </div>
            <div class="fieldValue">
              {{
                parent.liveOnResidentialAddress != null
                  ? parent.liveOnResidentialAddress
                    ? 'Yes'
                    : 'No'
                  : '(empty)'
              }}
            </div>
          </v-col>
          <v-col>
            <div class="fieldLabel">Address</div>
            <div class="fieldValue">
              {{
                parent.residentialAddress != null
                  ? addressString(parent.residentialAddress)
                  : '(empty)'
              }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="fieldLabel">
              Is
              {{
                parent.parentCarerGivenName
                  ? parent.parentCarerGivenName
                  : 'parent/carer'
              }}
              completing this application for this student?
            </div>
            <div class="fieldValue">
              {{
                parent.parentCarerCompletingApp !== null
                  ? parent.parentCarerCompletingApp
                    ? 'Yes'
                    : 'No'
                  : '(empty)'
              }}
            </div>
          </v-col>
          <v-col>
            <div class="fieldLabel">Email</div>
            <div class="fieldValue">
              {{
                parent.parentCarerEmail ? parent.parentCarerEmail : '(empty)'
              }}
            </div>
          </v-col>
        </v-row>
        <div>
          <v-row>
            <v-col
              v-for="(contact, contactIndex) in parent.contactDetails"
              :key="contactIndex"
              cols="12"
              md="6"
            >
              <div v-if="contact.contactType === 'PHHOM'" class="fieldLabel">
                Contact number (Home)
              </div>
              <div v-if="contact.contactType === 'PHMOB'" class="fieldLabel">
                Contact number (Mobile)
              </div>
              <div v-if="contact.contactType === 'PHWRK'" class="fieldLabel">
                Contact number (Business/Work)
              </div>
              <div
                v-if="
                  contact.contactType === '' || contact.contactType === null
                "
                class="fieldLabel"
              >
                Contact number (empty)
              </div>
              <div class="fieldValue pb-1">
                {{ contact.contactValue ? contact.contactValue : '(empty)' }}
              </div>
              <div class="fieldLabel">Comment</div>
              <div class="fieldValue">
                {{ contact.comments ? contact.comments : '(empty)' }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div v-if="form.parentCarers === null">
      <div class="parentName mb-2">(empty)</div>
      <v-row>
        <v-col>
          <div class="fieldLabel">Relationship</div>
          <div class="fieldValue">(empty)</div>
        </v-col>
        <v-col>
          <div class="fieldLabel">Title</div>
          <div class="fieldValue">(empty)</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="fieldLabel">Given name</div>
          <div class="fieldValue">(empty)</div>
        </v-col>
        <v-col>
          <div class="fieldLabel">Family name</div>
          <div class="fieldValue">(empty)</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="fieldLabel">Email</div>
          <div class="fieldValue">(empty)</div>
        </v-col>
      </v-row>
      <div>
        <div class="fieldLabel">Contact number (empty)</div>
        <div class="fieldValue">(empty)</div>
        <div class="fieldLabel">Comment</div>
        <div class="fieldValue">(empty)</div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ParentCarerView',
  computed: {
    ...mapGetters({
      countries: 'countries',
      form: 'form',
      relationships: 'relationships'
    }),
    studentAddress() {
      return this.form.residentialAddress
    }
  },
  methods: {
    relationship(value) {
      const relationship = this.relationships.filter(
        (item) => item.value === value
      )
      if (relationship.length > 0) {
        return relationship[0].text
      }
      return null
    },
    addressString(address) {
      if (address.countryCode === 'AUS') {
        return `${address.addressLine1}, ${address.suburbName}, ${address.stateCode} ${address.postCode}`
      }
      const countryName = this.countries.find(
        (item) => item.value === address.countryCode
      )?.text
      return `${address.addressLine1} ${address.addressLine2} ${countryName}`
    }
  }
}
</script>

<style lang="scss" scoped>
.fieldValue {
  color: $color-text-body;
  font-weight: bold;
  font-size: 14px;
  overflow-wrap: break-word;
}
.fieldLabel {
  font-size: 14px;
  color: $ads-dark-70;
  overflow-wrap: break-word;
}
.parentName {
  border-bottom: 1px solid $ads-light-20;
  font-weight: bold;
  font-size: 16px;
  color: $color-text-body;
  overflow-wrap: break-word;
}
</style>
