import store from '@/store'
import { setSessionItem, getSessionItem } from '@/helpers/storage'

export function saveState(storageKey, vuexProps) {
  const props = vuexProps.reduce((acc, storeProp) => {
    acc[storeProp.getter] = store.getters[storeProp.getter]
    return acc
  }, {})
  setSessionItem(storageKey, props)
}

export function restoreState(storageKey, vuexProps) {
  const savedStateData = getSessionItem(storageKey)
  if (savedStateData) {
    Object.entries(savedStateData).forEach(([key, value]) => {
      const prop = vuexProps.find((p) => p.getter === key)
      if (prop) {
        store.commit(prop.setter, value)
      }
    })
    return true
  }
  return false
}
