<template>
  <v-radio-group
    :ref="inputRef"
    v-bind="$attrs"
    :value="value"
    :label="label"
    :hint="hint"
    :rules="rules"
    persistent-hint
    :error-messages="hasError"
    :disabled="disabled"
    @change="$emit('input', $event)"
  >
    <v-radio
      v-for="item in items"
      :key="item.value"
      :value="item.value"
      :label="item.text"
      :aria-describedby="messageBoxId"
      :aria-invalid="!isValid"
    />
    <template #message="data">
      <div :id="messageBoxId" class="v-messages__message">
        {{ data.message }}
      </div>
    </template>
  </v-radio-group>
</template>

<script>
import FormMixin from '@/mixins/form'
import accessibilityMixin from '@/mixins/fieldAccessibility'

export default {
  name: 'RadioGroup',
  mixins: [FormMixin, accessibilityMixin('radioGroup')],
  props: {
    value: [String, Number, Boolean],
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      hasError: ''
    }
  },
  watch: {
    // When the user click the radio button, remove the validation message
    value(val) {
      if (val) {
        this.hasError = ''
      }
    }
  },
  mounted() {
    const radioRef = this.inputRef
    // Select the radio input and add onblur event as Vuetify doesn't provide onblur event and wrap the radio input deep inside div of the v-radio
    this.$refs[radioRef].$children[0].$refs.input.onblur = function () {
      if (!this.$refs[radioRef].value) {
        this.hasError = `${this.label.replace('?', '')} is required`
      }
    }.bind(this)
  }
}
</script>

<style lang="scss" scoped>
.v-radio {
  //accessiblility requirement as specified in FUS-16
  height: 44px;
  margin-bottom: 0px !important;
}
</style>
