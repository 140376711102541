<template>
  <!-- eslint-disable max-len -->
  <v-container fluid class="content">
    <Banner>
      <!-- eslint-disable-next-line -->
      <h2 v-html="heading" />
    </Banner>
    <v-container class="ooaContent error-content">
      <APIError
        v-if="error.type === ERROR_CODES.API"
        :error="error"
        :default-action="defaultAction"
        :include-stack-trace="!!displayStackTraceDetails"
        :previous-route="previousRoute"
      />
      <NoPendingError v-else-if="error.type === ERROR_CODES.NO_PENDING" />
      <NoSchoolCodeError
        v-else-if="error.type === ERROR_CODES.NO_SCHOOL_CODE"
      />
      <DefaultError
        v-else
        :default-action="defaultAction"
        :include-stack-trace="!!displayStackTraceDetails"
        :error="error"
      />
    </v-container>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner'
import APIError from '@/components/error/APIError'
import NoPendingError from '@/components/error/NoPendingError'
import NoSchoolCodeError from '@/components/error/NoSchoolCodeError'
import DefaultError from '@/components/error/DefaultError'
import { ERROR_CODES, NODE_ENVIRONMENTS } from '@/constants'

export default {
  name: 'ErrorView',
  components: {
    Banner,
    APIError,
    NoPendingError,
    NoSchoolCodeError,
    DefaultError
  },
  data() {
    return {
      displayStacktrace:
        process.env.VUE_APP_ENV_NAME &&
        process.env.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PROD &&
        process.env.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PREPROD,
      previousRoute: null,
      ERROR_CODES,
      defaultAction: {
        text: 'Contact support',
        attrs: {
          href: 'https://enrolmentassist.freshdesk.com/support/tickets/new',
          target: '_blank',
          rel: 'noopener'
        }
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line no-param-reassign
    next((vm) => {
      vm.previousRoute = from
    })
  },
  computed: {
    errorType() {
      return this.$store.getters.errorType
    },
    error() {
      return this.$store.getters.error
    },
    heading() {
      if (
        this.errorType === ERROR_CODES.NO_PENDING ||
        this.errorType === ERROR_CODES.NO_SCHOOL_CODE
      ) {
        return 'No application found <br /> for this email address'
      }
      return 'Whoops!<br/>That was unexpected...'
    },
    displayStackTraceDetails() {
      return this.displayStacktrace && this.error && this.error.errorObject
    }
  }
}
</script>
<style lang="scss" scoped>
.error-content {
  padding: 3.125rem 0.9375rem;
}
.redText {
  color: $ads-error-red;
}
.mobile .btnSection span {
  display: block;
  margin-top: 10px;
}
.stackTraceContainer {
  color: $ads-navy !important;

  .stackTrace {
    white-space: pre-wrap;
    font-size: 0.9rem;
  }
}
.error-container {
  background-color: $ads-white;
  border-radius: 4px;
  text-align: center;

  .icon-container {
    background-color: $ads-light-blue;
    border-radius: 50%;
    width: 161px;
    height: 161px;
    text-align: center;
    vertical-align: middle;
    margin: 1.5rem auto;

    .error-icon {
      padding-top: 30px;
      margin: auto;
    }
  }
}
.form-container {
  padding-top: 40px;
}
</style>
