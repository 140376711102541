<template>
  <!-- eslint-disable max-len -->
  <v-container fluid class="content">
    <Banner>
      <h2 data-testid="session-timeout-banner">Your session has timed out</h2>
    </Banner>
    <v-container fluid class="ooaContent">
      <v-container class="form-container">
        <div class="error-container elevation-2 pa-5 pb-9 text-center">
          <div class="icon-container">
            <img
              class="error-icon"
              alt="timeout"
              width="100px"
              :src="require(`@/assets/icon-timeout.png`)"
            />
          </div>
          <div v-if="fromEhub" class="timeout-content">
            <h2 class="pb-sm-8 pb-4">Your session has timed out</h2>
            <v-btn
              class="underline"
              data-testid="login-ehub"
              text
              @click="ehubLogin"
            >
              Login
            </v-btn>
          </div>
          <div v-else class="timeout-content">
            <h2 class="pb-sm-8 pb-4">Your session has timed out</h2>
            <a
              v-if="email"
              class="underline"
              data-testid="resume-application-otp"
              @click="resendOtpCode"
            >
              Resume Application</a
            >
            <a
              v-else
              class="underline"
              data-testid="resume-application-email"
              @click="resumeFromEmail"
              >Resume Application</a
            >
          </div>
        </div>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner'

export default {
  name: 'TimeoutView',
  components: {
    Banner
  },
  data() {
    return {}
  },
  computed: {
    email() {
      return this.$store.getters.email
    },
    fromEhub() {
      const comesFrom = JSON.parse(window.sessionStorage.getItem('comesFrom'))
      return comesFrom === 'ehub'
    }
  },
  methods: {
    resendOtpCode() {
      this.$store.commit('setIsResuming', true)
      this.$log.debug(
        'resuming application from expired session for user: ',
        this.$store.getters.email
      )
      this.$router.push({
        name: 'login',
        params: { resumeSessionExpired: true, userEmail: this.email }
      })
    },
    resumeFromEmail() {
      this.$router.push({ name: 'login' })
    },
    ehubLogin() {
      window.onbeforeunload = null
      window.location.href = `${window.location.origin}/login`
    }
  }
}
</script>

<style lang="scss" scoped>
.timeout-content h2 {
  font-size: 1.5rem;
  color: $ads-navy !important;
}
.error-container {
  background-color: $ads-white;
  border-radius: 4px;
  text-align: center;

  .icon-container {
    background-color: $ads-light-blue;
    border-radius: 50%;
    width: 161px;
    height: 161px;
    text-align: center;
    vertical-align: middle;
    margin: 1.5rem auto;

    .error-icon {
      padding-top: 44px;
      margin: auto;
    }
  }
}
.form-container {
  padding-top: 40px;
}
</style>
