import { saveState, restoreState } from '@/helpers/vuexState'

const sessionStorageKey = 'preForm'
const vuexProps = [
  {
    getter: 'school',
    setter: 'setSchool'
  },
  {
    getter: 'calendarYear',
    setter: 'setCalendarYear'
  },
  {
    getter: 'localResidentialAddress',
    setter: 'setLocalResidentialAddress'
  },
  {
    getter: 'isResuming',
    setter: 'setIsResuming'
  },
  {
    getter: 'email',
    setter: 'setEmail'
  },
  {
    getter: 'cognitoSession',
    setter: 'setCognitoSession'
  },
  {
    getter: 'selectedCatchment',
    setter: 'setSelectedCatchment'
  }
]

export default function preFormGuard(to, from, next) {
  if (from.name) {
    // save state from last step
    saveState(sessionStorageKey, vuexProps)
    return next()
  }

  // we're coming from a page refresh. Try and restore state.
  restoreState(sessionStorageKey, vuexProps)

  return next()
}
