<template>
  <!-- eslint-disable max-len -->

  <v-container class="pt-0" aria-live="polite">
    <v-alert
      v-if="isTemporaryResident"
      border="left"
      colored-border
      :color="ADS_Colors.PRIMARY_RED"
      icon="mdi-alert-circle"
      class="theme--light mb-8"
    >
      <p>
        This online application can only be used for Australian or NZ citizens
        or Permanent residents.
      </p>
      <p>
        This online service is no longer available for temporary residents.
        Please visit the website for
        <a href="https://www.deinternational.nsw.edu.au/" target="_blank"
          >temporary residents and international students</a
        >.
      </p>
    </v-alert>
    <v-alert
      v-if="isStudentPrefilled"
      border="left"
      colored-border
      color="blue"
      icon="mdi-alert-circle"
      class="alert--light-blue mb-8"
    >
      <p>
        We have pre-filled these details based on information you have already
        provided to NSW Department of Education.
      </p>
      <strong
        >Please ensure the pre-filled information below is accurate before
        continuing to the next section</strong
      >
    </v-alert>
    <v-card class="pa-6">
      <v-form
        ref="form"
        v-model="validStudent"
        class="ooa-form"
        data-testid="student-details-form"
      >
        <h2 class="mb-8">Student details</h2>
        <TextField
          v-model="studentDetails.firstName"
          data-initial-focus
          class="mb-6"
          label="Student's first name"
          placeholder="Enter first name"
          hint="As shown on their birth certificate"
          :rules="splTextRuleName"
          maxlength="100"
          data-testid="student-firstName"
          required
          :disabled="isEhubLinkedStudent && !!form.student.firstName"
          :show-label-on-disabled="true"
        />

        <TextField
          v-model="studentDetails.otherName"
          class="mb-6"
          label="Student's middle name"
          placeholder="Enter middle name"
          hint="(optional)"
          :rules="splTextRuleMiddleName"
          maxlength="100"
          data-testid="student-middleName"
        />

        <TextField
          v-model="studentDetails.familyName"
          class="mb-6"
          label="Student's family name"
          hint="As shown on their birth certificate"
          placeholder="Enter family name"
          :rules="splTextRuleFamilyName"
          maxlength="100"
          required
          data-testid="student-familyName"
        />

        <TextField
          v-model="studentDetails.prefFirstName"
          class="mb-6"
          label="Student's preferred name"
          maxlength="100"
          placeholder="Enter preferred name"
          :rules="splTextRulePrefFirstName"
          hint="(Optional) This is the name usually used to address your child. It can be different from their birth certificate."
          data-testid="student-prefFirstName"
        />

        <RadioGroup
          v-model="studentDetails.genderCode"
          class="mb-6"
          :items="[
            { text: 'Male', value: 'M' },
            { text: 'Female', value: 'F' }
          ]"
          :label="pluralisedFirstName + ' gender'"
          data-testid="student-genderCode"
          required
        />

        <DatePicker
          v-model="studentDetails.dateOfBirth"
          :label="pluralisedFirstName + ' date of birth'"
          class="mb-6"
          :date-picker-attrs="{
            min: minBirthDate,
            max: sevenDaysAgo
          }"
          year-picker
          date-format="DD-MM-YYYY"
          output-date-format="DD-MM-YYYY"
          :rules="[
            rules.required('Student\'s date of birth'),
            rules.validMiminumAge,
            rules.validMaximumAge
          ]"
          data-testid="student-dateOfBirth"
          :disabled="isEhubLinkedStudent && isDateOfBirthProvided()"
          persistent-placeholder
        />

        <SelectMenu
          v-model="otherStudentDetails.intendedStartDate"
          class="mb-6"
          :items="schoolTerms"
          label="Intended start date"
          required
          data-testid="student-intendedStartDate"
        />

        <DatePicker
          v-if="
            otherStudentDetails.intendedStartDate ===
            'Choose a specific start date'
          "
          v-model="otherStudentDetails.enterStartDate"
          label="Enter start date"
          class="mb-6"
          :date-picker-attrs="{
            min: minFutureDatesForSelectedYear,
            max: maxFutureDatesForSelectedYear,
            showCurrent: minFutureDatesForSelectedYear
          }"
          date-format="DD-MM-YYYY"
          output-date-format="DD-MM-YYYY"
          required
          :rules="validStartDate"
          data-testid="student-enterStartDate"
          @input="validateStartDate"
          persistent-placeholder
        />

        <SelectMenu
          v-if="showScholasticYear"
          v-model="otherStudentDetails.scholasticYear"
          class="mb-6"
          :items="SCHOLASTIC_YEARS"
          label="Intended scholastic year"
          data-testid="student-scholasticYear"
          required
          @input="changeScholasticYear"
        />

        <RadioGroup
          v-model="otherStudentDetails.prevAttendSchool"
          class="mb-6"
          :items="[
            { text: 'No', value: 'No' },
            { text: 'Yes', value: 'Yes' }
          ]"
          :label="`Has ${inlinePreferredName} attended another school?`"
          hint="e.g. NSW, interstate, overseas"
          required
          data-testid="student-prevAttendSchool"
          @input="changePreviousSchool"
        />
        <div
          v-if="otherStudentDetails.prevAttendSchool === 'Yes'"
          class="fieldLabel"
        >
          <label
            >Where did {{ inlinePreferredName }} most recently attend
            school?</label
          >
        </div>
        <SelectMenu
          v-if="otherStudentDetails.prevAttendSchool === 'Yes'"
          v-model="otherStudentDetails.prevSchoolType"
          class="mb-6"
          :items="stateOptions"
          label="Australia/Overseas"
          required
          data-testid="student-prevSchoolType"
          @input="changePreviousSchool"
        />

        <SchoolLookUp
          v-if="displaySchoolLookup"
          v-model="otherStudentDetails.prevSchool"
          label="School name"
          placeholder="Enter school name"
          :selected-state="otherStudentDetails.prevSchoolType"
          required
          data-testid="student-prevSchool"
          class="mb-6"
        />

        <TextField
          v-if="displayOtherSchoolAttended"
          v-model="otherStudentDetails.otherSchoolAttendedName"
          class="mb-6"
          label="School name"
          placeholder="Enter school name"
          required
          data-testid="student-otherSchoolAttendedName"
          maxlength="200"
        />

        <TextField
          v-if="displayOtherSchoolAttended"
          v-model="otherStudentDetails.otherSchoolAttendedLocation"
          class="mb-6"
          label="School location"
          placeholder="Enter school location"
          :rules="maxLength"
          required
          data-testid="student-otherSchoolAttendedLocation"
          maxlength="132"
        />

        <DatePicker
          v-if="otherStudentDetails.prevAttendSchool === 'Yes'"
          ref="prevSchoolStartDate"
          v-model="otherStudentDetails.prevSchoolStartDate"
          label="Attended from"
          class="mb-6"
          :date-picker-attrs="{
            type: 'month',
            min: minAttendedDate,
            max: maxAttendedStartDate
          }"
          year-picker
          date-format="MM-YYYY"
          output-date-format="MM-YYYY"
          required
          :rules="validAttendedFrom"
          data-testid="student-prevSchoolStartDate"
          @input="validateAttendedFrom"
          persistent-placeholder
        />

        <DatePicker
          v-if="otherStudentDetails.prevAttendSchool === 'Yes'"
          ref="prevSchoolLastDate"
          v-model="otherStudentDetails.prevSchoolLastDate"
          class="mb-6"
          label="Attended until"
          :date-picker-attrs="{
            type: 'month',
            min: minAttendedLastDate,
            max: maxFutureDates
          }"
          year-picker
          date-format="MM-YYYY"
          output-date-format="MM-YYYY"
          required
          :rules="validAttendedUntil"
          data-testid="student-prevSchoolLastDate"
          @input="validateAttendedUntil"
          persistent-placeholder
        />

        <div class="fieldLabel">
          <label>What is {{ inlinePreferredName }}'s residency status?</label>
        </div>

        <SelectMenu
          v-model="residencyStatus"
          class="mb-6"
          :items="residencyStatusOptions"
          label="Residency status"
          hint="To be an Australian citizen, at least one parent needed to be an Australian citizen or permanent resident when the student was born."
          data-testid="student-residencyStatus"
          :rules="[rules.required('Residency status'), rules.residency]"
        />
      </v-form>
    </v-card>

    <div class="d-flex justify-end mt-6">
      <v-btn
        class="button-next primary"
        color="primary"
        x-large
        data-testid="save-btn"
        @click="
          enableLoader()
          save()
        "
      >
        <ButtonSpinner :loading="loading" class="mr-2" />
        Save &amp; Continue
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL,
  CHOOSE_DATE_VALUE,
  DATE_OF_BIRTH_MINIMUM_YEAR,
  ATTENDED_FROM_UNTIL_MINIMUM_YEAR,
  DATE_OF_BIRTH_MAX_YEAR,
  PREFILL_FLAG,
  CATCHMENTS
} from '@/constants'

import RadioGroup from '@/components/form/RadioGroup'
import SelectMenu from '@/components/form/SelectMenu'
import TextField from '@/components/form/TextField'
import SchoolLookUp from '@/components/form/SchoolLookUp'
import ButtonSpinner from '@/components/ButtonSpinner'
import { DatePicker, ADS_Colors } from '@nswdoe/doe-ui-core'
import getTerms from '@/helpers/form/schoolTerms'
import handleFirstFocus from '@/helpers/form/focus'

import moment from 'moment'
import validators from '@/helpers/form/validators'

export default {
  name: 'FormStudent',
  components: {
    DatePicker,
    RadioGroup,
    SelectMenu,
    TextField,
    SchoolLookUp,
    ButtonSpinner
  },
  data() {
    const dateSevenDaysAgo = new Date()
    dateSevenDaysAgo.setDate(dateSevenDaysAgo.getDate() - 7)
    const sevenDaysAgo = dateSevenDaysAgo.toISOString().slice(0, 10)

    const thisYear = new Date().getFullYear()
    const maxFutureDates = `${thisYear.toString()}-12-31`
    const maxFutureDatesNextYear = `${(thisYear + 1).toString()}-12-31`

    return {
      loading: false,
      ADS_Colors,
      validStudent: true,
      residencyStatus: null,
      // Student details
      studentDetails: {
        firstName: null,
        otherName: null,
        familyName: null,
        prefFirstName: null,
        genderCode: null,
        dateOfBirth: null,
        srn: null
      },
      otherStudentDetails: {
        intendedStartDate: null,
        intendedStartDatePrint: null,
        enterStartDate: null,
        scholasticYear: null,
        prevAttendSchool: null,
        prevSchoolType: null,
        prevSchool: null,
        prevSchoolStartDate: null,
        prevSchoolLastDate: null,
        otherSchoolAttendedName: null,
        otherSchoolAttendedLocation: null
      },
      today: moment().format('YYYY-MM-DD'), // local time
      maxFutureDates,
      maxFutureDatesNextYear,
      thisYear,
      minBirthDate: new Date(`${thisYear - DATE_OF_BIRTH_MINIMUM_YEAR}-01-01`)
        .toISOString()
        .slice(0, 10),
      minAttendedDate: new Date(
        `${thisYear - ATTENDED_FROM_UNTIL_MINIMUM_YEAR}-01-01`
      )
        .toISOString()
        .slice(0, 10),
      sevenDaysAgo,
      allowedResidencies: ['PER', 'NZC', 'AUC'],
      // ToDo: rules function has only 1 parameter value. Can't pass label dynamically
      splTextRuleFamilyName: [validators.validName],
      splTextRuleMiddleName: [validators.validName],
      splTextRuleName: [validators.validName],
      splTextRulePrefFirstName: [validators.validName],
      maxLength: [(v) => !v || v.length <= 128 || 'Max 128 characters'],
      isHighSchoolStudent: () => {
        const catchment = this.form.catchmentLevel
        return catchment === 'secondary'
      },
      rules: {
        required: (label) => (v) =>
          !!v || `${label.replace('?', '')} is required`,
        residency: (v) =>
          this.allowedResidencies.includes(v) ||
          `This online application can only be used for Australian or NZ citizens or Permanent residents. ${
            v === 'TMP'
              ? 'This online service is no longer available for temporary residents. '
              : ''
          }Please visit the website for <a class="error--text" href="https://www.deinternational.nsw.edu.au/" target="_blank" > temporary residents and international students</a>${
            v === 'TMP' ? '' : ' or contact the school for more information'
          }.`,
        validMaximumAge: (date) => {
          if (this.isHighSchoolStudent()) {
            // high school age check less than 18 year old
            const baseDateForAge = this.targetBaseAge()
            const diff = moment(baseDateForAge, 'DD-MM-YYYY').diff(
              moment(date, 'DD-MM-YYYY'),
              'year',
              true
            )
            return (
              diff < DATE_OF_BIRTH_MAX_YEAR ||
              'Children enrolling in high school must be no younger than 9 and no older than 18 years of age. Please choose a date of birth within this range.'
            )
          }
          // leaving previous rules we had for DOB as the default
          const cutOffDate = moment(
            `01-01-${thisYear - DATE_OF_BIRTH_MINIMUM_YEAR}`,
            'DD-MM-YYYY'
          )
          return (
            cutOffDate.diff(moment(date, 'DD-MM-YYYY')) <= 0 ||
            `Please ensure the date is no earlier than ${
              thisYear - DATE_OF_BIRTH_MINIMUM_YEAR
            } and no later than one week ago.`
          )
        },
        validMiminumAge: (date) => {
          const baseDateForAge = this.isHighSchoolStudent()
            ? `${this.targetBaseAge()}`
            : `31-07-${this.calendarYear}`
          const diff = moment(baseDateForAge, 'DD-MM-YYYY').diff(
            moment(date, 'DD-MM-YYYY'),
            'year',
            true
          )
          if (this.isHighSchoolStudent()) {
            // high school age check
            return (
              diff >= 9 ||
              'Children enrolling in high school must be at least 9 years of age.'
            )
          }
          // kindergarten age check APO-586
          return (
            diff >= 5 ||
            `This student is too young to begin school in ${this.calendarYear}.`
          )
        },
        validDateFormat: (date) => {
          const formattedDate = this.formatDateString(date)
          return (
            this.isValidDateFormat('YYYY-MM-DD', formattedDate) ||
            'Invalid date'
          )
        }
      },
      dateUntilValidation: [true],
      isSectionCommitted: false,
      validStartDate: [true],
      validAttendedFrom: [true],
      validAttendedUntil: [true]
    }
  },
  computed: {
    ...mapGetters({
      form: 'form',
      states: 'states',
      residencyStatuses: 'residencyStatuses',
      currentStep: 'currentStep',
      scholasticYears: 'scholasticYears',
      allSchoolTerms: 'termDates',
      school: 'school',
      calendarYear: 'calendarYear',
      viaEhub: 'viaEhub',
      isEhubLinkedStudent: 'isEhubLinkedStudent',
      scholasticYear: 'scholasticYear',
      ehubPrefill: 'ehubPrefill',
      ehubFormData: 'ehubFormData',
      childSrn: 'childSrn',
      oesProperties: 'oesProperties'
    }),
    schoolTerms() {
      return getTerms(
        this.allSchoolTerms,
        this.calendarYear,
        this.school.calendarLateInd
      )
    },
    preferredName() {
      if (this.studentDetails.prefFirstName || this.studentDetails.firstName) {
        return (
          this.studentDetails.prefFirstName || this.studentDetails.firstName
        )
      }
      return 'Student'
    },
    inlinePreferredName() {
      const name = this.preferredName
      if (name === 'Student') {
        return 'the student'
      }
      return name
    },
    pluralisedFirstName() {
      const name = this.preferredName
      if (name) {
        return name.trim().slice(-1) === 's' ? `${name}'` : `${name}'s`
      }
      return ''
    },
    showScholasticYear() {
      if (this.getStartYear(this.otherStudentDetails)) {
        return true
      }
      return false
    },
    SCHOLASTIC_YEARS() {
      const scholasticYears =
        this.getScholasticYearsDropdown(
          this.getStartYear(this.otherStudentDetails),
          this.form
        ) || []

      if (scholasticYears.length === 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.otherStudentDetails.scholasticYear = null
      }

      return scholasticYears
    },
    stateOptions() {
      const stateOptions = this.states
      stateOptions.push({
        text: 'Overseas',
        value: 'OVS'
      })
      return stateOptions
    },
    residencyStatusOptions() {
      return this.residencyStatuses
    },
    minDateAttended() {
      if (this.otherStudentDetails.prevSchoolStartDate) {
        return moment(
          this.otherStudentDetails.prevSchoolStartDate,
          'MM-YYYY'
        ).format('YYYY-MM')
      }
      return new Date().toISOString().slice(0, 10)
    },
    attendedUntil() {
      if (
        this.otherStudentDetails.prevAttendSchool === 'Yes' &&
        this.otherStudentDetails.prevSchoolStartDate
      ) {
        return true
      }
      return false
    },
    displayOtherSchoolAttended() {
      return (
        this.otherStudentDetails.prevAttendSchool === 'Yes' &&
        this.otherStudentDetails.prevSchoolType === 'OVS'
      )
    },
    displaySchoolLookup() {
      return (
        this.otherStudentDetails.prevAttendSchool === 'Yes' &&
        this.otherStudentDetails.prevSchoolType &&
        this.otherStudentDetails.prevSchoolType !== 'OVS'
      )
    },
    maxAttendedStartDate() {
      // the vue date picker requires min/max to be in iso format
      return this.otherStudentDetails.prevSchoolLastDate
        ? this.otherStudentDetails.prevSchoolLastDate
            .split('-')
            .reverse()
            .join('-')
        : this.today
    },
    minAttendedLastDate() {
      return this.otherStudentDetails.prevSchoolStartDate
        ? this.otherStudentDetails.prevSchoolStartDate
            .split('-')
            .reverse()
            .join('-')
        : `${this.thisYear - ATTENDED_FROM_UNTIL_MINIMUM_YEAR}-01`
    },
    maxFutureDatesForSelectedYear() {
      if (this.calendarYear) {
        return `${this.calendarYear}-12-31`
      }
      return this.maxFutureDatesNextYear
    },
    minFutureDatesForSelectedYear() {
      if (this.calendarYear && this.calendarYear !== this.thisYear) {
        return `${this.calendarYear}-01-01`
      }
      return this.today
    },
    isStudentPrefilled() {
      return !!(
        this.ehubPrefill === PREFILL_FLAG.STUDENT_AND_PARENT ||
        this.ehubPrefill === PREFILL_FLAG.STUDENT
      )
    },
    isTemporaryResident() {
      return this.residencyStatus === 'TMP'
    }
  },
  mounted() {
    if (this.form.student && !this.viaEhub) {
      this.validate()
    }
    this.$nextTick().then(() => {
      handleFirstFocus(this.$refs.form.inputs)
    })
  },
  created() {
    if (this.form.student) {
      this.studentDetails = this.form.student
    }
    if (this.isEhubLinkedStudent && !this.form?.student?.srn) {
      this.studentDetails.srn =
        this.childSrn ||
        this.ehubFormData?.child?.srn ||
        this.ehubPrefill?.student?.srn
    }
    if (this.form.otherStudentDetails) {
      this.otherStudentDetails = this.form.otherStudentDetails
    }
    if (!this.otherStudentDetails.scholasticYear) {
      this.otherStudentDetails.scholasticYear = this.scholasticYear
    }
    if (this.form.residencyStatus) {
      this.residencyStatus = this.form.residencyStatus
    }
  },
  beforeDestroy() {
    this.commitSection()
  },
  methods: {
    enableLoader() {
      this.loading = true
    },
    disableLoader() {
      this.loading = false
    },
    formatDateString(date) {
      return moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD')
    },
    dateStringToDateObject(date) {
      return moment(this.formatDateString(date), 'YYYY-MM-DD')
    },
    targetBaseAge() {
      const currentYear = moment().year()

      let target = `01-01-${this.calendarYear}`
      if (+this.calendarYear === currentYear) {
        target = moment().format('DD-MM-YYYY')
      }

      return target
    },
    validate() {
      let isValidPrevSchoolName = true
      let isValidStartDate = true
      let isValidAttendedDates = true
      if (
        this.otherStudentDetails.intendedStartDate ===
        'Choose a specific start date'
      ) {
        this.validateStartDate(this.otherStudentDetails.enterStartDate)
        isValidStartDate = this.validStartDate[0] === true
      } else {
        this.otherStudentDetails.intendedStartDatePrint =
          this.selectedTermLabel(this.otherStudentDetails.intendedStartDate)
      }
      if (this.otherStudentDetails.prevAttendSchool === 'Yes') {
        this.validateAttendedFrom(this.otherStudentDetails.prevSchoolStartDate)
        this.validateAttendedUntil(this.otherStudentDetails.prevSchoolLastDate)
        isValidAttendedDates =
          this.validAttendedFrom[0] === true &&
          this.validAttendedUntil[0] === true
        if (
          this.otherStudentDetails.prevSchoolType !== 'OVS' &&
          this.otherStudentDetails.prevSchool === null
        ) {
          isValidPrevSchoolName = false
        }
      }
      this.validStudent = this.$refs.form.validate()
      this.$store.dispatch('setIsValidStudent', {
        isValid:
          this.validStudent &&
          isValidPrevSchoolName &&
          isValidStartDate &&
          isValidAttendedDates
      })
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    commitSection() {
      if (!this.isSectionCommitted) {
        const student = this.studentDetails
        const otherDetails = this.otherStudentDetails
        this.$store.commit('setResidencyStatus', this.residencyStatus)
        this.$store.commit('setStudentDetails', student)
        this.$store.commit('setOtherStudentDetails', otherDetails)
        this.isSectionCommitted = true
        this.validate()
      }
    },
    async save() {
      try {
        this.validate()
        this.commitSection()
        // persist student details to data store
        await this.$store.dispatch('updateApplication')
        // TODO: look at moving setCurrentStep calls into the route on beforeEnter
        await this.$store.dispatch('setCurrentStep', {
          currentStep: this.currentStep + 1
        })
        await this.$router.push('/form/parentcarer')
      } finally {
        // being extra pedantic here. user would generally either be the new route or error page.
        this.disableLoader()
      }
    },
    getScholasticYearsDropdown(year, form) {
      const scholasticYears = this.scholasticYears[year]
      let { catchmentLevel } = form
      const scholasticYear = this.otherStudentDetails.scholasticYear
      if (catchmentLevel === 'central' && scholasticYear != null) {
        this.form.initialCatchmentLevel = catchmentLevel
        if (
          SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL.PRIMARY.includes(scholasticYear)
        ) {
          this.form.catchmentLevel = CATCHMENTS.PRIMARY
          catchmentLevel = CATCHMENTS.PRIMARY
        } else if (
          SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL.SECONDARY.includes(scholasticYear)
        ) {
          this.form.catchmentLevel = CATCHMENTS.SECONDARY
          catchmentLevel = CATCHMENTS.SECONDARY
        }
      }
      const sYearsForCatchment =
        SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL[catchmentLevel.toUpperCase()] || []
      return scholasticYears
        ? scholasticYears.filter((year) =>
            sYearsForCatchment.includes(year.value)
          )
        : []
    },
    changePreviousSchool() {
      // Changes to the "Yes"/ "No" or "Australian State/Overseas" options clear out all
      // student details for previous school.
      this.otherStudentDetails.prevSchool = null
      this.otherStudentDetails.otherSchoolAttendedLocation = null
      this.otherStudentDetails.otherSchoolAttendedName = null
    },
    changeScholasticYear() {
      this.$store.commit(
        'setScholasticYear',
        this.otherStudentDetails.scholasticYear
      )
    },
    validateStartDate(date) {
      const selectedDate = moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(
        'YYYY-MM-DD'
      )
      if (date === null || date === '') {
        this.validStartDate = ['Intended start date is required']
      } else if (this.isValidDateFormat('YYYY-MM-DD', selectedDate)) {
        const today = moment().format('YYYY-MM-DD')
        const currentDate = moment(today)
        const enteredDate = moment(selectedDate)

        const maxYear = this.calendarYear || new Date().getFullYear() + 1
        const maxFutureDate = new Date(`${maxYear}-12-31`)
          .toISOString()
          .slice(0, 10)

        const minYear = this.calendarYear || currentDate.year()
        const minDate = new Date(`${minYear}-01-01`).toISOString().slice(0, 10)

        if (enteredDate.diff(currentDate) < 0) {
          this.validStartDate = ['Cannot be a past date']
        } else if (
          enteredDate.diff(maxFutureDate) > 0 ||
          enteredDate.diff(minDate) < 0
        ) {
          this.validStartDate = this.calendarYear
            ? [
                `The intended start date must be within calendar year, ${maxYear}`
              ]
            : [
                'The intended start date must be within this or the next calendar year'
              ]
        } else {
          this.validStartDate = [true]
        }
      } else {
        this.validStartDate = ['Invalid date']
      }
    },
    validateAttendedFrom(date) {
      const selectedDate = moment(date, ['MM-YYYY', 'YYYY-MM']).format(
        'YYYY-MM'
      )
      if (date === null || date === '') {
        this.validAttendedFrom = ['Attended from is required']
      } else if (this.isValidDateFormat('YYYY-MM', selectedDate)) {
        const enteredDate = moment(selectedDate)
        const currentDate = moment(new Date().toISOString().slice(0, 7))
        const minAttendedDateDate = moment(
          new Date(`${this.thisYear - ATTENDED_FROM_UNTIL_MINIMUM_YEAR}-01-01`)
            .toISOString()
            .slice(0, 7)
        )
        const prevSchoolLastDate = this.otherStudentDetails.prevSchoolLastDate
          ? moment(
              this.otherStudentDetails.prevSchoolLastDate,
              'MM-YYYY'
            ).format('YYYY-MM')
          : null
        if (enteredDate.diff(currentDate) > 0) {
          this.validAttendedFrom = ['Cannot be a future date']
        } else if (enteredDate.diff(minAttendedDateDate) < 0) {
          this.validAttendedFrom = [
            `Please ensure the date is no earlier than ${
              this.thisYear - ATTENDED_FROM_UNTIL_MINIMUM_YEAR
            }.`
          ]
        } else if (
          prevSchoolLastDate &&
          moment(enteredDate) > moment(prevSchoolLastDate)
        ) {
          this.validAttendedUntil = [
            "Cannot be earlier than 'Attended from' date"
          ]
          this.validAttendedFrom = [true]
        } else {
          this.validAttendedFrom = [true]
        }
      } else {
        this.validAttendedFrom = ['Invalid date']
      }
    },
    validateAttendedUntil(date) {
      const selectedDate = moment(date, ['MM-YYYY', 'YYYY-MM']).format(
        'YYYY-MM'
      )
      if (date === null || date === '') {
        this.validAttendedUntil = ['Attended until is required']
      } else if (this.isValidDateFormat('YYYY-MM', selectedDate)) {
        const enteredDate = moment(selectedDate)
        const minAttendedDateDate = moment(
          new Date(`${this.thisYear - ATTENDED_FROM_UNTIL_MINIMUM_YEAR}-01-01`)
            .toISOString()
            .slice(0, 7)
        )
        const prevSchoolStartDate = moment(
          this.otherStudentDetails.prevSchoolStartDate,
          ['MM-YYYY', 'YYYY-MM']
        ).format('YYYY-MM')
        if (
          prevSchoolStartDate &&
          moment(prevSchoolStartDate, 'YYYY-MM') > moment(enteredDate)
        ) {
          this.validAttendedUntil = [
            "Cannot be earlier than 'Attended from' date"
          ]
        } else if (enteredDate.diff(this.maxFutureDates) > 0) {
          this.validAttendedUntil = [
            `Please ensure the date is no earlier than ${
              this.thisYear - ATTENDED_FROM_UNTIL_MINIMUM_YEAR
            } and no later than the current calendar year.`
          ]
        } else if (enteredDate.diff(minAttendedDateDate) < 0) {
          this.validAttendedUntil = [
            `Please ensure the date is no earlier than ${
              this.thisYear - ATTENDED_FROM_UNTIL_MINIMUM_YEAR
            }.`
          ]
        } else {
          this.validAttendedUntil = [true]
        }
      } else {
        this.validAttendedUntil = ['Invalid date']
      }
    },
    isValidDateFormat(format, date) {
      let regPattern
      if (format === 'DD-MM-YYYY') {
        regPattern = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/
      } else if (format === 'YYYY-MM-DD') {
        regPattern = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
      } else if (format === 'YYYY-MM') {
        regPattern = /^(\d{4}-(0[1-9]|1[012]))$/
      } else if (format === 'MM-YYYY') {
        regPattern = /^(((0)[0-9])|((1)[0-2]))(-)\d{4}$/
      }
      return regPattern.test(date)
    },
    getStartYear(form) {
      // Gets start year from intendedStartDate (or enterStartDate if manual date is entered).
      // A year will only be returned if the whole date is valid.
      try {
        const startDate =
          form.intendedStartDate === CHOOSE_DATE_VALUE
            ? moment(form.enterStartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : form.intendedStartDate
        const date = new Date(startDate)
        return startDate.length === 10 ? date.getFullYear() : null
      } catch (e) {
        return null
      }
    },
    isDateOfBirthProvided() {
      return !!this.studentDetails.dateOfBirth
    },
    selectedTermLabel(value) {
      const selectedTerm = this.schoolTerms.filter((res) => res.value === value)
      if (selectedTerm.length > 0) {
        return selectedTerm[0].text
      }
      return null
    },
    openTemporaryResidentLink() {
      window.open(this.oesProperties.urls.temporaryResidents, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .tmp-alert {
  max-width: 420px;
  .link {
    color: $ads-primary-blue;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
}
h2 {
  color: $ads-navy;
  font-size: 1.375rem;
  line-height: 1.2;
}
.fieldLabel {
  color: $ads-navy;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 20px;
}
</style>
