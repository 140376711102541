<template>
  <!-- eslint-disable max-len -->
  <div>
    <Banner compact>
      <h2 v-if="fromEhub">Application confirmation</h2>
      <h2 v-else>Application submitted</h2>
    </Banner>
    <v-container fluid class="ooaContent py-12">
      <ContentBox
        class="pt-6 applicationId"
        img-file-name="green_tick.svg"
        data-testid="application-submitted"
      >
        <div slot="title">
          <div v-if="fromEhub">
            <h2>Your application has been submitted.</h2>
            <p class="subheading">
              Application reference ID: <strong>{{ applicationId }}</strong>
            </p>
          </div>
          <div v-else>
            <h2>You've submitted your application</h2>
            <p class="subheading">
              Your application ID is: <strong>{{ applicationId }}</strong>
            </p>
          </div>
        </div>
        <div slot="copyContent">
          <p v-if="fromEhub">
            We’ve sent a copy of your application and its ID to
            <strong>{{ email }}</strong>
          </p>
          <p v-else>
            We’ve sent a copy of your application and its ID number to
            <strong>{{ email }}</strong>
          </p>
        </div>
      </ContentBox>
      <v-divider />
      <ContentBox
        v-if="fromEhub"
        class="pt-9"
        img-file-name="icon-hub.svg"
        title="Track applications from the dashboard."
        image-class="largeImage"
        data-testid="enrolment-hub"
      >
        <div slot="copyContent">
          <p>
            Track the status of applications or start a new application from the
            Online Enrolment dashboard.
          </p>
          <v-btn color="primary" class="mt-1" @click="gotoEhub">
            <v-icon class="mr-2">mdi-logout-variant</v-icon>
            Go to the dashboard
          </v-btn>
        </div>
      </ContentBox>
      <ContentBox
        class="pt-9"
        img-file-name="whatsnext.svg"
        title="What’s next?"
        image-class="largeImage"
        data-testid="whats-next"
      >
        <div slot="copyContent">
          <p>
            We will assess your application {{ timeFrameCopy }} and contact you.
            If we need additional information or to arrange an interview before
            making a decision, we'll let you know. School holiday periods may
            increase assessment times.
          </p>
        </div>
      </ContentBox>
      <ContentBox
        class="pt-9"
        img-file-name="survey-icon.svg"
        title="Complete our 1 minute survey"
        image-class="largeImage"
        data-testid="survey"
      >
        <div slot="copyContent">
          <p>
            Our goal is to create the best possible product, and your thoughts,
            ideas, and suggestions play a major role in helping us identify
            opportunities to improve.<br />
            <strong
              ><a :href="surveyLink" target="_blank"
                >Click here to start.</a
              ></strong
            >
          </p>
        </div>
      </ContentBox>
      <v-card class="mt-6">
        <ContentBox
          v-if="localCatchmentSchools && localCatchmentSchools.length > 0"
          class="pt-6"
          img-file-name="icon-local-school.svg"
          title="Consider your local school"
          data-testid="local-school-information"
        >
          <div slot="copyContent" class="mb-4 text-left">
            <p class="mb-6">
              Remember, placement at our school is not guaranteed for
              out-of-area students. We recommend speaking with your local school
              about how to meet your child's education needs, if you haven't
              done so yet.
            </p>
            <div v-for="loc in localCatchmentSchools" :key="loc.schoolCode">
              <SchoolExpanderPanel
                :name="loc.schoolName"
                :address="loc.schoolAddress"
                :lat="loc.lat"
                :long="loc.long"
                :phone="loc.schoolPhone"
                :website="loc.schoolWebsite"
                :show-apply="loc.isOesSchool"
                @click="proceedLocalEnrl(loc.schoolCode)"
              />
            </div>
          </div>
        </ContentBox>
        <v-divider />
        <ContentBox
          v-show="localCatchmentSchools && localCatchmentSchools.length > 0"
          class="pt-6"
          img-file-name="icon-next-steps.svg"
          data-testid="localschool-nextsteps"
        >
          <div slot="copyContent" class="mb-4 text-left">
            <p>
              <strong
                >To understand how your local school can support your child's
                needs, you can:</strong
              >
            </p>

            <div class="steps pa-4">
              <div class="d-flex pb-3 pt-2">
                <v-icon class="pr-5">schedule</v-icon>
                <div>Ask to meet with the principal.</div>
              </div>
              <div class="d-flex pb-3 pt-2">
                <v-icon class="pr-5">mdi-web</v-icon>
                <div>Visit the school website.</div>
              </div>

              <div class="d-flex pb-3 pt-2">
                <v-icon class="pr-5">mdi-train</v-icon>
                <div>
                  Look at transport and commutes on
                  <a href="https://www.google.com/maps" target="_blank"
                    >Google Maps</a
                  >.
                </div>
              </div>

              <div
                v-if="!isSecondary"
                class="d-flex pb-3 pt-2"
                data-testid="localschool-nextsteps-oshc-section"
              >
                <v-icon class="pr-5">schedule</v-icon>
                <div>
                  Find out about
                  <a
                    href="https://www.service.nsw.gov.au/find-a-basc-provider"
                    target="_blank"
                    >out of school hours care (OSHC) services</a
                  >.
                </div>
              </div>
            </div>
          </div>
        </ContentBox>
      </v-card>
    </v-container>
    <ooa-dialog
      :visible="attemptToLeave"
      title="Your application is awaiting review"
      icon="mdi-lock-outline"
      max-width="596px"
      :open-dialog="attemptToLeave"
      :display-close-btn="true"
      :actions="dialogActions"
      @proceed="gotoEhub"
      @close="attemptToLeave = false"
    >
      <div slot="message">
        Your application is now with the school for review and cannot be edited
        - please check your email to see the details you have submitted. Would
        you like to return to the enrolment dashboard?
      </div>
    </ooa-dialog>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import ContentBox from '@/components/ContentBox'
import SchoolExpanderPanel from '@/components/SchoolExpanderPanel'
import moment from 'moment'
import { mapGetters } from 'vuex'
import {
  isSecondary,
  getQueryYear,
  getFallBackQueryYear,
  getQueryYearLookupArray,
  isFromEhub
} from '@/helpers'
import catchmentFinder from '@/api/server/CatchmentFinder'
import OoaDialog from '@/components/OoaDialog'
import jwtDecode from 'jwt-decode'

export default {
  name: 'ConfirmationView',
  components: {
    Banner,
    ContentBox,
    SchoolExpanderPanel,
    OoaDialog
  },
  data() {
    return {
      queryYearLookupArray: [],
      attemptToLeave: null,
      dialogActions: [
        {
          name: 'Return to dashboard',
          color: 'primary',
          size: 'large',
          outlined: false,
          btnIcon: 'mdi-arrow-left',
          btnText: false,
          action: 'proceed'
        }
      ],
      isStamped: false,
      isSnswLogin: false
    }
  },
  computed: {
    ...mapGetters([
      'form',
      'school',
      'schoolData',
      'oesProperties',
      'applicationId',
      'email',
      'localCatchmentSchools',
      'selectedCatchment',
      'selectedResidentialAddress',
      'calendarYear'
    ]),
    selectedScholasticYear() {
      return this.form.otherStudentDetails.scholasticYear
    },
    schoolName() {
      return this.form.schoolName
    },
    isSecondary() {
      return isSecondary(this.school?.catchmentLevel, this.selectedCatchment)
    },
    localResidentialAddress() {
      return this.form.residentialAddress
    },
    intendedStartDate() {
      if (
        this.form?.otherStudentDetails?.intendedStartDate ===
        'Choose a specific start date'
      ) {
        return moment(
          this.form.otherStudentDetails.enterStartDate,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD')
      }
      return this.form?.otherStudentDetails?.intendedStartDate
    },
    timeFrameCopy() {
      const copy =
        new Date(this.intendedStartDate).getFullYear() ===
        new Date().getFullYear()
          ? this.schoolData?.currentYearTimeFrame?.value
          : this.schoolData?.nextYearTimeFrame?.value

      // values are stored like 'In November this year' so lowerCase the first letter only
      if (copy) {
        return copy.charAt(0).toLowerCase() + copy.slice(1)
      }
      return ''
    },
    fromEhub() {
      return isFromEhub()
    },
    schoolFinderURLs() {
      const endPoints = this.oesProperties ? this.oesProperties.endpoints : null
      return endPoints && endPoints.schoolFinder && endPoints.schoolFinder.urls
    },
    surveyLink() {
      let surveyLink = 'https://digitalnsw.typeform.com/to/vDRv88'
      if (this.fromEhub) {
        surveyLink = `https://www.surveymonkey.com/r/NLWRQ2B?src=ooa&st=${
          this.isStamped ? 'yes' : 'no'
        }&auth=${this.isSnswLogin ? 'snsw' : 'otp'}`
      }
      return surveyLink
    }
  },
  watch: {
    /* eslint-disable consistent-return */
    queryYearLookupArray(queryYearLookupValue) {
      // If it is the resume aplication, the localCatchmentSchools is null in state as we don't get the data from
      // home page, so we have to get the localCatchmentSchools information from the database here
      const { catchmentLevel } = this.form
      const queryYear = getQueryYear(
        queryYearLookupValue,
        catchmentLevel,
        this.calendarYear
      )
      // If the normal query year returns no data, then use the fallBackQueryYear
      const fallbackQueryYear = getFallBackQueryYear(
        queryYearLookupValue,
        catchmentLevel,
        this.calendarYear
      )

      const localResidentialAddressLongitude =
        this.localResidentialAddress?.long ||
        this.localResidentialAddress?.longitude
      const localResidentialAddressLatitude =
        this.localResidentialAddress?.lat ||
        this.localResidentialAddress?.latitude
      return this.$store
        .dispatch('fetchLocalCatchmentSchool', {
          long: localResidentialAddressLongitude,
          lat: localResidentialAddressLatitude,
          catchmentLevel,
          selectedYear: queryYear,
          scholasticYear: this.selectedScholasticYear
        })
        .then((response) => {
          if (response.data.rows.length > 0 || !fallbackQueryYear) {
            return response
          }

          return this.$store.dispatch('fetchLocalCatchmentSchool', {
            long: localResidentialAddressLongitude,
            lat: localResidentialAddressLatitude,
            catchmentLevel,
            selectedYear: fallbackQueryYear,
            scholasticYear: this.selectedScholasticYear
          })
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
        })
    }
  },
  created() {
    const idToken = window.localStorage.getItem('id_token')
    if (idToken) {
      const decodedJwt = jwtDecode(idToken)
      if (decodedJwt) {
        const customPid = decodedJwt['custom:pid']
        if (customPid) {
          this.isStamped = true
        }
        const cognitoUsername = decodedJwt['cognito:username']
        if (cognitoUsername && String(cognitoUsername).startsWith('SNSW')) {
          this.isSnswLogin = true
        }
      }
    }
  },
  mounted() {
    this.getCatchmentAndYearData()
  },
  beforeRouteLeave(to, from, next) {
    if (this.fromEhub) {
      this.attemptToLeave = true
    } else {
      next()
    }
  },
  methods: {
    proceedLocalEnrl(schoolCode) {
      let catchmentPrefill
      const catchmentLevel = this.selectedCatchment || 'primary'
      if (this.selectedResidentialAddress) {
        const data = {
          address: this.selectedResidentialAddress,
          catchmentLevel,
          year: this.calendarYear
        }
        catchmentPrefill = encodeURIComponent(btoa(JSON.stringify(data)))
      }

      let url = `${process.env.VUE_APP_LOCAL_ENROLMENT_LINK}schoolCode=${schoolCode}&catchmentLevel=${this.school.catchmentLevel}`
      if (catchmentPrefill) {
        url += `&catchmentPrefill=${catchmentPrefill}`
      }
      window.open(url)
    },
    getCatchmentAndYearData() {
      catchmentFinder
        .getCatchmentAndYearData(this.schoolFinderURLs, this.school?.schoolCode)
        .then(({ data: { rows } }) => {
          if (rows.length === 1 && rows[0].calendar_year === 0) {
            // handle 0 calendar year case
            const currentYear = new Date().getFullYear()
            this.queryYearLookupArray = [
              {
                catchmentLevel: rows[0].catchment_level,
                calendarYear: currentYear + 1,
                queryYear: 0,
                fallbackQueryYear: currentYear
              }
            ]
          } else {
            // Get all calendar years and catchment levels
            const calendarYears = rows.map((row) => row.calendar_year)
            const catchmentLevels = rows.map((row) => row.catchment_level)

            // Remove the duplicates and sort years
            const uniqueCalendarYears = Array.from(
              new Set(calendarYears)
            ).sort()
            const uniqueCatchmentLevels = Array.from(new Set(catchmentLevels))

            // Take the base year as the lowest non-zero year
            const baseYear =
              uniqueCalendarYears[0] === 0
                ? uniqueCalendarYears[1]
                : uniqueCalendarYears[0]
            const nextYear = baseYear + 1

            this.generateQueryYearLookupArray(
              rows,
              uniqueCatchmentLevels,
              baseYear,
              nextYear
            )
          }
        })
    },
    generateQueryYearLookupArray(
      rows,
      uniqueCatchmentLevels,
      baseYear,
      nextYear
    ) {
      this.queryYearLookupArray = getQueryYearLookupArray(
        rows,
        uniqueCatchmentLevels,
        baseYear,
        nextYear
      )
    },
    gotoEhub() {
      this.attemptToLeave = false
      window.location.href = `${window.location.origin}/dashboard`
    }
  }
}
</script>

<style lang="scss" scoped>
.subheading {
  font-weight: normal;
  font-size: 1.375rem;
}
.applicationId {
  word-break: break-word;
}
</style>
