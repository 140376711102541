/* eslint-disable no-param-reassign */
import applicationApi from 'api-client/application'
import ernApi from 'api-client/ern'

import helpers from '@/helpers'

const formModule = {
  state: {
    form: {
      student: null,
      otherStudentDetails: null,
      parentCarers: null,
      siblings: null,
      schoolCode: null,
      schoolName: null,
      catchmentLevel: null,
      capacity: null,
      otherConsiderations: null,
      criteria: null,
      residencyStatus: null,
      residentialAddress: null,
      supportingDocuments: null
    },
    prevCriterias: null,
    // flag to identify is user select the resume application button
    isResuming: true,
    // flag to identify if the application exists for email-id
    isPendingApplication: null,
    isFileUploadInProgress: null,
    isFileDeleteInProgress: null,
    // current (active) step
    currentStep: 1,
    // valid/invalid step status
    validStudent: null,
    validParentCarers: null,
    validConsiderations: null,
    applicationId: null,
    isReviewed: null,
    viaEhub: null,
    isEhubLinkedStudent: null,
    parentId: null,
    ernEnrolmentOwner: null,
    ernEnrolmentOwnerNameNotMatched: null,
    ehubPrefill: null,
    prefillParentStudent: null,
    ernRecord: null,
    ehubFormData: null,
    childSrn: null,
    swsEnrolmentData: null
  },
  getters: {
    form: (state) => state.form,
    isResuming: (state) => state.isResuming,
    isPendingApplication: (state) => state.isPendingApplication,
    isFileUploadInProgress: (state) => state.isFileUploadInProgress,
    isFileDeleteInProgress: (state) => state.isFileDeleteInProgress,
    currentStep: (state) => state.currentStep,
    validStudent: (state) => state.validStudent,
    validParentCarers: (state) => state.validParentCarers,
    validConsiderations: (state) => state.validConsiderations,
    applicationId: (state) => state.applicationId,
    isReviewed: (state) => state.isReviewed,
    studentName: (state) => (defaultText) => {
      const defaultReturn = defaultText || 'this student'
      const { student } = state.form
      return student
        ? student.prefFirstName || student.firstName || defaultReturn
        : defaultReturn
    },
    residentialAddress: (state) => state.residentialAddress,
    // eslint-disable-next-line max-len
    pluralisedStudentName: (state, getters) => (defaultText) =>
      helpers.pluralise(getters.studentName(defaultText)),
    supportingDocuments: (state) => state.form.supportingDocuments,
    viaEhub: (state) => state.viaEhub,
    isEhubLinkedStudent: (state) => state.isEhubLinkedStudent,
    parentId: (state) => state.parentId,
    ernEnrolmentOwner: (state) => state.ernEnrolmentOwner,
    prevCriterias: (state) => state.prevCriterias,
    ernEnrolmentOwnerNameNotMatched: (state) =>
      state.ernEnrolmentOwnerNameNotMatched,
    ehubPrefill: (state) => state.ehubPrefill,
    prefillParentStudent: (state) => state.prefillParentStudent,
    ernRecord: (state) => state.ernRecord,
    ehubFormData: (state) => state.ehubFormData,
    childSrn: (state) => state.childSrn,
    swsEnrolmentData: (state) => state.swsEnrolmentData
  },
  mutations: {
    setPrevCriterias(state, prevCriterias) {
      state.prevCriterias = prevCriterias
    },
    setSelectedPrevCriteria(state, criteria) {
      state.prevCriterias.forEach((prevCriteria) => {
        if (prevCriteria.code === criteria.code && criteria.description) {
          prevCriteria.description = criteria.description
        }
      })
    },
    setForm(state, form) {
      state.form = form
    },
    setIsResuming(state, resuming) {
      state.isResuming = resuming
    },
    setIsPendingApplication(state, isPending) {
      state.isPendingApplication = isPending
    },
    setIsFileUploadInProgress(state, inProgress) {
      state.isFileUploadInProgress = inProgress
    },
    setIsFileDeleteInProgress(state, inProgress) {
      state.isFileDeleteInProgress = inProgress
    },
    setResidentialAddress(state, address) {
      state.form.residentialAddress = address
    },
    setSchoolDetails(state, { school, selectedCatchment }) {
      state.form.schoolCode = school.schoolCode
      state.form.schoolName = school.schoolName
      state.form.catchmentLevel = selectedCatchment
    },
    setMeta(state, meta) {
      state.form.meta = meta
    },
    setCurrentStep(state, step) {
      state.currentStep = step
    },
    setStudentDetails(state, student) {
      state.form.student = student
    },
    setOtherStudentDetails(state, otherDeatils) {
      state.form.otherStudentDetails = otherDeatils
    },
    setResidencyStatus(state, residencyStatus) {
      state.form.residencyStatus = residencyStatus
    },
    setSupportingDocuments(state, supportingDocuments) {
      state.form.supportingDocuments = supportingDocuments
    },
    setValidStudent(state, isValid) {
      state.validStudent = isValid
    },
    setValidParentCarers(state, isValid) {
      state.validParentCarers = isValid
    },
    setValidConsiderations(state, isValid) {
      state.validConsiderations = isValid
    },
    setSiblings(state, siblingsDetails) {
      state.form.siblings = siblingsDetails
    },
    setParentCarers(state, parentCarers) {
      state.form.parentCarers = parentCarers
    },
    setCapacityDetails(state, capacity) {
      state.form.capacity = capacity
    },
    setOtherConsiderations(state, description) {
      state.form.otherConsiderations = description
    },
    setCriteria(state, criteria) {
      state.form.criteria = criteria
    },
    setApplicationId(state, id) {
      state.applicationId = id
    },
    setIsReviewed(state, reviewed) {
      state.isReviewed = reviewed
    },
    setViaEhub(state, viaEhub) {
      state.viaEhub = viaEhub
    },
    setIsEhubLinkedStudent(state, isEhubLinkedStudent) {
      state.isEhubLinkedStudent = isEhubLinkedStudent
    },
    setParentId(state, parentId) {
      state.parentId = parentId
    },
    setErnEnrolmentOwner(state, person) {
      state.ernEnrolmentOwner = person
    },
    setEnrolmentOwnerNameNotMatched(state, value) {
      state.ernEnrolmentOwnerNameNotMatched = value
    },
    setEhubPrefill(state, ehubPrefill) {
      state.ehubPrefill = ehubPrefill
    },
    setPrefillParentStudent(state, prefillParentStudent) {
      state.prefillParentStudent = prefillParentStudent
    },
    setErnRecord(state, ernRecord) {
      state.ernRecord = ernRecord
    },
    setEhubFormData(state, ehubFormData) {
      state.ehubFormData = ehubFormData
    },
    setChildSrn(state, childSrn) {
      state.childSrn = childSrn
    },
    setSwsEnrolmentData(state, swsEnrolmentData) {
      state.swsEnrolmentData = swsEnrolmentData
    }
  },
  actions: {
    setCurrentStep({ commit }, { currentStep }) {
      commit('setCurrentStep', currentStep)
    },
    setIsValidStudent({ commit }, { isValid }) {
      commit('setValidStudent', isValid)
    },
    setIsValidParentCarers({ commit }, { isValid }) {
      commit('setValidParentCarers', isValid)
    },
    setIsValidConsiderations({ commit }, { isValid }) {
      commit('setValidConsiderations', isValid)
    },
    setIsFileUploadInProgress({ commit }, { inProgress }) {
      commit('setIsFileUploadInProgress', inProgress)
    },
    setIsFileDeleteInProgress({ commit }, { inProgress }) {
      commit('setIsFileDeleteInProgress', inProgress)
    },
    resetPreviousSchoolId({ commit }, { schoolId }) {
      commit('setPreviousSchoolId', schoolId)
    },
    setSupportingDocuments({ commit }, { supportingDocuments }) {
      commit('setSupportingDocuments', supportingDocuments)
    },
    fetchApplication({ commit, rootState }) {
      return applicationApi.fetchApplication().then((response) => {
        this._vm.$log.debug(response) // eslint-disable-line no-underscore-dangle
        this._vm.$log.debug(rootState.auth.email) // eslint-disable-line no-underscore-dangle
        if (response.body && response.body.application) {
          if (response.body.application.meta) {
            let { currentStep } = response.body.application.meta
            // Backward compatibility for inprogress app
            currentStep = currentStep === 5 ? 2 : currentStep
            commit('setCurrentStep', currentStep || 1)
            commit(
              'setValidStudent',
              response.body.application.meta.validStudent
            )
            commit(
              'setValidParentCarers',
              response.body.application.meta.validParentCarers
            )
            commit(
              'setValidConsiderations',
              response.body.application.meta.validConsiderations
            )
            commit(
              'setCalendarYear',
              response.body.application.meta.calendarYear
            )
            commit('setEhubPrefill', response.body.application.meta.ehubPrefill)
            delete response.body.application.meta
          }
          //  removed for Defect FUS-1149 fix
          /* const ernEnrolmentOwner = JSON.parse(window.sessionStorage.getItem('ernEnrolmentOwnerName'))
          const hasParents = Array.isArray(response.body.application.parentCarers)
          if (ernEnrolmentOwner && hasParents) {
            // change the enrolment owner name based on the names from ERN
            const enrolmentOwnerIndex = response.body.application.parentCarers.findIndex(p => p.parentCarerEmail === rootState.auth.email)
            if (enrolmentOwnerIndex !== -1) {
              // check if the enrolment owner name from EHUB (retrieved from ERN) is matched with those in draft
              const enrolmentOwnerInDraft = response.body.application.parentCarers[enrolmentOwnerIndex]

              if (
                helpers.nameNotMatched(enrolmentOwnerInDraft, ernEnrolmentOwner)
              ) {
                response.body.application.parentCarers[enrolmentOwnerIndex].parentCarerGivenName = ernEnrolmentOwner.firstName
                response.body.application.parentCarers[enrolmentOwnerIndex].parentCarerFamilyName = ernEnrolmentOwner.lastName
                commit('setEnrolmentOwnerNameNotMatched', true)
                commit('setEhubPrefill', 'parent')
              } else {
                response.body.application.parentCarers[enrolmentOwnerIndex].parentCarerCompletingApp = true
                commit('setEnrolmentOwnerNameNotMatched', false)
              }
            } else {
              response.body.application.parentCarers[0].parentCarerGivenName = ernEnrolmentOwner.firstName
              response.body.application.parentCarers[0].parentCarerFamilyName = ernEnrolmentOwner.lastName
              response.body.application.parentCarers[0].parentCarerCompletingApp = true
              response.body.application.parentCarers[0].parentCarerEmail = rootState.auth.email
              commit('setEnrolmentOwnerNameNotMatched', true)
              commit('setEhubPrefill', 'parent')
            }

            commit('setErnEnrolmentOwner', ernEnrolmentOwner)
          } */

          commit('setForm', response.body.application)
          commit(
            'setIsEhubLinkedStudent',
            !!response.body.application.student?.srn
          )
          commit('setIsPendingApplication', true)
        } else {
          const form = {
            student: null,
            otherStudentDetails: null,
            parentCarers: null,
            residentialAddress: null,
            schoolCode: null,
            schoolName: null,
            catchmentLevel: null,
            siblings: null,
            capacity: null,
            otherConsiderations: null,
            residencyStatus: null
          }
          commit('setForm', form)
          commit('setIsPendingApplication', false)
        }
      })
    },
    // create a new application in dynamoDB.
    // This should only be invoked when creating the application for the first time.
    createNewApplication({ commit, state, getters }) {
      if (
        state.form.schoolCode === null &&
        state.form.catchmentLevel === null &&
        state.form.residentialAddress === null
      ) {
        commit('setSchoolDetails', {
          school: getters.school,
          selectedCatchment: getters.catchment
        })
        commit('setResidentialAddress', getters.localResidentialAddress)
        commit('setMeta', {
          calendarYear: getters.calendarYear,
          ehubPrefill: getters.ehubPrefill
        })
        return applicationApi.createNewApplication({ application: state.form })
      }
      // TODO: add handling for non-null application
      return null
    },

    // create a new application via ehub in dynamoDB.
    async createNewApplicationViaEhub({ commit, state, getters }) {
      commit('setSchoolDetails', {
        school: getters.school,
        selectedCatchment: getters.catchment
      })
      commit('setResidentialAddress', getters.localResidentialAddress)
      commit('setMeta', {
        calendarYear: getters.calendarYear,
        ehubPrefill: getters.ehubPrefill
      })
      const response = await applicationApi.createNewApplication({
        application: state.form
      })
      return response
    },

    deleteApplication() {
      return applicationApi.deleteApplication()
    },
    // update an existing application in dynamoDB.
    // Form submissions should use this to update the existing draft application.
    updateApplication({ state, getters }) {
      const metaData = {
        currentStep: state.currentStep,
        validStudent: state.validStudent,
        validParentCarers: state.validParentCarers,
        validConsiderations: state.validConsiderations,
        calendarYear: getters.calendarYear,
        ehubPrefill: getters.ehubPrefill
      }

      // deep copy object
      const formData = JSON.parse(JSON.stringify(state.form))

      // add ehub flags for backend to handle email distribution etc
      if (getters.viaEhub) {
        formData.viaEhub = true
      }
      if (getters.isEhubLinkedStudent) {
        formData.isEhubLinkedStudent = true
        if (!formData.student.srn) {
          formData.student.srn =
            getters.childSrn || getters.ehubFormData.child.srn
        }
      }

      formData.meta = metaData

      return applicationApi.saveApplication({ application: formData })
    },

    resetApplication({ commit }) {
      const form = {
        student: null,
        otherStudentDetails: null,
        parentCarers: null,
        residentialAddress: null,
        schoolCode: null,
        schoolName: null,
        catchmentLevel: null,
        siblings: null,
        capacity: null,
        otherConsiderations: null,
        residencyStatus: null,
        meta: {}
      }
      commit('setForm', form)
      commit('setIsPendingApplication', false)
    },

    getErnRecordBySRN({ commit }, srn) {
      const ernRecord = ernApi.getErnRecordBySRN(srn)
      commit('setErnRecord', ernRecord)
    },

    async getPrefillParentStudentByName({ commit }, { firstName, lastName }) {
      try {
        const parentStudentData = await ernApi.getPrefillParentStudentByName(
          firstName,
          lastName
        )
        if (parentStudentData) {
          commit('setPrefillParentStudent', parentStudentData)
        }
      } catch (error) {
        commit('setError', error)
      }
    },

    async getPrefillParentStudentBySrn({ commit }, childSrn) {
      try {
        const parentStudentData = await ernApi.getPrefillParentStudentBySrn(
          childSrn
        )
        if (parentStudentData) {
          commit('setPrefillParentStudent', parentStudentData)
        }
      } catch (error) {
        commit('setError', error)
      }
    },

    async getPrefillParentStudentForNewChild({ commit }) {
      try {
        const parentStudentData =
          await ernApi.getPrefillParentStudentForNewChild()
        if (parentStudentData) {
          parentStudentData.student = null
          commit('setPrefillParentStudent', parentStudentData)
        }
      } catch (error) {
        commit('setError', error)
      }
    }
  }
}

export default formModule
