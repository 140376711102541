<template>
  <v-container fluid class="ErrorCard">
    <slot name="icon-container">
      <div class="ErrorCard__icon-container">
        <slot name="icon">
          <img
            class="ErrorCard__icon"
            :src="require(`@/assets/icon-generic-error.png`)"
          />
        </slot>
      </div>
    </slot>
    <section>
      <slot name="body">
        <h2 class="ErrorCard__heading">
          <slot name="heading">{{ headingText }}</slot>
        </h2>
        <p class="ErrorCard__info">
          <slot name="info-text">{{ infoText }}</slot>
        </p>
        <p class="ErrorCard__error-code">
          <slot name="error-code">
            <template v-if="errorCode">
              <span class="bold">Error Code:</span> {{ errorCode }}
            </template>
          </slot>
        </p>
        <p class="ErrorCard__error-id">
          <slot name="error-id">
            <template v-if="errorId">
              <span class="bold">Error ID:</span> {{ errorId }}
            </template>
          </slot>
        </p>
      </slot>
      <div v-if="!fromEhub">
        <div
          v-if="actions && Array.isArray(actions) && actions.length > 0"
          class="ErrorCard__actions"
        >
          <slot
            v-for="(action, i) in actions"
            :action="action"
            name="action-button"
          >
            <v-btn
              :key="i"
              color="primary"
              class="ErrorCard__action-button"
              v-bind="action.attrs"
              v-on="action.on"
            >
              {{ action.text }}
            </v-btn>
          </slot>
        </div>
      </div>
      <div v-else>
        <p class="ErrorCard__error-ehub">
          Please
          <a href="/dashboard">return to the dashboard</a> to continue
        </p>
      </div>
    </section>
  </v-container>
</template>

<script>
import { isFromEhub } from '@/helpers'

export default {
  name: 'ErrorCard',
  props: {
    errorCode: {
      type: String
    },
    errorId: {
      type: String
    },
    infoText: {
      type: String
    },
    headingText: {
      type: String,
      default: 'An error has occurred and we’re trying to fix the problem.'
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    fromEhub() {
      return isFromEhub()
    }
  }
}
</script>

<style lang="scss" scoped>
.ErrorCard {
  background-color: $ads-white;
  border-radius: 5px;
  text-align: center;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);

  &__icon-container {
    background-color: $ads-light-blue;
    border-radius: 50%;
    width: 185px;
    height: 185px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 100px;
  }

  &__heading {
    font-size: 24px;
    font-weight: bold;
  }

  &__error-code,
  &__actions {
    margin-top: 40px;
  }

  &__info,
  &__error-code,
  &__error-id,
  &__error-ehub {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 0;
  }

  &__info,
  &__error-ehub {
    margin-top: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .ErrorCard__action-button {
      font-size: 16px;
      font-weight: bold;
      padding: 20px;
      margin: 10px 20px;
    }
  }

  .bold {
    font-weight: bold;
  }
}
</style>
